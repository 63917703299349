import { useState } from "react";
import { Field } from "react-final-form";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { InfoCircle } from "react-bootstrap-icons";

import RenderSlider from "../../../shared/components/range_slider/Slider";
import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import { RenderField } from "../../../shared/components/form/Field";
import AssessmentSource from "../components/AssessmentSource";
import { WizzardPageType } from "../../../types/types";

type CauseProps = {
  causeNumber: number;
} & WizzardPageType;

const Cause = ({ causeNumber, values, mutators }: CauseProps) => {
  const [linkCause, setLinkCause] = useState(false);

  const linkCauseHandler = (onChange: any) => {
    onChange();
    setLinkCause(!linkCause);
  };

  return (
    <>
      <div className="form-title">{`CAUSE ${causeNumber}`}</div>
      <Col md={12}>
        <Row>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link an existing record as a Cause?
              </span>
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {linkCause ? "Yes" : "No"}
                </span>
                <Field
                  name={`causeLink${causeNumber}`}
                  render={({ input, meta }) => (
                    <SwitchButton
                      id={input.name}
                      name={input.name}
                      checked={input.checked}
                      onChange={(e) => linkCauseHandler(input.onChange)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">Linked Record</span>
              <div className="form__form-group-field">
                <Field
                  name={`causeLinkedRisk${causeNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      disabled={!linkCause}
                      meta={meta}
                      placeholder="Linked Risk"
                      options={[
                        { value: "1", label: "T-0074 - COVID-19 Impact" },
                        {
                          value: "2",
                          label: "T-0057 - Non-Productive Contractors",
                        },
                        {
                          value: "3",
                          label: "T-0060 - Below Ground Obstructions",
                        },
                        { value: "4", label: "T-0069 - Utility strike" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">Cause Title</span>
              <div className="form__form-group-field">
                <div className="form__form-group-field">
                  <Field
                    name={`causeTitle${causeNumber}`}
                    disabled={linkCause}
                    component={RenderField}
                    placeholder="Cause Title"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="form__form-group">
          <span className="form__form-group-label">Cause Description</span>
          <div className="form__form-group-field">
            <Field
              name={`causeDescription${causeNumber}`}
              component="textarea"
              disabled={linkCause}
              type="text"
            />
          </div>
        </div>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Likelihood
                <UncontrolledTooltip target="likelihoodInfo">
                  How likely is this Cause to happen?
                </UncontrolledTooltip>
                <InfoCircle
                  id="likelihoodInfo"
                  className="form__form-group-info"
                />
              </span>
              <Field
                name={`causeLikelihood${causeNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Degree of Correlation
                <UncontrolledTooltip target="correlationInfo">
                  If this Cause does happen, how likely is it to lead to the
                  Risk happening?
                </UncontrolledTooltip>
                <InfoCircle
                  id="correlationInfo"
                  className="form__form-group-info"
                />
              </span>
              <Field
                name={`causeDegreeOfCorrelation${causeNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <AssessmentSource
          pageTitle="cause"
          parentNumber={causeNumber}
          values={values}
          mutators={mutators}
        />
      </Col>
    </>
  );
};

export default Cause;
