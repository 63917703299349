import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { ChevronDown } from "react-bootstrap-icons";

import SwitchButton from "../../../../../shared/components/form/SwitchButton";
import RiskOverTimeGraph from "../components/RiskOverTimeGraph";

const RiskOverTime = () => {
  const [activeZoom, setActiveZoom] = useState("3");
  const [isSchedule, setIsSchedule] = useState<boolean>(false);

  const activeZoomChangeHanlder = (zoom: string) => {
    if (activeZoom !== zoom) setActiveZoom(zoom);
  };

  const isScheduleToggle = () => {
    setIsSchedule(!isSchedule);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="tab__header">
            <h4 className="tab__header--title">
              Risk Exposure and Risk Spend Over Time
            </h4>
            <p className="tab__header--view">
              <div className="switch__wrap" style={{ marginRight: "20px" }}>
                <p>Schedule Risk</p>
                <SwitchButton
                  name="over-time__scheduleRisk"
                  small
                  id="over-time__scheduleRisk"
                  onChange={isScheduleToggle}
                  checked={isSchedule}
                />
              </div>
              View full stats
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="over-time__subheader">
            <div className="over-time__zoom">
              <span>Zoom:</span>
              <ul>
                {/* <li
                onClick={() => activeZoomChangeHanlder("1")}
                className={activeZoom === "1" ? "active" : ""}
              >
                1d
              </li> */}
                <li
                  onClick={() => activeZoomChangeHanlder("2")}
                  className={activeZoom === "2" ? "active" : ""}
                >
                  1w
                </li>
                <li
                  onClick={() => activeZoomChangeHanlder("3")}
                  className={activeZoom === "3" ? "active" : ""}
                >
                  1m
                </li>
                <li
                  onClick={() => activeZoomChangeHanlder("4")}
                  className={activeZoom === "4" ? "active" : ""}
                >
                  3m
                </li>
                <li
                  onClick={() => activeZoomChangeHanlder("5")}
                  className={activeZoom === "5" ? "active" : ""}
                >
                  6m
                </li>
                <li
                  onClick={() => activeZoomChangeHanlder("6")}
                  className={activeZoom === "6" ? "active" : ""}
                >
                  1y
                </li>
                <li
                  onClick={() => activeZoomChangeHanlder("7")}
                  className={activeZoom === "7" ? "active" : ""}
                >
                  ALL
                </li>
              </ul>
            </div>
            <div className="over-time__range">
              <p>
                01.01.2020 - 30.01.2020 <ChevronDown />
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="tab__graph-container">
          <RiskOverTimeGraph zoom={activeZoom} />
        </div>
      </Row>
    </Container>
  );
};

export default RiskOverTime;
