import React from "react";
import { Button } from "reactstrap";

interface AddUserProps {
  toggleModal: () => void;
}

const AddUser = ({ toggleModal }: AddUserProps) => {
  return (
    <div className="table__header-button add-user">
      <Button color="primary" onClick={() => toggleModal()}>
        SEND NEW INVITE
      </Button>
    </div>
  );
};

export default AddUser;
