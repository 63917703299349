import React from "react";

import { InfoOutlined } from "@material-ui/icons";

const UploadInstructions = () => {
  return (
    <div className="upload-instructions">
      <div className="instructions__faq">
        <InfoOutlined className="instructions__faq-icon" /> First bulk upload?
        Follow these steps!
      </div>
      <div className="instructions__steps">
        <ul>
          <li>
            <p className="instructions__step-title">Step 1</p>
            <p>
              Download the relevant blank template from the Registers section.
            </p>
          </li>
          <li>
            <p className="instructions__step-title">Step 2</p>
            <p>
              Enter your data on the blank template and save it when you’re
              done. <br /> All mandatory fields must be populated.
            </p>
          </li>
          <li>
            <p className="instructions__step-title">Step 3</p>
            <p>
              Drag and drop/browse your computer to upload the saved template
              and validate it. <br /> If any errors are flagged, make the
              required changes and submit the file again.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UploadInstructions;
