import React from "react";
import { useSelector } from "react-redux";
import { Container, Card, CardBody, Row, Col } from "reactstrap";

import { RootState } from "../../../redux/store";
import OrganisationsForm from "../../Organisations/OrganisationsForm";

const CreateOrganisation = () => {
  const editMode = useSelector(
    (state: RootState) => state.organisations.editMode
  );

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {editMode
              ? `Welcome, Anne, here you can change the defaults for your Organisation`
              : `Welcome Anne. Here you can view and edit your existing organisations or add new ones.`}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <OrganisationsForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateOrganisation;
