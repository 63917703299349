import { IGanttProject } from "../../types/interfaces";

const currentDate = new Date();

export const ganttData: IGanttProject[] = [
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    name: "Civils",
    id: "P-0001",
    type: "project",
    riskDuration: 9,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
        name: "Dig Foundations",
        id: "T-0023",
        type: "task",
        riskDuration: 3,
        risks: [
          {
            id: "R-0022",
            title: "Wall Collapse",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0023",
            title: "Plant Availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0024",
            title: "Resource Availability",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
        name: "Install Supports",
        id: "T-0024",
        type: "task",
        riskDuration: 1,
        risks: [
          {
            id: "R-0025",
            title: "Insufficient Loading",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0026",
            title: "Plant Failure",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0022",
            title: "Wall collapse",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
        name: "Pour Concrete",
        id: "T-0025",
        type: "task",
        riskDuration: 2,
        risks: [
          {
            id: "R-0027",
            title: "Concrete Test Failure",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0028",
            title: "Logistics",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0029",
            title: "Concrete Spillage",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 12),
        name: "Glazing",
        id: "T-0026",
        type: "task",
        riskDuration: 2,
        risks: [
          {
            id: "R-0030",
            title: "Late Delivery",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0031",
            title: "Glass Breakage",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0032",
            title: "Incorrect Measurements",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 14),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 16),
        name: "Finish Surface",
        id: "T-0027",
        type: "task",
        riskDuration: 1,
        risks: [
          {
            id: "R-0033",
            title: "Finishing Plant availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0034",
            title: "Surface Failures",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0035",
            title: "Damage to Finished Surface",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 17),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24),
    name: "Enabling Works",
    id: "P-0002",
    type: "project",
    riskDuration: 7,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 17),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
        name: "Install Electrical Supply",
        id: "T-0028",
        type: "task",
        riskDuration: 1,
        risks: [
          {
            id: "R-0036",
            title: "Power Outage",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0037",
            title: "Testing Equipment Availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0038",
            title: "Testing Resources Constraints",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 21),
        name: "Install Plumbing",
        id: "T-0029",
        type: "task",
        riskDuration: 2,
        risks: [
          {
            id: "R-0039",
            title: "Leakage",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0040",
            title: "Past Availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0041",
            title: "Site Congestion",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24),
        name: "Secure Permissions",
        id: "T-0030",
        type: "task",
        riskDuration: 2,
        risks: [
          {
            id: "R-0042",
            title: "Permissions Refused",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0043",
            title: "Permissions Granted with Conditions",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0044",
            title: "Incorrect Information Submitted",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 26),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 28),
        name: "Procure Resource",
        id: "T-0031",
        type: "task",
        riskDuration: 1,
        risks: [
          {
            id: "R-0045",
            title: "Specialist Resource Availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0046",
            title: "Increased Resource Costs",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0047",
            title: "Competing Projects",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 29),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
        name: "Hire Plant",
        id: "T-0032",
        type: "task",
        riskDuration: 1,
        risks: [
          {
            id: "R-0048",
            title: "Critical Plant Availability",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0049",
            title: "Plant Breakdown",
            description: "Lorem ipsum dolor sit amet",
          },
          {
            id: "R-0050",
            title: "Plant Parts Availability",
            description: "Lorem ipsum dolor sit amet",
          },
        ],
      },
    ],
  },
  // {
  //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
  //   name: "Fit Out",
  //   id: "P-0003",
  //   type: "project",
  //   riskDuration: 20,
  //   tasks: [
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Ceilings",
  //       id: "T-0033",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0048",
  //           title: "Ceiling Collapse",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0049",
  //           title: "Lightning Fixtures",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0050",
  //           title: "Metal Price Increase",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Floors",
  //       id: "T-0034",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0051",
  //           title: "Tile Logistics Issues",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0052",
  //           title: "Specialist Tiling Resource",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0053",
  //           title: "Site Interface",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Painting",
  //       id: "T-0035",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0054",
  //           title: "Site Access",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0055",
  //           title: "Change in Design",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0056",
  //           title: "Damage to Finished Paint",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Procure Resource",
  //       id: "T-0036",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0030",
  //           title: "Late Delivery",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0032",
  //           title: "Incorrect Measurements",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0057",
  //           title: "Breakage",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Toilets",
  //       id: "T-0037",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0058",
  //           title: "Leakages",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0059",
  //           title: "Misalignment of Specs",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0042",
  //           title: "Specialist Resource Availability",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
  //   name: "Handover",
  //   id: "P-0004",
  //   type: "project",
  //   riskDuration: 20,
  //   tasks: [
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Define Deliverables",
  //       id: "T-0038",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0060",
  //           title: "Scope Gaps",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0061",
  //           title: "Resource Constraints",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0062",
  //           title: "Unclear Requirements",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Prepare Documentation",
  //       id: "T-0039",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0063",
  //           title: "Data Loss",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0064",
  //           title: "Late Receipt of Information",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0065",
  //           title: "Lack of Ownership",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Engage Client",
  //       id: "T-0040",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0066",
  //           title: "Poor Communication Channels",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0067",
  //           title: "Stakeholder Engagement Issues",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0068",
  //           title: "Unclear Scope",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Rectify Snagging",
  //       id: "T-0041",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0069",
  //           title: "Specialist Resource",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0047",
  //           title: "Parts Availability",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0054",
  //           title: "Site Access",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //     {
  //       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //       name: "Secure Sign-Off",
  //       id: "T-0042",
  //       type: "task",
  //       riskDuration: 2,
  //       risks: [
  //         {
  //           id: "R-0070",
  //           title: "Latent Defects",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0071",
  //           title: "Late Provision of Comments",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //         {
  //           id: "R-0072",
  //           title: "Availability of Decision-Makers",
  //           description: "Lorem ipsum dolor sit amet",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
