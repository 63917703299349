import { useState } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import Mitigation from "./Mitigation";

const AssumptionMitigations = () => {
  const [mitigations, setMitigations] = useState(1);

  const addMitigation = () => {
    setMitigations(mitigations + 1);
  };
  return (
    <>
      {[...Array(mitigations)].map((value: undefined, index: number) => (
        <Mitigation key={index} mitigationNumber={index + 1} />
      ))}
      <ButtonToolbar>
        <Button color="primary" outline={true} onClick={addMitigation}>
          + ADD MITIGATION ACTION
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default AssumptionMitigations;
