import React from "react";
// import { ReactComponent as Map } from "../../../shared/img/landing/map.svg";
import logo from "../../../shared/img/logo_big.png";
import map from "../../../shared/img/landing/contacts_map.png";
import Footer from "../components/Footer";
import Header from "../components/Header";



const Contacts = () => {
  return (
    <div className="contacts">
      <Header />
      <div className="contacts__content">
        <div className="contacts__left">
          {/* <Map className="contacts__map" /> */}
          <img src={map} />
        </div>
        <div className="contacts__right">
          <div className="decoration__blue-box" />
          <h1>Contact Information and Office Location</h1>

          <div className="contacts__right-body">
            <ul>
              <li>
                <a href="mailto:graeme.miller@riskoncise.com">
                  graeme.miller@riskoncise.com
                </a>
              </li>
              <li>117 St John’s Way</li>
              <li>London</li>
              <li>N19 3RG</li>
              <li>
                w. <a href="https://www.riskoncise.com">riskoncise.com</a>
              </li>
              <li>t. +447837 998 930</li>
            </ul>
            <img src={logo} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
