import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Row,
  NavLink,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { ArrowRight } from "react-bootstrap-icons";
import { useHistory } from "react-router";

import { ReactComponent as Laptop } from "../../../shared/img/landing/laptop.svg";
import { B2C_SCOPES } from "../../../B2C";

const Hero = () => {
  const { instance } = useMsal();

  const history = useHistory();

  return (
    <div className="landing__hero">
      <Container fluid="sm">
        <Row>
          <Col lg={12} xl={6}>
            <h1>
              <strong>Riskoncise:</strong> Risk Decision-Making Software
            </h1>
            <p>
              Riskoncise has the power to revolutionise the way risk is
              understood, modelled and managed on projects of all sizes. With
              intuitive workflows, automated actions and cutting-edge reporting,
              our user-friendly solution frees up headspace for risk experts and
              non-specialists to focus on the things that really matter.
            </p>
            <ButtonToolbar>
              <Button color="primary">
                Get Started{" "}
                <ArrowRight
                  size={10}
                  onClick={() => instance.loginRedirect(B2C_SCOPES.API_ACCESS)}
                />
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => history.push("/contacts")}
              >
                Contact Sales <ArrowRight size={40} />
              </Button>
            </ButtonToolbar>
          </Col>
          <Col lg={12} xl={6}>
            <Laptop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
