import Config from "./Config.js";

const SESSION_STORAGE = "sessionStorage";

var isIE = function isIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ") > -1;
  var msie11 = ua.indexOf("Trident/") > -1;
  return msie || msie11;
};

export var B2C_SCOPES = {
  API_ACCESS: {
    scopes: Config.B2C.Scopes,
  },
};

export var msalAppConfig = {
  auth: {
    clientId: Config.B2C.ClientId,
    authority: `${Config.B2C.Instance}${Config.B2C.Tenant}/${Config.B2C.SignInPolicy}`,
    knownAuthorities: [`${Config.B2C.Instance}`],
    redirectUri: Config.B2C.RedirectUri,
    validateAuthority: false,
    postLogoutRedirectUri: Config.B2C.RedirectUri,
  },
  cache: {
    cacheLocation: SESSION_STORAGE,
    storeAuthStateInCookie: isIE(),
  },
};
