import { Task } from "gantt-task-react";
import moment from "moment";

export const splitTime = (numberOfHours: number) => {
  var Days = Math.floor(numberOfHours / 24);
  var Remainder = numberOfHours % 24;
  var Hours = Math.floor(Remainder);
  var Minutes = Math.floor(60 * (Remainder - Hours));
  return { d: Days, h: Hours, m: Minutes };
};

export const commaDecimal = (number: string) => {
  let result = number.replace(".", ",");
  return result;
};

export const priceMask = (number: number) => {
  let result = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    currency: "GBP",
  }).format(Number(number.toFixed(1)));

  return result;
};

export const fullPriceMask = (number: number) => {
  let result = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    currency: "GBP",
    minimumFractionDigits: 3,
  }).format(number);

  return commaDecimal(result);
};

export function ganttGetStartEndDateForProject(
  tasks: Task[],
  projectId: string
) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }

  return [start, end];
}

export const ganttDurationInDays = (endDate: Date, startDate: Date) => {
  return moment(endDate).diff(startDate, "days");
};

export const ganttStartingDay = (date: Date) => {
  return moment(date).diff(moment().startOf("month"), "days");
};
