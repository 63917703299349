import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { Task } from "gantt-task-react";

import { getProjectsRequest } from "../../api/projects";
import { IProject, IRisk } from "../../types/interfaces";

interface DashboardState {
  piechartData: number;
  projects: Array<IProject>;
  project?: IProject | null;
  mitigation: boolean;
  isOpportunities: boolean;
  activeProject: string | null;
  scheduleRisk: boolean;
  monteCarloGraphType: boolean;
  isGanttChartRisks: boolean;
  ganttChartData: Task[];
}

const initialState: DashboardState = {
  piechartData: 0,
  projects: [],
  project: null,
  mitigation: false,
  isOpportunities: false,
  activeProject: null,
  scheduleRisk: false,
  monteCarloGraphType: false,
  isGanttChartRisks: true,
  ganttChartData: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    onProjectsReceived: (state, action: PayloadAction<Array<IProject>>) => {
      state.projects = action.payload;
      state.project = action.payload[0];
    },
    onMitigationToggle: (state, action: PayloadAction<boolean>) => {
      state.mitigation = action.payload;
    },
    onOpportunityToggle: (state, action: PayloadAction<boolean>) => {
      state.isOpportunities = action.payload;
    },
    onActiveProjectChange: (state, action: PayloadAction<string>) => {
      state.activeProject = action.payload;
    },
    onScheduleRiskToogle: (state, action: PayloadAction<boolean>) => {
      state.scheduleRisk = action.payload;
    },
    onMonteCarloGraphTypeToggle: (state) => {
      state.monteCarloGraphType = !state.monteCarloGraphType;
    },
    onIsGanttChartRisksToggle: (state) => {
      state.isGanttChartRisks = !state.isGanttChartRisks;
    },
    onGanttChartDataChange: (state, action: PayloadAction<Task[]>) => {
      state.ganttChartData = action.payload;
    },
  },
});

export const {
  onProjectsReceived,
  onMitigationToggle,
  onActiveProjectChange,
  onOpportunityToggle,
  onScheduleRiskToogle,
  onMonteCarloGraphTypeToggle,
  onIsGanttChartRisksToggle,
  onGanttChartDataChange,
} = dashboardSlice.actions;

export const getProjects = () => async (dispatch: Dispatch) => {
  const result = await getProjectsRequest();
  dispatch(onProjectsReceived(result.data));
};

export default dashboardSlice.reducer;
