import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { ICreateUser, IUserInvitation } from "../../types/interfaces";
import { createUserInvitationRequest } from "../../api/userInvitation";
import { createUserRequest } from "../../api/applicationUsers";

export type UserModals = "info" | "remove" | "edit" | "invitation";

interface UserManagementState {
  userModal: boolean;
  userModalType: UserModals;
}

const initialState: UserManagementState = {
  userModal: false,
  userModalType: "info",
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    onUserModalToggle(state) {
      state.userModal = !state.userModal;
    },
    onUserModalTypeChange(state, action: PayloadAction<UserModals>) {
      state.userModalType = action.payload;
    },
  },
});

export const { onUserModalToggle, onUserModalTypeChange } =
  userManagementSlice.actions;

export const createUserInvitation =
  (body: IUserInvitation) => async (dispatch: Dispatch) => {
    await createUserInvitationRequest(body);
    dispatch(onUserModalToggle());
  };

export const createUser = (body: ICreateUser) => async (dispatch: Dispatch) => {
  await createUserRequest(body);
};

export default userManagementSlice.reducer;
