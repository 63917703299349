import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Calendar4Week } from "react-bootstrap-icons";

type DatePickerFieldProps = {
  onChange: (
    date: Date | Date[],
    event: React.SyntheticEvent<any, Event>
  ) => void;
};

export const DatePickerField = ({ onChange }: DatePickerFieldProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const handleChange = (date: Date, e: React.SyntheticEvent<any, Event>) => {
    setStartDate(date);
    onChange(date, e);
  };

  return (
    <div className="date-picker">
      <DatePicker
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        // dropDownMode="select"
        // popperPlacement="center"
      />
      <div className="date-picker__icon">
        <Calendar4Week />
      </div>
    </div>
  );
};

type RenderDatePickerFieldProps = {
  input: {
    onChange: any;
  };
};

const RenderDatePickerField = ({ input }: RenderDatePickerFieldProps) => (
  <DatePickerField {...input} />
);

export default RenderDatePickerField;
