import { useState } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { Bell } from "react-bootstrap-icons";

const notifications = [
  {
    id: 0,
    ava: `${process.env.PUBLIC_URL}/img/ava.jpg`,
    name: "Cristopher Changer",
    message: " has started a new project",
    date: "09:02",
  },
  {
    id: 1,
    ava: `${process.env.PUBLIC_URL}/img/ava.jpg`,
    name: "Sveta Narry",
    message: " has closed a project",
    date: "09:00",
  },
  {
    id: 2,
    ava: `${process.env.PUBLIC_URL}/img/ava.jpg`,
    name: "Lory McQueen",
    message: " has started a new project as a Project Managert",
    date: "08:43",
  },
  {
    id: 3,
    ava: `${process.env.PUBLIC_URL}/img/ava.jpg`,
    name: "Cristopher Changer",
    message: " has closed a project",
    date: "08:43",
  },
];

const TopbarNotifications = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleNotification = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className="topbar__collapse">
      <button className="topbar__btn" type="button" onClick={toggleNotification}>
        <Bell />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={toggleNotification}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__collapse-content">
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Notifications</p>
          <button
            aria-label="topbar__collapse-btn"
            className="topbar__collapse-button"
            type="button"
          >
            Mark all as read
          </button>
        </div>
        {notifications.map((notification) => (
          <div className="topbar__collapse-item" key={notification.id}>
            <div className="topbar__collapse-img-wrap">
              <img
                className="topbar__collapse-img"
                src={notification.ava}
                alt=""
              />
            </div>
            <p className="topbar__collapse-message">
              <span className="topbar__collapse-name">{notification.name}</span>
              {notification.message}
            </p>
            <p className="topbar__collapse-date">{notification.date}</p>
          </div>
        ))}
        <Link
          className="topbar__collapse-link"
          to="/online_marketing_dashboard"
          onClick={toggleNotification}
        >
          See all notifications
        </Link>
      </Collapse>
    </div>
  );
};

export default TopbarNotifications;
