export const MCGraphData = [
  {
    id: 1,
    uv: 0.629785651,
  },
  {
    id: 2,
    uv: 0.638715476,
  },
  {
    id: 3,
    uv: 0.647757173,
  },
  {
    id: 4,
    uv: 0.656911914,
  },
  {
    id: 5,
    uv: 0.666180879,
  },
  {
    id: 6,
    uv: 0.675565261,
  },
  {
    id: 7,
    uv: 0.685066258,
  },
  {
    id: 8,
    uv: 0.694685079,
  },
  {
    id: 9,
    uv: 0.704422942,
  },
  {
    id: 10,
    uv: 0.714281074,
  },
  {
    id: 11,
    uv: 0.72426071,
  },
  {
    id: 12,
    uv: 0.734363096,
  },
  {
    id: 13,
    uv: 0.744589485,
  },
  {
    id: 14,
    uv: 0.754941142,
  },
  {
    id: 15,
    uv: 0.765419338,
  },
  {
    id: 16,
    uv: 0.776025355,
  },
  {
    id: 17,
    uv: 0.786760484,
  },
  {
    id: 18,
    uv: 0.797626026,
  },
  {
    id: 19,
    uv: 0.808623289,
  },
  {
    id: 20,
    uv: 0.819753592,
  },
  {
    id: 21,
    uv: 0.831018264,
  },
  {
    id: 22,
    uv: 0.84241864,
  },
  {
    id: 23,
    uv: 0.853956068,
  },
  {
    id: 24,
    uv: 0.865631903,
  },
  {
    id: 25,
    uv: 0.87744751,
  },
  {
    id: 26,
    uv: 0.889404263,
  },
  {
    id: 27,
    uv: 0.901503547,
  },
  {
    id: 28,
    uv: 0.913746753,
  },
  {
    id: 29,
    uv: 0.926135285,
  },
  {
    id: 30,
    uv: 0.938670553,
  },
  {
    id: 31,
    uv: 0.95135398,
  },
  {
    id: 32,
    uv: 0.964186995,
  },
  {
    id: 33,
    uv: 0.977171037,
  },
  {
    id: 34,
    uv: 0.990307556,
  },
  {
    id: 35,
    uv: 1.00359801,
  },
  {
    id: 36,
    uv: 1.017043867,
  },
  {
    id: 37,
    uv: 1.030646603,
  },
  {
    id: 38,
    uv: 1.044407706,
  },
  {
    id: 39,
    uv: 1.058328671,
  },
  {
    id: 40,
    uv: 1.072411002,
  },
  {
    id: 41,
    uv: 1.086656215,
  },
  {
    id: 42,
    uv: 1.101065832,
  },
  {
    id: 43,
    uv: 1.115641388,
  },
  {
    id: 44,
    uv: 1.130384424,
  },
  {
    id: 45,
    uv: 1.145296492,
  },
  {
    id: 46,
    uv: 1.160379152,
  },
  {
    id: 47,
    uv: 1.175633976,
  },
  {
    id: 48,
    uv: 1.191062542,
  },
  {
    id: 49,
    uv: 1.206666439,
  },
  {
    id: 50,
    uv: 1.222447266,
  },
  {
    id: 51,
    uv: 1.238406628,
  },
  {
    id: 52,
    uv: 1.254546144,
  },
  {
    id: 53,
    uv: 1.270867437,
  },
  {
    id: 54,
    uv: 1.287372144,
  },
  {
    id: 55,
    uv: 1.304061907,
  },
  {
    id: 56,
    uv: 1.320938381,
  },
  {
    id: 57,
    uv: 1.338003226,
  },
  {
    id: 58,
    uv: 1.355258115,
  },
  {
    id: 59,
    uv: 1.372704727,
  },
  {
    id: 60,
    uv: 1.390344751,
  },
  {
    id: 61,
    uv: 1.408179887,
  },
  {
    id: 62,
    uv: 1.426211841,
  },
  {
    id: 63,
    uv: 1.44444233,
  },
  {
    id: 64,
    uv: 1.462873078,
  },
  {
    id: 65,
    uv: 1.481505819,
  },
  {
    id: 66,
    uv: 1.500342297,
  },
  {
    id: 67,
    uv: 1.519384264,
  },
  {
    id: 68,
    uv: 1.538633478,
  },
  {
    id: 69,
    uv: 1.558091711,
  },
  {
    id: 70,
    uv: 1.577760739,
  },
  {
    id: 71,
    uv: 1.597642349,
  },
  {
    id: 72,
    uv: 1.617738337,
  },
  {
    id: 73,
    uv: 1.638050506,
  },
  {
    id: 74,
    uv: 1.658580668,
  },
  {
    id: 75,
    uv: 1.679330645,
  },
  {
    id: 76,
    uv: 1.700302265,
  },
  {
    id: 77,
    uv: 1.721497366,
  },
  {
    id: 78,
    uv: 1.742917794,
  },
  {
    id: 79,
    uv: 1.764565403,
  },
  {
    id: 80,
    uv: 1.786442056,
  },
  {
    id: 81,
    uv: 1.808549624,
  },
  {
    id: 82,
    uv: 1.830889985,
  },
  {
    id: 83,
    uv: 1.853465027,
  },
  {
    id: 84,
    uv: 1.876276643,
  },
  {
    id: 85,
    uv: 1.899326739,
  },
  {
    id: 86,
    uv: 1.922617223,
  },
  {
    id: 87,
    uv: 1.946150015,
  },
  {
    id: 88,
    uv: 1.969927041,
  },
  {
    id: 89,
    uv: 1.993950236,
  },
  {
    id: 90,
    uv: 2.018221541,
  },
  {
    id: 91,
    uv: 2.042742905,
  },
  {
    id: 92,
    uv: 2.067516287,
  },
  {
    id: 93,
    uv: 2.092543649,
  },
  {
    id: 94,
    uv: 2.117826964,
  },
  {
    id: 95,
    uv: 2.143368211,
  },
  {
    id: 96,
    uv: 2.169169377,
  },
  {
    id: 97,
    uv: 2.195232454,
  },
  {
    id: 98,
    uv: 2.221559443,
  },
  {
    id: 99,
    uv: 2.248152352,
  },
  {
    id: 100,
    uv: 2.275013195,
  },
  {
    id: 101,
    uv: 2.302143993,
  },
  {
    id: 102,
    uv: 2.329546775,
  },
  {
    id: 103,
    uv: 2.357223575,
  },
  {
    id: 104,
    uv: 2.385176434,
  },
  {
    id: 105,
    uv: 2.4134074,
  },
  {
    id: 106,
    uv: 2.441918528,
  },
  {
    id: 107,
    uv: 2.470711877,
  },
  {
    id: 108,
    uv: 2.499789515,
  },
  {
    id: 109,
    uv: 2.529153514,
  },
  {
    id: 110,
    uv: 2.558805952,
  },
  {
    id: 111,
    uv: 2.588748915,
  },
  {
    id: 112,
    uv: 2.618984494,
  },
  {
    id: 113,
    uv: 2.649514784,
  },
  {
    id: 114,
    uv: 2.680341888,
  },
  {
    id: 115,
    uv: 2.711467912,
  },
  {
    id: 116,
    uv: 2.74289497,
  },
  {
    id: 117,
    uv: 2.774625181,
  },
  {
    id: 118,
    uv: 2.806660666,
  },
  {
    id: 119,
    uv: 2.839003555,
  },
  {
    id: 120,
    uv: 2.871655982,
  },
  {
    id: 121,
    uv: 2.904620084,
  },
  {
    id: 122,
    uv: 2.937898004,
  },
  {
    id: 123,
    uv: 2.971491891,
  },
  {
    id: 124,
    uv: 3.005403896,
  },
  {
    id: 125,
    uv: 3.039636177,
  },
  {
    id: 126,
    uv: 3.074190893,
  },
  {
    id: 127,
    uv: 3.10907021,
  },
  {
    id: 128,
    uv: 3.144276298,
  },
  {
    id: 129,
    uv: 3.179811329,
  },
  {
    id: 130,
    uv: 3.21567748,
  },
  {
    id: 131,
    uv: 3.251876931,
  },
  {
    id: 132,
    uv: 3.288411866,
  },
  {
    id: 133,
    uv: 3.325284473,
  },
  {
    id: 134,
    uv: 3.362496942,
  },
  {
    id: 135,
    uv: 3.400051467,
  },
  {
    id: 136,
    uv: 3.437950245,
  },
  {
    id: 137,
    uv: 3.476195474,
  },
  {
    id: 138,
    uv: 3.514789358,
  },
  {
    id: 139,
    uv: 3.553734102,
  },
  {
    id: 140,
    uv: 3.593031911,
  },
  {
    id: 141,
    uv: 3.632684997,
  },
  {
    id: 142,
    uv: 3.67269557,
  },
  {
    id: 143,
    uv: 3.713065844,
  },
  {
    id: 144,
    uv: 3.753798035,
  },
  {
    id: 145,
    uv: 3.794894359,
  },
  {
    id: 146,
    uv: 3.836357036,
  },
  {
    id: 147,
    uv: 3.878188286,
  },
  {
    id: 148,
    uv: 3.920390329,
  },
  {
    id: 149,
    uv: 3.962965388,
  },
  {
    id: 150,
    uv: 4.005915686,
  },
  {
    id: 151,
    uv: 4.049243448,
  },
  {
    id: 152,
    uv: 4.092950898,
  },
  {
    id: 153,
    uv: 4.137040261,
  },
  {
    id: 154,
    uv: 4.181513761,
  },
  {
    id: 155,
    uv: 4.226373626,
  },
  {
    id: 156,
    uv: 4.271622079,
  },
  {
    id: 157,
    uv: 4.317261346,
  },
  {
    id: 158,
    uv: 4.363293652,
  },
  {
    id: 159,
    uv: 4.409721221,
  },
  {
    id: 160,
    uv: 4.456546276,
  },
  {
    id: 161,
    uv: 4.503771039,
  },
  {
    id: 162,
    uv: 4.551397732,
  },
  {
    id: 163,
    uv: 4.599428575,
  },
  {
    id: 164,
    uv: 4.647865786,
  },
  {
    id: 165,
    uv: 4.696711583,
  },
  {
    id: 166,
    uv: 4.74596818,
  },
  {
    id: 167,
    uv: 4.795637791,
  },
  {
    id: 168,
    uv: 4.845722627,
  },
  {
    id: 169,
    uv: 4.896224895,
  },
  {
    id: 170,
    uv: 4.947146803,
  },
  {
    id: 171,
    uv: 4.998490554,
  },
  {
    id: 172,
    uv: 5.050258347,
  },
  {
    id: 173,
    uv: 5.102452381,
  },
  {
    id: 174,
    uv: 5.155074849,
  },
  {
    id: 175,
    uv: 5.208127942,
  },
  {
    id: 176,
    uv: 5.261613845,
  },
  {
    id: 177,
    uv: 5.315534744,
  },
  {
    id: 178,
    uv: 5.369892815,
  },
  {
    id: 179,
    uv: 5.424690234,
  },
  {
    id: 180,
    uv: 5.47992917,
  },
  {
    id: 181,
    uv: 5.535611789,
  },
  {
    id: 182,
    uv: 5.591740252,
  },
  {
    id: 183,
    uv: 5.648316713,
  },
  {
    id: 184,
    uv: 5.705343324,
  },
  {
    id: 185,
    uv: 5.762822228,
  },
  {
    id: 186,
    uv: 5.820755564,
  },
  {
    id: 187,
    uv: 5.879145466,
  },
  {
    id: 188,
    uv: 5.937994059,
  },
  {
    id: 189,
    uv: 5.997303466,
  },
  {
    id: 190,
    uv: 6.0570758,
  },
  {
    id: 191,
    uv: 6.117313168,
  },
  {
    id: 192,
    uv: 6.178017671,
  },
  {
    id: 193,
    uv: 6.239191402,
  },
  {
    id: 194,
    uv: 6.300836446,
  },
  {
    id: 195,
    uv: 6.362954883,
  },
  {
    id: 196,
    uv: 6.425548782,
  },
  {
    id: 197,
    uv: 6.488620206,
  },
  {
    id: 198,
    uv: 6.552171209,
  },
  {
    id: 199,
    uv: 6.616203837,
  },
  {
    id: 200,
    uv: 6.680720127,
  },
  {
    id: 201,
    uv: 6.745722107,
  },
  {
    id: 202,
    uv: 6.811211797,
  },
  {
    id: 203,
    uv: 6.877191205,
  },
  {
    id: 204,
    uv: 6.943662333,
  },
  {
    id: 205,
    uv: 7.010627171,
  },
  {
    id: 206,
    uv: 7.078087699,
  },
  {
    id: 207,
    uv: 7.146045888,
  },
  {
    id: 208,
    uv: 7.214503697,
  },
  {
    id: 209,
    uv: 7.283463075,
  },
  {
    id: 210,
    uv: 7.352925961,
  },
  {
    id: 211,
    uv: 7.422894282,
  },
  {
    id: 212,
    uv: 7.493369953,
  },
  {
    id: 213,
    uv: 7.56435488,
  },
  {
    id: 214,
    uv: 7.635850954,
  },
  {
    id: 215,
    uv: 7.707860055,
  },
  {
    id: 216,
    uv: 7.780384053,
  },
  {
    id: 217,
    uv: 7.853424802,
  },
  {
    id: 218,
    uv: 7.926984145,
  },
  {
    id: 219,
    uv: 8.001063914,
  },
  {
    id: 220,
    uv: 8.075665923,
  },
  {
    id: 221,
    uv: 8.150791978,
  },
  {
    id: 222,
    uv: 8.226443868,
  },
  {
    id: 223,
    uv: 8.302623368,
  },
  {
    id: 224,
    uv: 8.379332242,
  },
  {
    id: 225,
    uv: 8.456572235,
  },
  {
    id: 226,
    uv: 8.534345082,
  },
  {
    id: 227,
    uv: 8.612652501,
  },
  {
    id: 228,
    uv: 8.691496195,
  },
  {
    id: 229,
    uv: 8.770877851,
  },
  {
    id: 230,
    uv: 8.850799144,
  },
  {
    id: 231,
    uv: 8.931261728,
  },
  {
    id: 232,
    uv: 9.012267246,
  },
  {
    id: 233,
    uv: 9.093817323,
  },
  {
    id: 234,
    uv: 9.175913565,
  },
  {
    id: 235,
    uv: 9.258557566,
  },
  {
    id: 236,
    uv: 9.341750899,
  },
  {
    id: 237,
    uv: 9.425495124,
  },
  {
    id: 238,
    uv: 9.50979178,
  },
  {
    id: 239,
    uv: 9.50979178,
  },
  {
    id: 240,
    uv: 9.50979178,
  },
  {
    id: 241,
    uv: 9.50979178,
  },
  {
    id: 242,
    uv: 9.50979178,
  },
  {
    id: 243,
    uv: 9.939614202,
  },
  {
    id: 244,
    uv: 10.0272568,
  },
  {
    id: 245,
    uv: 10.1154621,
  },
  {
    id: 246,
    uv: 10.20423151,
  },
  {
    id: 247,
    uv: 10.29356639,
  },
  {
    id: 248,
    uv: 10.38346811,
  },
  {
    id: 249,
    uv: 10.473938,
  },
  {
    id: 250,
    uv: 10.56497737,
  },
  {
    id: 251,
    uv: 10.65658751,
  },
  {
    id: 252,
    uv: 10.74876971,
  },
  {
    id: 253,
    uv: 10.84152521,
  },
  {
    id: 254,
    uv: 10.93485524,
  },
  {
    id: 255,
    uv: 11.02876103,
  },
  {
    id: 256,
    uv: 11.12324374,
  },
  {
    id: 257,
    uv: 11.21830457,
  },
  {
    id: 258,
    uv: 11.31394464,
  },
  {
    id: 259,
    uv: 11.41016509,
  },
  {
    id: 260,
    uv: 11.50696702,
  },
  {
    id: 261,
    uv: 11.60435151,
  },
  {
    id: 262,
    uv: 11.7023196,
  },
  {
    id: 263,
    uv: 11.80087235,
  },
  {
    id: 264,
    uv: 11.80087235,
  },
  {
    id: 265,
    uv: 11.80087235,
  },
  {
    id: 266,
    uv: 11.80087235,
  },
  {
    id: 267,
    uv: 12.20094964,
  },
  {
    id: 268,
    uv: 12.30244031,
  },
  {
    id: 269,
    uv: 12.40452132,
  },
  {
    id: 270,
    uv: 12.50719356,
  },
  {
    id: 271,
    uv: 12.61045787,
  },
  {
    id: 272,
    uv: 12.71431506,
  },
  {
    id: 273,
    uv: 12.81876592,
  },
  {
    id: 274,
    uv: 12.92381122,
  },
  {
    id: 275,
    uv: 13.02945171,
  },
  {
    id: 276,
    uv: 13.1356881,
  },
  {
    id: 277,
    uv: 13.24252109,
  },
  {
    id: 278,
    uv: 13.34995132,
  },
  {
    id: 279,
    uv: 13.45797946,
  },
  {
    id: 280,
    uv: 13.56660609,
  },
  {
    id: 281,
    uv: 13.67583182,
  },
  {
    id: 282,
    uv: 13.7856572,
  },
  {
    id: 283,
    uv: 13.89608276,
  },
  {
    id: 284,
    uv: 14.00710901,
  },
  {
    id: 285,
    uv: 14.11873642,
  },
  {
    id: 286,
    uv: 14.23096544,
  },
  {
    id: 287,
    uv: 14.34379649,
  },
  {
    id: 288,
    uv: 14.45722997,
  },
  {
    id: 289,
    uv: 14.57126624,
  },
  {
    id: 290,
    uv: 14.68590564,
  },
  {
    id: 291,
    uv: 14.80114848,
  },
  {
    id: 292,
    uv: 14.91699503,
  },
  {
    id: 293,
    uv: 15.03344556,
  },
  {
    id: 294,
    uv: 15.15050028,
  },
  {
    id: 295,
    uv: 15.26815938,
  },
  {
    id: 296,
    uv: 15.38642304,
  },
  {
    id: 297,
    uv: 15.38642304,
  },
  {
    id: 298,
    uv: 15.38642304,
  },
  {
    id: 299,
    uv: 15.38642304,
  },
  {
    id: 300,
    uv: 15.86552539,
  },
  {
    id: 301,
    uv: 15.98681322,
  },
  {
    id: 302,
    uv: 16.10870595,
  },
  {
    id: 303,
    uv: 16.23120355,
  },
  {
    id: 304,
    uv: 16.35430593,
  },
  {
    id: 305,
    uv: 16.478013,
  },
  {
    id: 306,
    uv: 16.60232461,
  },
  {
    id: 307,
    uv: 16.72724059,
  },
  {
    id: 308,
    uv: 16.85276075,
  },
  {
    id: 309,
    uv: 16.97888485,
  },
  {
    id: 310,
    uv: 17.10561263,
  },
  {
    id: 311,
    uv: 17.2329438,
  },
  {
    id: 312,
    uv: 17.36087803,
  },
  {
    id: 313,
    uv: 17.48941497,
  },
  {
    id: 314,
    uv: 17.61855422,
  },
  {
    id: 315,
    uv: 17.74829537,
  },
  {
    id: 316,
    uv: 17.87863796,
  },
  {
    id: 317,
    uv: 18.00958151,
  },
  {
    id: 318,
    uv: 18.14112549,
  },
  {
    id: 319,
    uv: 18.27326936,
  },
  {
    id: 320,
    uv: 18.40601253,
  },
  {
    id: 321,
    uv: 18.5393544,
  },
  {
    id: 322,
    uv: 18.6732943,
  },
  {
    id: 323,
    uv: 18.80783157,
  },
  {
    id: 324,
    uv: 18.94296548,
  },
  {
    id: 325,
    uv: 19.07869529,
  },
  {
    id: 326,
    uv: 19.21502021,
  },
  {
    id: 327,
    uv: 19.35193944,
  },
  {
    id: 328,
    uv: 19.48945213,
  },
  {
    id: 329,
    uv: 19.62755739,
  },
  {
    id: 330,
    uv: 19.76625431,
  },
  {
    id: 331,
    uv: 19.90554195,
  },
  {
    id: 332,
    uv: 20.04541933,
  },
  {
    id: 333,
    uv: 20.18588542,
  },
  {
    id: 334,
    uv: 20.32693918,
  },
  {
    id: 335,
    uv: 20.32693918,
  },
  {
    id: 336,
    uv: 20.32693918,
  },
  {
    id: 337,
    uv: 20.32693918,
  },
  {
    id: 338,
    uv: 20.89700879,
  },
  {
    id: 339,
    uv: 21.04098399,
  },
  {
    id: 340,
    uv: 21.18553986,
  },
  {
    id: 341,
    uv: 21.33067511,
  },
  {
    id: 342,
    uv: 21.47638842,
  },
  {
    id: 343,
    uv: 21.62267843,
  },
  {
    id: 344,
    uv: 21.76954376,
  },
  {
    id: 345,
    uv: 21.91698298,
  },
  {
    id: 346,
    uv: 22.06499463,
  },
  {
    id: 347,
    uv: 22.21357723,
  },
  {
    id: 348,
    uv: 22.36272924,
  },
  {
    id: 349,
    uv: 22.51244911,
  },
  {
    id: 350,
    uv: 22.66273524,
  },
  {
    id: 351,
    uv: 22.81358599,
  },
  {
    id: 352,
    uv: 22.96499972,
  },
  {
    id: 353,
    uv: 23.11697471,
  },
  {
    id: 354,
    uv: 23.26950923,
  },
  {
    id: 355,
    uv: 23.42260152,
  },
  {
    id: 356,
    uv: 23.57624978,
  },
  {
    id: 357,
    uv: 23.73045216,
  },
  {
    id: 358,
    uv: 23.88520681,
  },
  {
    id: 359,
    uv: 24.04051181,
  },
  {
    id: 360,
    uv: 24.19636522,
  },
  {
    id: 361,
    uv: 24.35276508,
  },
  {
    id: 362,
    uv: 24.50970937,
  },
  {
    id: 363,
    uv: 24.66719605,
  },
  {
    id: 364,
    uv: 24.82522305,
  },
  {
    id: 365,
    uv: 24.98378825,
  },
  {
    id: 366,
    uv: 25.14288951,
  },
  {
    id: 367,
    uv: 25.30252465,
  },
  {
    id: 368,
    uv: 25.46269147,
  },
  {
    id: 369,
    uv: 25.6233877,
  },
  {
    id: 370,
    uv: 25.78461108,
  },
  {
    id: 371,
    uv: 25.94635929,
  },
  {
    id: 372,
    uv: 26.10862997,
  },
  {
    id: 373,
    uv: 26.27142075,
  },
  {
    id: 374,
    uv: 26.43472921,
  },
  {
    id: 375,
    uv: 26.5985529,
  },
  {
    id: 376,
    uv: 26.76288935,
  },
  {
    id: 377,
    uv: 26.92773602,
  },
  {
    id: 378,
    uv: 27.09309038,
  },
  {
    id: 379,
    uv: 27.25894983,
  },
  {
    id: 380,
    uv: 27.42531178,
  },
  {
    id: 381,
    uv: 27.59217355,
  },
  {
    id: 382,
    uv: 27.75953248,
  },
  {
    id: 383,
    uv: 27.92738584,
  },
  {
    id: 384,
    uv: 28.09573089,
  },
  {
    id: 385,
    uv: 28.26456485,
  },
  {
    id: 386,
    uv: 28.4338849,
  },
  {
    id: 387,
    uv: 28.60368821,
  },
  {
    id: 388,
    uv: 28.77397188,
  },
  {
    id: 389,
    uv: 28.94473302,
  },
  {
    id: 390,
    uv: 29.11596868,
  },
  {
    id: 391,
    uv: 29.28767588,
  },
  {
    id: 392,
    uv: 29.45985162,
  },
  {
    id: 393,
    uv: 29.63249286,
  },
  {
    id: 394,
    uv: 29.80559654,
  },
  {
    id: 395,
    uv: 29.97915955,
  },
  {
    id: 396,
    uv: 30.15317875,
  },
  {
    id: 397,
    uv: 30.327651,
  },
  {
    id: 398,
    uv: 30.50257309,
  },
  {
    id: 399,
    uv: 30.6779418,
  },
  {
    id: 400,
    uv: 30.6779418,
  },
  {
    id: 401,
    uv: 30.6779418,
  },
  {
    id: 402,
    uv: 31.20669494,
  },
  {
    id: 403,
    uv: 31.38381728,
  },
  {
    id: 404,
    uv: 31.56136965,
  },
  {
    id: 405,
    uv: 31.73934866,
  },
  {
    id: 406,
    uv: 31.91775088,
  },
  {
    id: 407,
    uv: 32.09657283,
  },
  {
    id: 408,
    uv: 32.27581103,
  },
  {
    id: 409,
    uv: 32.45546194,
  },
  {
    id: 410,
    uv: 32.63552203,
  },
  {
    id: 411,
    uv: 32.81598771,
  },
  {
    id: 412,
    uv: 32.99685537,
  },
  {
    id: 413,
    uv: 33.17812137,
  },
  {
    id: 414,
    uv: 33.35978206,
  },
  {
    id: 415,
    uv: 33.54183374,
  },
  {
    id: 416,
    uv: 33.72427268,
  },
  {
    id: 417,
    uv: 33.90709516,
  },
  {
    id: 418,
    uv: 34.09029738,
  },
  {
    id: 419,
    uv: 34.27387555,
  },
  {
    id: 420,
    uv: 34.45782584,
  },
  {
    id: 421,
    uv: 34.6421444,
  },
  {
    id: 422,
    uv: 34.82682735,
  },
  {
    id: 423,
    uv: 35.01187078,
  },
  {
    id: 424,
    uv: 35.19727076,
  },
  {
    id: 425,
    uv: 35.38302333,
  },
  {
    id: 426,
    uv: 35.56912452,
  },
  {
    id: 427,
    uv: 35.75557031,
  },
  {
    id: 428,
    uv: 35.94235668,
  },
  {
    id: 429,
    uv: 36.12947956,
  },
  {
    id: 430,
    uv: 36.31693488,
  },
  {
    id: 431,
    uv: 36.50471854,
  },
  {
    id: 432,
    uv: 36.6928264,
  },
  {
    id: 433,
    uv: 36.88125431,
  },
  {
    id: 434,
    uv: 37.06999811,
  },
  {
    id: 435,
    uv: 37.25905358,
  },
  {
    id: 436,
    uv: 37.44841653,
  },
  {
    id: 437,
    uv: 37.63808269,
  },
  {
    id: 438,
    uv: 37.82804782,
  },
  {
    id: 439,
    uv: 38.01830762,
  },
  {
    id: 440,
    uv: 38.20885778,
  },
  {
    id: 441,
    uv: 38.39969399,
  },
  {
    id: 442,
    uv: 38.59081188,
  },
  {
    id: 443,
    uv: 38.7822071,
  },
  {
    id: 444,
    uv: 38.97387524,
  },
  {
    id: 445,
    uv: 39.16581192,
  },
  {
    id: 446,
    uv: 39.35801268,
  },
  {
    id: 447,
    uv: 39.55047309,
  },
  {
    id: 448,
    uv: 39.74318868,
  },
  {
    id: 449,
    uv: 39.93615496,
  },
  {
    id: 450,
    uv: 40.12936743,
  },
  {
    id: 451,
    uv: 40.32282157,
  },
  {
    id: 452,
    uv: 40.51651283,
  },
  {
    id: 453,
    uv: 40.71043666,
  },
  {
    id: 454,
    uv: 40.90458849,
  },
  {
    id: 455,
    uv: 41.09896371,
  },
  {
    id: 456,
    uv: 41.29355774,
  },
  {
    id: 457,
    uv: 41.48836593,
  },
  {
    id: 458,
    uv: 41.68338365,
  },
  {
    id: 459,
    uv: 41.87860625,
  },
  {
    id: 460,
    uv: 42.07402906,
  },
  {
    id: 461,
    uv: 42.26964738,
  },
  {
    id: 462,
    uv: 42.46545653,
  },
  {
    id: 463,
    uv: 42.66145178,
  },
  {
    id: 464,
    uv: 42.85762841,
  },
  {
    id: 465,
    uv: 43.05398168,
  },
  {
    id: 466,
    uv: 43.25050683,
  },
  {
    id: 467,
    uv: 43.4471991,
  },
  {
    id: 468,
    uv: 43.64405371,
  },
  {
    id: 469,
    uv: 43.84106587,
  },
  {
    id: 470,
    uv: 44.03823076,
  },
  {
    id: 471,
    uv: 44.23554359,
  },
  {
    id: 472,
    uv: 44.43299952,
  },
  {
    id: 473,
    uv: 44.63059372,
  },
  {
    id: 474,
    uv: 44.82832133,
  },
  {
    id: 475,
    uv: 45.02617752,
  },
  {
    id: 476,
    uv: 45.2241574,
  },
  {
    id: 477,
    uv: 45.4222561,
  },
  {
    id: 478,
    uv: 45.62046875,
  },
  {
    id: 479,
    uv: 45.81879044,
  },
  {
    id: 480,
    uv: 46.01721627,
  },
  {
    id: 481,
    uv: 46.21574135,
  },
  {
    id: 482,
    uv: 46.41436074,
  },
  {
    id: 483,
    uv: 46.61306954,
  },
  {
    id: 484,
    uv: 46.8118628,
  },
  {
    id: 485,
    uv: 47.01073559,
  },
  {
    id: 486,
    uv: 47.20968298,
  },
  {
    id: 487,
    uv: 47.40870001,
  },
  {
    id: 488,
    uv: 47.60778173,
  },
  {
    id: 489,
    uv: 47.80692319,
  },
  {
    id: 490,
    uv: 48.00611942,
  },
  {
    id: 491,
    uv: 48.20536545,
  },
  {
    id: 492,
    uv: 48.40465631,
  },
  {
    id: 493,
    uv: 48.60398704,
  },
  {
    id: 494,
    uv: 48.80335266,
  },
  {
    id: 495,
    uv: 49.00274818,
  },
  {
    id: 496,
    uv: 49.20216863,
  },
  {
    id: 497,
    uv: 49.40160902,
  },
  {
    id: 498,
    uv: 49.60106437,
  },
  {
    id: 499,
    uv: 49.80052969,
  },
  {
    id: 500,
    uv: 50,
  },
  {
    id: 501,
    uv: 50.19947031,
  },
  {
    id: 502,
    uv: 50.39893563,
  },
  {
    id: 503,
    uv: 50.59839098,
  },
  {
    id: 504,
    uv: 50.79783137,
  },
  {
    id: 505,
    uv: 50.99725182,
  },
  {
    id: 506,
    uv: 51.19664734,
  },
  {
    id: 507,
    uv: 51.39601296,
  },
  {
    id: 508,
    uv: 51.59534369,
  },
  {
    id: 509,
    uv: 51.79463455,
  },
  {
    id: 510,
    uv: 51.99388058,
  },
  {
    id: 511,
    uv: 52.19307681,
  },
  {
    id: 512,
    uv: 52.39221827,
  },
  {
    id: 513,
    uv: 52.59129999,
  },
  {
    id: 514,
    uv: 52.79031702,
  },
  {
    id: 515,
    uv: 52.98926441,
  },
  {
    id: 516,
    uv: 53.1881372,
  },
  {
    id: 517,
    uv: 53.38693046,
  },
  {
    id: 518,
    uv: 53.58563926,
  },
  {
    id: 519,
    uv: 53.78425865,
  },
  {
    id: 520,
    uv: 53.98278373,
  },
  {
    id: 521,
    uv: 54.18120956,
  },
  {
    id: 522,
    uv: 54.37953125,
  },
  {
    id: 523,
    uv: 54.5777439,
  },
  {
    id: 524,
    uv: 54.7758426,
  },
  {
    id: 525,
    uv: 54.97382248,
  },
  {
    id: 526,
    uv: 55.17167867,
  },
  {
    id: 527,
    uv: 55.36940628,
  },
  {
    id: 528,
    uv: 55.56700048,
  },
  {
    id: 529,
    uv: 55.76445641,
  },
  {
    id: 530,
    uv: 55.96176924,
  },
  {
    id: 531,
    uv: 56.15893413,
  },
  {
    id: 532,
    uv: 56.35594629,
  },
  {
    id: 533,
    uv: 56.5528009,
  },
  {
    id: 534,
    uv: 56.74949317,
  },
  {
    id: 535,
    uv: 56.94601832,
  },
  {
    id: 536,
    uv: 57.14237159,
  },
  {
    id: 537,
    uv: 57.33854822,
  },
  {
    id: 538,
    uv: 57.53454347,
  },
  {
    id: 539,
    uv: 57.73035262,
  },
  {
    id: 540,
    uv: 57.92597094,
  },
  {
    id: 541,
    uv: 58.12139375,
  },
  {
    id: 542,
    uv: 58.31661635,
  },
  {
    id: 543,
    uv: 58.51163407,
  },
  {
    id: 544,
    uv: 58.70644226,
  },
  {
    id: 545,
    uv: 58.90103629,
  },
  {
    id: 546,
    uv: 59.09541151,
  },
  {
    id: 547,
    uv: 59.28956334,
  },
  {
    id: 548,
    uv: 59.48348717,
  },
  {
    id: 549,
    uv: 59.67717843,
  },
  {
    id: 550,
    uv: 59.87063257,
  },
  {
    id: 551,
    uv: 60.06384504,
  },
  {
    id: 552,
    uv: 60.25681132,
  },
  {
    id: 553,
    uv: 60.44952691,
  },
  {
    id: 554,
    uv: 60.64198732,
  },
  {
    id: 555,
    uv: 60.83418808,
  },
  {
    id: 556,
    uv: 61.02612476,
  },
  {
    id: 557,
    uv: 61.2177929,
  },
  {
    id: 558,
    uv: 61.40918812,
  },
  {
    id: 559,
    uv: 61.60030601,
  },
  {
    id: 560,
    uv: 61.79114222,
  },
  {
    id: 561,
    uv: 61.98169238,
  },
  {
    id: 562,
    uv: 62.17195218,
  },
  {
    id: 563,
    uv: 62.36191731,
  },
  {
    id: 564,
    uv: 62.55158347,
  },
  {
    id: 565,
    uv: 62.74094642,
  },
  {
    id: 566,
    uv: 62.93000189,
  },
  {
    id: 567,
    uv: 63.11874569,
  },
  {
    id: 568,
    uv: 63.3071736,
  },
  {
    id: 569,
    uv: 63.49528146,
  },
  {
    id: 570,
    uv: 63.68306512,
  },
  {
    id: 571,
    uv: 63.87052044,
  },
  {
    id: 572,
    uv: 64.05764332,
  },
  {
    id: 573,
    uv: 64.24442969,
  },
  {
    id: 574,
    uv: 64.43087548,
  },
  {
    id: 575,
    uv: 64.61697667,
  },
  {
    id: 576,
    uv: 64.80272924,
  },
  {
    id: 577,
    uv: 64.98812922,
  },
  {
    id: 578,
    uv: 65.17317265,
  },
  {
    id: 579,
    uv: 65.3578556,
  },
  {
    id: 580,
    uv: 65.54217416,
  },
  {
    id: 581,
    uv: 65.72612445,
  },
  {
    id: 582,
    uv: 65.90970262,
  },
  {
    id: 583,
    uv: 66.09290484,
  },
  {
    id: 584,
    uv: 66.27572732,
  },
  {
    id: 585,
    uv: 66.45816626,
  },
  {
    id: 586,
    uv: 66.64021794,
  },
  {
    id: 587,
    uv: 66.82187863,
  },
  {
    id: 588,
    uv: 67.00314463,
  },
  {
    id: 589,
    uv: 67.18401229,
  },
  {
    id: 590,
    uv: 67.36447797,
  },
  {
    id: 591,
    uv: 67.54453806,
  },
  {
    id: 592,
    uv: 67.72418897,
  },
  {
    id: 593,
    uv: 67.90342717,
  },
  {
    id: 594,
    uv: 68.08224912,
  },
  {
    id: 595,
    uv: 68.26065134,
  },
  {
    id: 596,
    uv: 68.43863035,
  },
  {
    id: 597,
    uv: 68.61618272,
  },
  {
    id: 598,
    uv: 68.79330506,
  },
  {
    id: 599,
    uv: 68.96999397,
  },
  {
    id: 600,
    uv: 69.14624613,
  },
  {
    id: 601,
    uv: 69.3220582,
  },
  {
    id: 602,
    uv: 69.49742691,
  },
  {
    id: 603,
    uv: 69.672349,
  },
  {
    id: 604,
    uv: 69.84682125,
  },
  {
    id: 605,
    uv: 70.02084045,
  },
  {
    id: 606,
    uv: 70.19440346,
  },
  {
    id: 607,
    uv: 70.36750714,
  },
  {
    id: 608,
    uv: 70.54014838,
  },
  {
    id: 609,
    uv: 70.71232412,
  },
  {
    id: 610,
    uv: 70.88403132,
  },
  {
    id: 611,
    uv: 71.05526698,
  },
  {
    id: 612,
    uv: 71.22602812,
  },
  {
    id: 613,
    uv: 71.39631179,
  },
  {
    id: 614,
    uv: 71.5661151,
  },
  {
    id: 615,
    uv: 71.73543515,
  },
  {
    id: 616,
    uv: 71.90426911,
  },
  {
    id: 617,
    uv: 72.07261416,
  },
  {
    id: 618,
    uv: 72.24046752,
  },
  {
    id: 619,
    uv: 72.40782645,
  },
  {
    id: 620,
    uv: 72.40782645,
  },
  {
    id: 621,
    uv: 72.40782645,
  },
  {
    id: 622,
    uv: 72.90690962,
  },
  {
    id: 623,
    uv: 73.07226398,
  },
  {
    id: 624,
    uv: 73.23711065,
  },
  {
    id: 625,
    uv: 73.4014471,
  },
  {
    id: 626,
    uv: 73.56527079,
  },
  {
    id: 627,
    uv: 73.72857925,
  },
  {
    id: 628,
    uv: 73.89137003,
  },
  {
    id: 629,
    uv: 74.05364071,
  },
  {
    id: 630,
    uv: 74.21538892,
  },
  {
    id: 631,
    uv: 74.3766123,
  },
  {
    id: 632,
    uv: 74.53730853,
  },
  {
    id: 633,
    uv: 74.69747535,
  },
  {
    id: 634,
    uv: 74.85711049,
  },
  {
    id: 635,
    uv: 75.01621175,
  },
  {
    id: 636,
    uv: 75.17477695,
  },
  {
    id: 637,
    uv: 75.33280395,
  },
  {
    id: 638,
    uv: 75.49029063,
  },
  {
    id: 639,
    uv: 75.64723492,
  },
  {
    id: 640,
    uv: 75.80363478,
  },
  {
    id: 641,
    uv: 75.95948819,
  },
  {
    id: 642,
    uv: 76.11479319,
  },
  {
    id: 643,
    uv: 76.26954784,
  },
  {
    id: 644,
    uv: 76.42375022,
  },
  {
    id: 645,
    uv: 76.57739848,
  },
  {
    id: 646,
    uv: 76.73049077,
  },
  {
    id: 647,
    uv: 76.88302529,
  },
  {
    id: 648,
    uv: 77.03500028,
  },
  {
    id: 649,
    uv: 77.18641401,
  },
  {
    id: 650,
    uv: 77.33726476,
  },
  {
    id: 651,
    uv: 77.48755089,
  },
  {
    id: 652,
    uv: 77.63727076,
  },
  {
    id: 653,
    uv: 77.78642277,
  },
  {
    id: 654,
    uv: 77.93500537,
  },
  {
    id: 655,
    uv: 78.08301702,
  },
  {
    id: 656,
    uv: 78.23045624,
  },
  {
    id: 657,
    uv: 78.37732157,
  },
  {
    id: 658,
    uv: 78.52361158,
  },
  {
    id: 659,
    uv: 78.66932489,
  },
  {
    id: 660,
    uv: 78.81446014,
  },
  {
    id: 661,
    uv: 78.95901601,
  },
  {
    id: 662,
    uv: 79.10299121,
  },
  {
    id: 663,
    uv: 79.2463845,
  },
  {
    id: 664,
    uv: 79.38919464,
  },
  {
    id: 665,
    uv: 79.53142047,
  },
  {
    id: 666,
    uv: 79.67306082,
  },
  {
    id: 667,
    uv: 79.81411458,
  },
  {
    id: 668,
    uv: 79.95458067,
  },
  {
    id: 669,
    uv: 80.09445805,
  },
  {
    id: 670,
    uv: 80.23374569,
  },
  {
    id: 671,
    uv: 80.37244261,
  },
  {
    id: 672,
    uv: 80.51054787,
  },
  {
    id: 673,
    uv: 80.64806056,
  },
  {
    id: 674,
    uv: 80.78497979,
  },
  {
    id: 675,
    uv: 80.92130471,
  },
  {
    id: 676,
    uv: 81.05703452,
  },
  {
    id: 677,
    uv: 81.19216843,
  },
  {
    id: 678,
    uv: 81.3267057,
  },
  {
    id: 679,
    uv: 81.4606456,
  },
  {
    id: 680,
    uv: 81.59398747,
  },
  {
    id: 681,
    uv: 81.72673064,
  },
  {
    id: 682,
    uv: 81.85887451,
  },
  {
    id: 683,
    uv: 81.99041849,
  },
  {
    id: 684,
    uv: 82.12136204,
  },
  {
    id: 685,
    uv: 82.25170463,
  },
  {
    id: 686,
    uv: 82.38144578,
  },
  {
    id: 687,
    uv: 82.51058503,
  },
  {
    id: 688,
    uv: 82.63912197,
  },
  {
    id: 689,
    uv: 82.7670562,
  },
  {
    id: 690,
    uv: 82.89438737,
  },
  {
    id: 691,
    uv: 83.02111515,
  },
  {
    id: 692,
    uv: 83.14723925,
  },
  {
    id: 693,
    uv: 83.27275941,
  },
  {
    id: 694,
    uv: 83.39767539,
  },
  {
    id: 695,
    uv: 83.521987,
  },
  {
    id: 696,
    uv: 83.64569407,
  },
  {
    id: 697,
    uv: 83.76879645,
  },
  {
    id: 698,
    uv: 83.89129405,
  },
  {
    id: 699,
    uv: 84.01318678,
  },
  {
    id: 700,
    uv: 84.13447461,
  },
  {
    id: 701,
    uv: 84.25515751,
  },
  {
    id: 702,
    uv: 84.3752355,
  },
  {
    id: 703,
    uv: 84.49470862,
  },
  {
    id: 704,
    uv: 84.61357696,
  },
  {
    id: 705,
    uv: 84.73184062,
  },
  {
    id: 706,
    uv: 84.84949972,
  },
  {
    id: 707,
    uv: 84.96655444,
  },
  {
    id: 708,
    uv: 85.08300497,
  },
  {
    id: 709,
    uv: 85.19885152,
  },
  {
    id: 710,
    uv: 85.31409436,
  },
  {
    id: 711,
    uv: 85.42873376,
  },
  {
    id: 712,
    uv: 85.54277003,
  },
  {
    id: 713,
    uv: 85.65620351,
  },
  {
    id: 714,
    uv: 85.76903456,
  },
  {
    id: 715,
    uv: 85.88126358,
  },
  {
    id: 716,
    uv: 85.99289099,
  },
  {
    id: 717,
    uv: 86.10391724,
  },
  {
    id: 718,
    uv: 86.2143428,
  },
  {
    id: 719,
    uv: 86.32416818,
  },
  {
    id: 720,
    uv: 86.43339391,
  },
  {
    id: 721,
    uv: 86.54202054,
  },
  {
    id: 722,
    uv: 86.65004868,
  },
  {
    id: 723,
    uv: 86.75747891,
  },
  {
    id: 724,
    uv: 86.8643119,
  },
  {
    id: 725,
    uv: 86.97054829,
  },
  {
    id: 726,
    uv: 87.07618878,
  },
  {
    id: 727,
    uv: 87.18123408,
  },
  {
    id: 728,
    uv: 87.28568494,
  },
  {
    id: 729,
    uv: 87.38954213,
  },
  {
    id: 730,
    uv: 87.49280644,
  },
  {
    id: 731,
    uv: 87.59547868,
  },
  {
    id: 732,
    uv: 87.69755969,
  },
  {
    id: 733,
    uv: 87.79905036,
  },
  {
    id: 734,
    uv: 87.89995156,
  },
  {
    id: 735,
    uv: 88.00026421,
  },
  {
    id: 736,
    uv: 88.09998925,
  },
  {
    id: 737,
    uv: 88.19912765,
  },
  {
    id: 738,
    uv: 88.2976804,
  },
  {
    id: 739,
    uv: 88.39564849,
  },
  {
    id: 740,
    uv: 88.49303298,
  },
  {
    id: 741,
    uv: 88.58983491,
  },
  {
    id: 742,
    uv: 88.68605536,
  },
  {
    id: 743,
    uv: 88.78169543,
  },
  {
    id: 744,
    uv: 88.87675626,
  },
  {
    id: 745,
    uv: 88.97123897,
  },
  {
    id: 746,
    uv: 89.06514476,
  },
  {
    id: 747,
    uv: 89.15847479,
  },
  {
    id: 748,
    uv: 89.25123029,
  },
  {
    id: 749,
    uv: 89.34341249,
  },
  {
    id: 750,
    uv: 89.43502263,
  },
  {
    id: 751,
    uv: 89.526062,
  },
  {
    id: 752,
    uv: 89.61653189,
  },
  {
    id: 753,
    uv: 89.70643361,
  },
  {
    id: 754,
    uv: 89.79576849,
  },
  {
    id: 755,
    uv: 89.8845379,
  },
  {
    id: 756,
    uv: 89.9727432,
  },
  {
    id: 757,
    uv: 90.0603858,
  },
  {
    id: 758,
    uv: 90.1474671,
  },
  {
    id: 759,
    uv: 90.23398853,
  },
  {
    id: 760,
    uv: 90.31995154,
  },
  {
    id: 761,
    uv: 90.40535761,
  },
  {
    id: 762,
    uv: 90.49020822,
  },
  {
    id: 763,
    uv: 90.57450488,
  },
  {
    id: 764,
    uv: 90.6582491,
  },
  {
    id: 765,
    uv: 90.74144243,
  },
  {
    id: 766,
    uv: 90.82408643,
  },
  {
    id: 767,
    uv: 90.90618268,
  },
  {
    id: 768,
    uv: 90.98773275,
  },
  {
    id: 769,
    uv: 91.06873827,
  },
  {
    id: 770,
    uv: 91.14920086,
  },
  {
    id: 771,
    uv: 91.22912215,
  },
  {
    id: 772,
    uv: 91.30850381,
  },
  {
    id: 773,
    uv: 91.3873475,
  },
  {
    id: 774,
    uv: 91.46565492,
  },
  {
    id: 775,
    uv: 91.54342776,
  },
  {
    id: 776,
    uv: 91.62066776,
  },
  {
    id: 777,
    uv: 91.69737663,
  },
  {
    id: 778,
    uv: 91.77355613,
  },
  {
    id: 779,
    uv: 91.84920802,
  },
  {
    id: 780,
    uv: 91.92433408,
  },
  {
    id: 781,
    uv: 91.99893609,
  },
  {
    id: 782,
    uv: 92.07301585,
  },
  {
    id: 783,
    uv: 92.1465752,
  },
  {
    id: 784,
    uv: 92.21961595,
  },
  {
    id: 785,
    uv: 92.29213994,
  },
  {
    id: 786,
    uv: 92.36414905,
  },
  {
    id: 787,
    uv: 92.43564512,
  },
  {
    id: 788,
    uv: 92.50663005,
  },
  {
    id: 789,
    uv: 92.57710572,
  },
  {
    id: 790,
    uv: 92.64707404,
  },
  {
    id: 791,
    uv: 92.71653693,
  },
  {
    id: 792,
    uv: 92.7854963,
  },
  {
    id: 793,
    uv: 92.85395411,
  },
  {
    id: 794,
    uv: 92.9219123,
  },
  {
    id: 795,
    uv: 92.98937283,
  },
  {
    id: 796,
    uv: 93.05633767,
  },
  {
    id: 797,
    uv: 93.12280879,
  },
  {
    id: 798,
    uv: 93.1887882,
  },
  {
    id: 799,
    uv: 93.25427789,
  },
  {
    id: 800,
    uv: 93.31927987,
  },
  {
    id: 801,
    uv: 93.38379616,
  },
  {
    id: 802,
    uv: 93.44782879,
  },
  {
    id: 803,
    uv: 93.51137979,
  },
  {
    id: 804,
    uv: 93.57445122,
  },
  {
    id: 805,
    uv: 93.63704512,
  },
  {
    id: 806,
    uv: 93.69916355,
  },
  {
    id: 807,
    uv: 93.7608086,
  },
  {
    id: 808,
    uv: 93.82198233,
  },
  {
    id: 809,
    uv: 93.88268683,
  },
  {
    id: 810,
    uv: 93.9429242,
  },
  {
    id: 811,
    uv: 94.00269653,
  },
  {
    id: 812,
    uv: 94.06200594,
  },
  {
    id: 813,
    uv: 94.12085453,
  },
  {
    id: 814,
    uv: 94.17924444,
  },
  {
    id: 815,
    uv: 94.23717777,
  },
  {
    id: 816,
    uv: 94.29465668,
  },
  {
    id: 817,
    uv: 94.35168329,
  },
  {
    id: 818,
    uv: 94.40825975,
  },
  {
    id: 819,
    uv: 94.46438821,
  },
  {
    id: 820,
    uv: 94.52007083,
  },
  {
    id: 821,
    uv: 94.57530977,
  },
  {
    id: 822,
    uv: 94.63010719,
  },
  {
    id: 823,
    uv: 94.68446526,
  },
  {
    id: 824,
    uv: 94.73838615,
  },
  {
    id: 825,
    uv: 94.79187206,
  },
  {
    id: 826,
    uv: 94.84492515,
  },
  {
    id: 827,
    uv: 94.89754762,
  },
  {
    id: 828,
    uv: 94.94974165,
  },
  {
    id: 829,
    uv: 95.00150945,
  },
  {
    id: 830,
    uv: 95.0528532,
  },
  {
    id: 831,
    uv: 95.1037751,
  },
  {
    id: 832,
    uv: 95.15427737,
  },
  {
    id: 833,
    uv: 95.20436221,
  },
  {
    id: 834,
    uv: 95.25403182,
  },
  {
    id: 835,
    uv: 95.30328842,
  },
  {
    id: 836,
    uv: 95.35213421,
  },
  {
    id: 837,
    uv: 95.40057142,
  },
  {
    id: 838,
    uv: 95.44860227,
  },
  {
    id: 839,
    uv: 95.49622896,
  },
  {
    id: 840,
    uv: 95.54345372,
  },
  {
    id: 841,
    uv: 95.59027878,
  },
  {
    id: 842,
    uv: 95.63670635,
  },
  {
    id: 843,
    uv: 95.68273865,
  },
  {
    id: 844,
    uv: 95.72837792,
  },
  {
    id: 845,
    uv: 95.77362637,
  },
  {
    id: 846,
    uv: 95.81848624,
  },
  {
    id: 847,
    uv: 95.86295974,
  },
  {
    id: 848,
    uv: 95.9070491,
  },
  {
    id: 849,
    uv: 95.95075655,
  },
  {
    id: 850,
    uv: 95.99408431,
  },
  {
    id: 851,
    uv: 96.03703461,
  },
  {
    id: 852,
    uv: 96.07960967,
  },
  {
    id: 853,
    uv: 96.12181171,
  },
  {
    id: 854,
    uv: 96.16364296,
  },
  {
    id: 855,
    uv: 96.20510564,
  },
  {
    id: 856,
    uv: 96.24620197,
  },
  {
    id: 857,
    uv: 96.28693416,
  },
  {
    id: 858,
    uv: 96.32730443,
  },
  {
    id: 859,
    uv: 96.367315,
  },
  {
    id: 860,
    uv: 96.40696809,
  },
  {
    id: 861,
    uv: 96.4462659,
  },
  {
    id: 862,
    uv: 96.48521064,
  },
  {
    id: 863,
    uv: 96.52380453,
  },
  {
    id: 864,
    uv: 96.56204976,
  },
  {
    id: 865,
    uv: 96.59994853,
  },
  {
    id: 866,
    uv: 96.63750306,
  },
  {
    id: 867,
    uv: 96.67471553,
  },
  {
    id: 868,
    uv: 96.71158813,
  },
  {
    id: 869,
    uv: 96.74812307,
  },
  {
    id: 870,
    uv: 96.78432252,
  },
  {
    id: 871,
    uv: 96.82018867,
  },
  {
    id: 872,
    uv: 96.8557237,
  },
  {
    id: 873,
    uv: 96.89092979,
  },
  {
    id: 874,
    uv: 96.92580911,
  },
  {
    id: 875,
    uv: 96.96036382,
  },
  {
    id: 876,
    uv: 96.9945961,
  },
  {
    id: 877,
    uv: 97.02850811,
  },
  {
    id: 878,
    uv: 97.062102,
  },
  {
    id: 879,
    uv: 97.09537992,
  },
  {
    id: 880,
    uv: 97.12834402,
  },
  {
    id: 881,
    uv: 97.16099644,
  },
  {
    id: 882,
    uv: 97.19333933,
  },
  {
    id: 883,
    uv: 97.22537482,
  },
  {
    id: 884,
    uv: 97.25710503,
  },
  {
    id: 885,
    uv: 97.28853209,
  },
  {
    id: 886,
    uv: 97.31965811,
  },
  {
    id: 887,
    uv: 97.35048522,
  },
  {
    id: 888,
    uv: 97.38101551,
  },
  {
    id: 889,
    uv: 97.41125108,
  },
  {
    id: 890,
    uv: 97.44119405,
  },
  {
    id: 891,
    uv: 97.47084649,
  },
  {
    id: 892,
    uv: 97.50021049,
  },
  {
    id: 893,
    uv: 97.52928812,
  },
  {
    id: 894,
    uv: 97.55808147,
  },
  {
    id: 895,
    uv: 97.5865926,
  },
  {
    id: 896,
    uv: 97.61482357,
  },
  {
    id: 897,
    uv: 97.64277643,
  },
  {
    id: 898,
    uv: 97.67045322,
  },
  {
    id: 899,
    uv: 97.69785601,
  },
  {
    id: 900,
    uv: 97.72498681,
  },
  {
    id: 901,
    uv: 97.75184765,
  },
  {
    id: 902,
    uv: 97.77844056,
  },
  {
    id: 903,
    uv: 97.80476755,
  },
  {
    id: 904,
    uv: 97.83083062,
  },
  {
    id: 905,
    uv: 97.85663179,
  },
  {
    id: 906,
    uv: 97.88217304,
  },
  {
    id: 907,
    uv: 97.90745635,
  },
  {
    id: 908,
    uv: 97.93248371,
  },
  {
    id: 909,
    uv: 97.95725709,
  },
  {
    id: 910,
    uv: 97.98177846,
  },
  {
    id: 911,
    uv: 98.00604976,
  },
  {
    id: 912,
    uv: 98.03007296,
  },
  {
    id: 913,
    uv: 98.05384999,
  },
  {
    id: 914,
    uv: 98.07738278,
  },
  {
    id: 915,
    uv: 98.10067326,
  },
  {
    id: 916,
    uv: 98.12372336,
  },
  {
    id: 917,
    uv: 98.14653497,
  },
  {
    id: 918,
    uv: 98.16911001,
  },
  {
    id: 919,
    uv: 98.19145038,
  },
  {
    id: 920,
    uv: 98.21355794,
  },
  {
    id: 921,
    uv: 98.2354346,
  },
  {
    id: 922,
    uv: 98.25708221,
  },
  {
    id: 923,
    uv: 98.27850263,
  },
  {
    id: 924,
    uv: 98.29969774,
  },
  {
    id: 925,
    uv: 98.32066936,
  },
  {
    id: 926,
    uv: 98.34141933,
  },
  {
    id: 927,
    uv: 98.36194949,
  },
  {
    id: 928,
    uv: 98.38226166,
  },
  {
    id: 929,
    uv: 98.40235765,
  },
  {
    id: 930,
    uv: 98.42223926,
  },
  {
    id: 931,
    uv: 98.44190829,
  },
  {
    id: 932,
    uv: 98.46136652,
  },
  {
    id: 933,
    uv: 98.48061574,
  },
  {
    id: 934,
    uv: 98.4996577,
  },
  {
    id: 935,
    uv: 98.51849418,
  },
  {
    id: 936,
    uv: 98.53712692,
  },
  {
    id: 937,
    uv: 98.55555767,
  },
  {
    id: 938,
    uv: 98.57378816,
  },
  {
    id: 939,
    uv: 98.59182011,
  },
  {
    id: 940,
    uv: 98.60965525,
  },
  {
    id: 941,
    uv: 98.62729527,
  },
  {
    id: 942,
    uv: 98.64474189,
  },
  {
    id: 943,
    uv: 98.66199677,
  },
  {
    id: 944,
    uv: 98.67906162,
  },
  {
    id: 945,
    uv: 98.69593809,
  },
  {
    id: 946,
    uv: 98.71262786,
  },
  {
    id: 947,
    uv: 98.72913256,
  },
  {
    id: 948,
    uv: 98.74545386,
  },
  {
    id: 949,
    uv: 98.76159337,
  },
  {
    id: 950,
    uv: 98.77755273,
  },
  {
    id: 951,
    uv: 98.79333356,
  },
  {
    id: 952,
    uv: 98.80893746,
  },
  {
    id: 953,
    uv: 98.82436602,
  },
  {
    id: 954,
    uv: 98.83962085,
  },
  {
    id: 955,
    uv: 98.85470351,
  },
  {
    id: 956,
    uv: 98.86961558,
  },
  {
    id: 957,
    uv: 98.88435861,
  },
  {
    id: 958,
    uv: 98.89893417,
  },
  {
    id: 959,
    uv: 98.91334379,
  },
  {
    id: 960,
    uv: 98.927589,
  },
  {
    id: 961,
    uv: 98.94167133,
  },
  {
    id: 962,
    uv: 98.95559229,
  },
  {
    id: 963,
    uv: 98.9693534,
  },
  {
    id: 964,
    uv: 98.98295613,
  },
  {
    id: 965,
    uv: 98.99640199,
  },
  {
    id: 966,
    uv: 99.00969244,
  },
  {
    id: 967,
    uv: 99.02282896,
  },
  {
    id: 968,
    uv: 99.03581301,
  },
  {
    id: 969,
    uv: 99.04864602,
  },
  {
    id: 970,
    uv: 99.06132945,
  },
  {
    id: 971,
    uv: 99.07386472,
  },
  {
    id: 972,
    uv: 99.08625325,
  },
  {
    id: 973,
    uv: 99.09849645,
  },
  {
    id: 974,
    uv: 99.11059574,
  },
  {
    id: 975,
    uv: 99.12255249,
  },
  {
    id: 976,
    uv: 99.1343681,
  },
  {
    id: 977,
    uv: 99.14604393,
  },
  {
    id: 978,
    uv: 99.15758136,
  },
  {
    id: 979,
    uv: 99.16898174,
  },
  {
    id: 980,
    uv: 99.18024641,
  },
  {
    id: 981,
    uv: 99.19137671,
  },
  {
    id: 982,
    uv: 99.20237397,
  },
  {
    id: 983,
    uv: 99.21323952,
  },
  {
    id: 984,
    uv: 99.22397464,
  },
  {
    id: 985,
    uv: 99.23458066,
  },
  {
    id: 986,
    uv: 99.24505886,
  },
  {
    id: 987,
    uv: 99.25541051,
  },
  {
    id: 988,
    uv: 99.2656369,
  },
  {
    id: 989,
    uv: 99.27573929,
  },
  {
    id: 990,
    uv: 99.28571893,
  },
  {
    id: 991,
    uv: 99.29557706,
  },
  {
    id: 992,
    uv: 99.30531492,
  },
  {
    id: 993,
    uv: 99.31493374,
  },
  {
    id: 994,
    uv: 99.32443474,
  },
  {
    id: 995,
    uv: 99.33381912,
  },
  {
    id: 996,
    uv: 99.34308809,
  },
  {
    id: 997,
    uv: 99.35224283,
  },
  {
    id: 998,
    uv: 99.36128452,
  },
  {
    id: 999,
    uv: 99.37021435,
  },
  {
    id: 1000,
    uv: 99.37903347,
  },
];
