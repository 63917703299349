import { Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { Organisation } from "../../types/interfaces";
import {
  deleteOrganisation,
  onEditModeChange,
  onUpdateOrganisation,
} from "../../redux/pages/organisations";

const OrganisationsTable = () => {
  const { organisationsList } = useSelector(
    (state: any) => state.organisations
  );

  const dispatch = useDispatch();

  const onChangeOrganisation = (id: Organisation["id"]) => {
    dispatch(onUpdateOrganisation(id));
    dispatch(onEditModeChange(true));
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Town</th>
          <th>County</th>
          <th>Post Code</th>
          <th>Country</th>
          <th>Address 1</th>
          <th>Address 2</th>
          <th>Address 3</th>
          <th>Switchboard</th>
          <th>Web Address</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {organisationsList.map((o: Organisation) => (
          <tr key={o.id}>
            <td>{o.name}</td>
            <td>{o.addressTown}</td>
            <td>{o.addressCounty}</td>
            <td>{o.addressPostCode}</td>
            <td>{o.addressCountryName}</td>
            <td>{o.address1}</td>
            <td>{o.address2}</td>
            <td>{o.address3}</td>
            <td>{o.addressSwitchboard}</td>
            <td>{o.addressWebAddress}</td>
            <td>{o.addressEmail}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onChangeOrganisation(o.id)}
              >
                Edit
              </span>{" "}
              {" / "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(deleteOrganisation(o.id))}
              >
                Delete
              </span>
            </td>
          </tr>
        ))}{" "}
      </tbody>
    </Table>
  );
};

export default OrganisationsTable;
