import { useState } from "react";
import { Button, ButtonToolbar } from "reactstrap";

import Consequence from "./Consequence";

const AssumptionConcequences = () => {
  const [consequences, setConsequences] = useState(1);

  const addConcequence = () => {
    setConsequences(consequences + 1);
  };

  return (
    <>
      {[...Array(consequences)].map((value: undefined, index: number) => (
        <Consequence key={index} consequenceNumber={index + 1} />
      ))}
      <ButtonToolbar>
        <Button color="primary" outline={true} onClick={addConcequence}>
          + ADD NEW CONSEQUENCE
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default AssumptionConcequences;
