import React from "react";
import { Route, Switch } from "react-router-dom";

import Landing from "../../../Landing";
import AcceptInvite from "../../../Landing/components/AcceptInvite";
import AboutUs from "../../../Landing/pages/AboutUs";
import Contacts from "../../../Landing/pages/Contacts";


const NotLoggedIn = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route
          path="/accept-invitation/:userInvitationId"
          component={AcceptInvite}
        />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contacts" component={Contacts} />
      </Switch>
    </div>
  );
};

export default NotLoggedIn;
