import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import { RootState } from "../../redux/store";

const Layout = () => {
  const [isSidebarShown, setIsSidebarShown] = useState(false);

  const sidebarCollapse = useSelector(
    (state: RootState) => state.layout.sidebarCollapse
  );

  // const changeSidebarVisibility = () => {
  //   setIsSidebarCollapsed(!isSidebarCollapsed);
  // };

  const changeMobileSidebarVisibility = () => {
    setIsSidebarShown(!isSidebarShown);
  };

  const LayoutClasses = classNames({
    layout: true,
    "layout--collapse": sidebarCollapse,
  });

  return (
    <div className={LayoutClasses}>
      <Topbar
        // changeSidebarVisibility={changeSidebarVisibility}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
      <Sidebar
        sidebarShown={isSidebarShown}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
};

export default Layout;
