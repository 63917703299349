import { Children, useState } from "react";
import { Form } from "react-final-form";
import { useSelector, useDispatch } from "react-redux";

import ModalContainer from "../../shared/components/ModalContainer";
import { onNewAssumptionToggle } from "../../redux/formModals";
import { RootState } from "../../redux/store";
import AssumptionNavigation from "./components/AssumptionNavigation";
import RiskDetails from "./components/AssumptionDetails";
import AssumptionConsequences from "./components/AssumptionConsequences";
import AssumptionMitigations from "./components/AssumptionMitigations";
import { Button, ButtonToolbar, Container } from "reactstrap";
import { onNextPage } from "../../redux/pages/assumptions";

interface AssumptionFormProps {
  initialValues: any;
  children: any;
  onSubmit: any;
}

const AssumptionForm = ({
  initialValues,
  children,
  onSubmit,
}: AssumptionFormProps) => {
  const dispatch = useDispatch();

  const page = useSelector((state: RootState) => state.assumptions.activePage);

  const handleSubmit = (values: any) => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      dispatch(onNextPage(children));
    }
  };

  const activePage = Children.toArray(children)[page];
  const isLastPage = page === Children.count(children) - 1;

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="form">
          {activePage}
          <ButtonToolbar style={{ marginLeft: "auto" }}>
            {!isLastPage && <Button color="primary">NEXT STEP</Button>}
            {isLastPage && (
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                disabled={submitting}
              >
                SUBMIT
              </Button>
            )}
          </ButtonToolbar>
        </form>
      )}
    />
  );
};

export default AssumptionForm;

AssumptionForm.Page = function Page({ children }: any) {
  return children;
};
