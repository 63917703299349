import React from "react";
import { Button } from "reactstrap";
import { Paperclip } from "react-bootstrap-icons";
import { FieldInputProps } from "react-final-form";

type FileInputFieldProps = {
  onChange: Function;
  name: string;
  value?: { name: string };
};

export const FileInputField = ({
  onChange,
  name,
  value,
}: FileInputFieldProps) => {
  const handleChange = (event: any) => {
    event.preventDefault();
    const files = [...event.target.files];
    onChange({ file: files[0], name: files[0].name });
  };

  return (
    <div className="form__form-group-file">
      <input type="file" name={name} id={name} onChange={handleChange} />
      {
        <span className="form__form-file-field">
          <Paperclip size={20} style={{ marginRight: "5px" }} />
          {value ? value.name : "No file chosen"}
        </span>
      }
      <Button type="button" color="primary" outline>
        <label htmlFor={name}>CHOOSE FILE</label>
      </Button>
    </div>
  );
};

FileInputField.defaultProps = {
  value: null,
};

type RenderFileInputFieldProps = {
  input: FieldInputProps<any>;
  meta?: {
    touched?: boolean | undefined;
    error?: string;
  };
};

const RenderFileInputField = ({ input, meta }: RenderFileInputFieldProps) => (
  <div className="form__form-group-input-wrap">
    <FileInputField {...input} />
    {meta?.touched && meta?.error && (
      <span className="form__form-group-error">{meta?.error}</span>
    )}
  </div>
);

RenderFileInputField.defaultProps = {
  meta: null,
};

export default RenderFileInputField;
