import React from "react";
import { FieldMetaState } from "react-final-form";
import Select from "react-select";
import { SelectOption } from "../../../types/types";
// import PropTypes from 'prop-types';

type SelectFieldProps = {
  value: SelectOption;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  options: SelectOption[];
  onChange: Function;
};

export const SelectField = ({
  onChange,
  value,
  name,
  disabled,
  placeholder,
  options,
}: SelectFieldProps) => {
  const handleChange = (selectedOption: any) => {
    onChange(selectedOption);
  };

  return (
    <Select
      name={name}
      value={value}
      onChange={handleChange}
      isDisabled={disabled}
      options={options}
      clearable={false}
      className="react-select"
      placeholder={placeholder}
      classNamePrefix="react-select"
    />
  );
};

type RenderSelectFieldProps = {
  input: {
    onChange: Function;
    value: SelectOption;
    name: string;
  };
  meta?: FieldMetaState<SelectOption>;
  options: SelectOption[] | undefined;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

const RenderSelectField = ({
  input,
  meta,
  options,
  disabled,
  placeholder,
  className,
}: RenderSelectFieldProps) => (
  <div className={`form__form-group-input-wrap ${className}`}>
    <SelectField {...input} options={options ? options : []} placeholder={placeholder} disabled={disabled} />
    {meta?.touched && meta?.error && (
      <span className="form__form-group-error">{meta?.error}</span>
    )}
  </div>
);

export default RenderSelectField;
