import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Gear } from "react-bootstrap-icons";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import TopRiskVariancesTable from "../components/TopRiskVariancesTable";
import SwitchButton from "../../../../../shared/components/form/SwitchButton";

const TopRiskVariances = () => {
  const [isSchedule, setIsSchedule] = useState<boolean>(false);

  const isScheduleToggle = () => {
    setIsSchedule(!isSchedule);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="tab__header">
            <div className="tab__header--left">
              <h4 className="tab__header--title">Top Risk Variances</h4>
              <div className="switch__wrap" style={{ marginRight: "20px" }}>
                <p>Schedule Risk</p>
                <SwitchButton
                  name="top-risk__scheduleRisk"
                  small
                  id="top-risk__scheduleRisk"
                  onChange={isScheduleToggle}
                  checked={isSchedule}
                />
              </div>
            </div>
            <p className="tab__header--view">
              Detailed view <SettingsIcon className="tab__header--view-icon" />
            </p>
          </div>
        </Col>
      </Row>
      <TopRiskVariancesTable isSchedule={isSchedule} />
    </Container>
  );
};

export default TopRiskVariances;
