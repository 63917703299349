import { configureStore } from "@reduxjs/toolkit";

import OrganisationsReducer from "./pages/organisations";
import UserManagementReducer from "./pages/userManagement";
import ProjectReducer from "./pages/projects";
import DashboardReducer from "./pages/dashboard";
import AssumptionsReducer from "./pages/assumptions";
import LayoutReducer from "./layout";
import ModalsStateReducer from "./formModals";
import OtherReducer from "./other";
import RisksReducer from "./risks";
import WorkPackagesReducer from "./workPackages";

const store = configureStore({
  reducer: {
    assumptions: AssumptionsReducer,
    dashboard: DashboardReducer,
    layout: LayoutReducer,
    modals: ModalsStateReducer,
    organisations: OrganisationsReducer,
    other: OtherReducer,
    projects: ProjectReducer,
    risks: RisksReducer,
    userManagement: UserManagementReducer,
    workPackages: WorkPackagesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
