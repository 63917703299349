import React, { useState } from "react";
import {
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import ChatBubbleOutlineOutlined from "@material-ui/icons/ChatBubbleOutlineOutlined";
import RemoveRedEyeOutlined from "@material-ui/icons/RemoveRedEyeOutlined";
import { ThreeDotsVertical, HandThumbsUp } from "react-bootstrap-icons";

const PostMenu = () => {
  return (
    <UncontrolledDropdown
      direction="left"
      className="post__menu"
      style={{ float: "right" }}
    >
      <DropdownToggle>
        <ThreeDotsVertical size="20" style={{ float: "right" }} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>View conversation</DropdownItem>
        <DropdownItem>Leave a comment</DropdownItem>
        <DropdownItem>Save conversation</DropdownItem>
        <DropdownItem>Hide post</DropdownItem>
        <DropdownItem>Report</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

type ForumPostProps = {
  tagList: Array<string>;
  postTitle: string;
  postText: string;
  imageSrc: string;
  postOwnerName: string;
  anonymous?: boolean;
  time: string;
};

const ForumPost = ({
  tagList,
  postTitle,
  postText,
  imageSrc,
  postOwnerName,
  anonymous,
  time,
}: ForumPostProps) => {
  const [liked, setLiked] = useState(false);

  const likeToggle = () => {
    setLiked(!liked);
  };

  return (
    <Card className="forum-post">
      <Row>
        <Col md={6}>
          <div className="forum__profile">
            <div className="forum__profile--avatar">
              <img
                src={
                  anonymous
                    ? "https://www.asiatripdeals.com/wp-content/uploads/2019/03/Anonymous-Avatar.png"
                    : imageSrc
                }
                alt="avatar"
              />
            </div>
            <div className="forum__profile--info">
              <p>{anonymous ? "Anonymous" : postOwnerName}</p>
              <span>{time} min ago</span>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <PostMenu />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4 className="forum-post__title">{postTitle}</h4>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="forum-post__text">{postText}</p>
        </Col>
      </Row>
      <div className="forum-post__footer">
        <div className="forum-post__tags">
          {tagList.map((tag) => {
            return (
              <div className="forum-post__tag">
                <span>{tag}</span>
              </div>
            );
          })}
        </div>
        <div className="forum-post__statistics">
          <div className="forum-post__statistics-item">
            <RemoveRedEyeOutlined fontSize="inherit" />
            <span>{Math.floor(Math.random() * 199 + 1)}</span>
          </div>
          <div className="forum-post__statistics-item">
            <ChatBubbleOutlineOutlined fontSize="inherit" />
            <span>{Math.floor(Math.random() * 29 + 1)}</span>
          </div>
          <div className="forum-post__statistics-item">
            <ArrowUpwardOutlinedIcon fontSize="inherit" />
            <span>{Math.floor(Math.random() * 199 + 1)}</span>
          </div>
          <HandThumbsUp
            size={24}
            className={`post__thumbup ${liked && "active"}`}
            onClick={likeToggle}
          />
        </div>
      </div>
    </Card>
  );
};

export default ForumPost;
