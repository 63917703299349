import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { Project } from "../../types/interfaces";

interface ProjectsState {
  projectsList: Project[];
  organisation: Project | null | undefined;
  editMode: boolean;
}

const initialState: ProjectsState = {
  projectsList: [],
  organisation: null,
  editMode: false,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    onEditModeChange(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
  },
});

export const {
    onEditModeChange
} = projectsSlice.actions

export default projectsSlice.reducer;