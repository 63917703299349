import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import WorkSmarter from "./components/WorkSmarter";
import Access from "./components/Access";
import KeyFeatures from "./components/KeyFeatures";
import DashboardPage from "./components/DashboardPage";
import Footer from "./components/Footer";

function Landing() {
  return (
    <div className="landing-page">
      <Header />
      <Hero />
      <WorkSmarter />
      <Access
        color="dark"
        text="A powerful new breed of risk management software"
      />
      <KeyFeatures />
      <DashboardPage />
      <Access
        color="light"
        text="Riskoncise, The Risk Decision-Making Software"
      />
      <Footer />
    </div>
  );
}

export default Landing;
