import TopbarProfile from "./TopbarProfile";
import TopbarNotifications from "./TopbarNotifications";
import TopbarMail from "./TopbarMail";
import TopbarSideButton from "./TopbarSideButton";
import TopbarSearch from "./TopbarSearch";

import logo from '../../../shared/img/logo_white_background.png'

type TopbarProps = {
  changeMobileSidebarVisibility: () => void;
};

const Topbar = ({ changeMobileSidebarVisibility }: TopbarProps) => (
  <div className="topbar">
    <div className="topbar__left">
      <TopbarSideButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
      <a href="/" className="topbar__logo" />
    </div>
    <div className="topbar__right">
      <div className="topbar__right-search">
        <TopbarSearch />
      </div>
      <div className="topbar__right-over">
        <TopbarMail />
        <TopbarNotifications />
        <TopbarProfile />
      </div>
    </div>
  </div>
);

export default Topbar;
