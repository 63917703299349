import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import { getCountries } from "../../redux/other";
import { getRisks } from "../../redux/risks";
import ProjectCard from "./components/ProjectCard";
import Risk from "./components/risks";
import DashboardTabs from "./components/dashboardTabs";
import CarouselMultiple from "../../shared/components/carousel/CarouselMultiple";
import { getProjects } from "../../redux/pages/dashboard";
import { getWorkPackages } from "../../redux/workPackages";
import { RootState } from "../../redux/store";

const projectsData = [
  {
    title: "Project 2",
    index: "2",
    price: "£1,829.4",
    date: "217d 12h",
    tableData1: [
      {
        identifier: "T-0021",
        title: "Site Interfaces",
        cost: "250.11",
        schedule: "66d 14h",
        id: "1322",
      },
      {
        identifier: "T-0023",
        title: "Peer Review Outputs",
        cost: "216.55",
        schedule: "63d 12h",
        id: "247864",
      },
      {
        identifier: "T-0032",
        title: "Concept of Operations",
        cost: "200.12",
        schedule: "60d 10h",
        id: "3sdfg4w",
      },
      {
        identifier: "T-0028",
        title: "Increased Prelim Requirements",
        cost: "156.34",
        schedule: "57d 8h",
        id: "4sdfgh34",
      },
      {
        identifier: "T-0034",
        title: "M&E Design Departures",
        cost: "148.73",
        schedule: "57d 8h",
        id: "545g",
      },
    ],
    tableData2: [
      {
        identifier: "T-0021",
        title: "Blast Protection",
        cost: "250.11",
        schedule: "66d 14h",
        id: "sdj4w1",
      },
      {
        identifier: "T-0023",
        title: "Utilities Scope Growth",
        cost: "216.55",
        schedule: "63d 12h",
        id: "2324gwerg",
      },
      {
        identifier: "T-0032",
        title: "Below Ground Obstructions",
        cost: "200.12",
        schedule: "60d 10h",
        id: "3324sdfg",
      },
      {
        identifier: "T-0028",
        title: "Stakeholder Objections",
        cost: "156.34",
        schedule: "57d 8h",
        id: "4dfjgxcvb",
      },
      {
        identifier: "T-0034",
        title: "3rd Party Interfaces Inefficient",
        cost: "148.73",
        schedule: "57d 8h",
        id: "5jdfgyje",
      },
    ],
  },
  {
    title: "Project 3",
    price: "£2,731.5",
    date: "163d 11h",
    index: "3",
    tableData1: [
      {
        identifier: "T-0011",
        title: "Incorrect Assumptions in Design",
        cost: "156.34",
        schedule: "57d 8h",
        id: "1sczxawc",
      },
      {
        identifier: "T-0023",
        title: "Cladding Panel Misalignment",
        cost: "248.11",
        schedule: "56d 8h",
        id: "2rthrthnet",
      },
      {
        identifier: "T-0017",
        title: "Steel Price Increases",
        cost: "242.12",
        schedule: "52d 6h",
        id: "3234234tv",
      },
      {
        identifier: "T-0020",
        title: "Specialist Resource Cost Increases",
        cost: "153.34",
        schedule: "52d 3h",
        id: "4erggerbegb3c",
      },
      {
        identifier: "T-0031",
        title: "Station Redesign Costs",
        cost: "146.73",
        schedule: "51d 4h",
        id: "52c34523",
      },
    ],
    tableData2: [
      {
        identifier: "T-0011",
        title: "Interim Transport: Operating Costs",
        cost: "156.34",
        schedule: "54d 6h",
        id: "1awv35t",
      },
      {
        identifier: "T-0023",
        title: "Long Lead Items",
        cost: "248.11",
        schedule: "50d 1h",
        id: "267n",
      },
      {
        identifier: "T-0017",
        title: "Snagging Items",
        cost: "242.12",
        schedule: "52d 3h",
        id: "3nw346e5y",
      },
      {
        identifier: "T-0020",
        title: "Specialist Resource Availability",
        cost: "153.34",
        schedule: "57d 8h",
        id: "4senywe4",
      },
      {
        identifier: "T-0031",
        title: "Design Approvals",
        cost: "146.73",
        schedule: "48d 11h",
        id: "5nr67nue",
      },
    ],
  },
  {
    title: "Project 4",
    price: "£2,282.1",
    date: "193d 06h",
    index: "4",
    tableData1: [
      {
        identifier: "T-0011",
        title: "Out of Hours Working",
        cost: "183.34",
        schedule: "47d 8h",
        id: "1n676n34",
      },
      {
        identifier: "T-0023",
        title: "3rd Party Works Delivery",
        cost: "231.11",
        schedule: "50d 8h",
        id: "2rsebtgs",
      },
      {
        identifier: "T-0017",
        title: "Additional Survey Efficiencies",
        cost: "142.12",
        schedule: "42d 6h",
        id: "3qb3673redfg",
      },
      {
        identifier: "T-0020",
        title: "Temp Works Scope Reduction",
        cost: "163.34",
        schedule: "47d 3h",
        id: "4b7i34yw",
      },
      {
        identifier: "T-0031",
        title: "Shared Office Accommodation",
        cost: "155.73",
        schedule: "50d 4h",
        id: "5bw3454rt",
      },
    ],
    tableData2: [
      {
        identifier: "T-0011",
        title: "Concurrent Working",
        cost: "156.34",
        schedule: "44d 6h",
        id: "1nftyjbr",
      },
      {
        identifier: "T-0023",
        title: "Value Management Outputs",
        cost: "248.11",
        schedule: "61d 1h",
        id: "2va4te",
      },
      {
        identifier: "T-0017",
        title: "Increased Supplier Availability",
        cost: "242.12",
        schedule: "88d 3h",
        id: "3vaw345arg",
      },
      {
        identifier: "T-0020",
        title: "Off-Site Component Construction",
        cost: "153.34",
        schedule: "22d 8h",
        id: "4nxdfghd",
      },
      {
        identifier: "T-0031",
        title: "Muck-Away by River Boat",
        cost: "146.73",
        schedule: "38d 11h",
        id: "5senwvse",
      },
    ],
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRisks());
    dispatch(getProjects());
    dispatch(getCountries());
    dispatch(getWorkPackages());
  }, []);

  const { projects, project } = useSelector(
    (state: RootState) => state.dashboard
  );

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="page-title">Welcome Anne!</h1>
        </Col>
      </Row>
      <Row>
        <CarouselMultiple>
          {project ? (
            <ProjectCard
              projects={projects}
              project={project}
              title="Project 1"
              index="1"
              repr={false}
            />
          ) : (
            <p>Loading...</p>
          )}
          {project ? (
            projectsData.map((projectData) => {
              return (
                <ProjectCard
                  projects={projects}
                  project={project}
                  title={projectData.title}
                  index={projectData.index}
                  repr={true}
                  price={projectData.price}
                  date={projectData.date}
                  tableData1={projectData.tableData1}
                  tableData2={projectData.tableData2}
                />
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </CarouselMultiple>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Col md={12}>
          {projects.length !== 0 ? <DashboardTabs /> : <p>Loading</p>}
          <Risk />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
