import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonToolbar } from "reactstrap";
import { RootState } from "../../../redux/store";
import { WizzardPageType } from "../../../types/types";
import Mitigation from "./Mitigation";

type RiskMitigationsProps = {
  initialMitigations?: Array<any | undefined>;
} & WizzardPageType;

const RiskMitigations = ({
  mutators,
  initialMitigations,
}: RiskMitigationsProps) => {
  const [mitigations, setMitigations] = useState(1);

  const addMitigation = () => {
    setMitigations(mitigations + 1);
  };

  const riskFormState = useSelector(
    (state: RootState) => state.risks.riskFormState
  );

  return (
    <>
      {riskFormState === "edit" && initialMitigations?.length !== 0
        ? initialMitigations?.map((value: undefined, index: number) => (
            <Mitigation
              key={index}
              mitigationNumber={index + 1}
              mutators={mutators}
            />
          ))
        : [...Array(mitigations)].map((value: undefined, index: number) => (
            <Mitigation
              key={index}
              mitigationNumber={index + 1}
              mutators={mutators}
            />
          ))}

      <ButtonToolbar>
        <Button color="primary" outline={true} onClick={addMitigation}>
          + ADD RESPONSE ACTION
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default RiskMitigations;
