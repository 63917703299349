import classNames from "classnames";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import SidebarContent from "./SidebarContent";

type SidebarProps = {
  sidebarShown: boolean;
  changeMobileSidebarVisibility: () => void;
};

const Sidebar = ({
  sidebarShown,
  changeMobileSidebarVisibility,
}: SidebarProps) => {
  const sidebarCollapse = useSelector(
    (state: RootState) => state.layout.sidebarCollapse
  );

  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebarShown,
    "sidebar--collapse": sidebarCollapse,
  });

  return (
    <div className={sidebarClass}>
      <button
        className="sidebar__back"
        type="button"
        aria-label="change mobile sidebar visibility button"
        onClick={changeMobileSidebarVisibility}
      />
      {/* <Scrollbar className="sidebar__scroll scroll"> */}
      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <SidebarContent onClick={() => {}} />
      </div>

      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent onClick={changeMobileSidebarVisibility} />
      </div>
      {/* </Scrollbar> */}
    </div>
  );
};
export default Sidebar;
