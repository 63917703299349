import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Table, UncontrolledTooltip } from "reactstrap";
import { RootState } from "../../../redux/store";
import { priceMask, splitTime } from "../../../shared/utilities";
import { IRisk } from "../../../types/interfaces";

interface TableRowProps {
  identifier?: string;
  title?: string;
  data?: string;
  id?: string;
}

const TableRow = ({ identifier, title, data, id }: TableRowProps) => {
  const opportunities = useSelector(
    (state: RootState) => state.dashboard.isOpportunities
  );
  const getRandom = () => {
    const number = Math.floor(Math.random() * 3);
    switch (number) {
      case 0:
        return (
          <div
            className="triangle-up"
            style={{ borderBottomColor: opportunities ? "#009688" : "#c9452b" }}
          />
        );
      case 1:
        return (
          <div
            className="triangle-down"
            style={{ borderTopColor: opportunities ? "#c9452b" : "#009688" }}
          />
        );
      default:
        return <div className="triangle-right" />;
    }
  };

  return (
    <tr>
      <td>{identifier}</td>
      <td id={`tooltip${id}`}>{title}</td>
      <UncontrolledTooltip placement="top" target={`tooltip${id}`}>
        {`${title}`}
      </UncontrolledTooltip>
      <td>{data}</td>
      <td>{getRandom()}</td>
    </tr>
  );
};

interface RiskProps {
  repr: boolean;
  schedule?: boolean;
  reprData: Array<any>;
}

function Risk({ schedule, repr, reprData }: RiskProps) {
  const { risksList, opportunitiesList, threatsList } = useSelector(
    (state: RootState) => state.risks
  );

  const opportunities = useSelector(
    (state: RootState) => state.dashboard.isOpportunities
  );

  // useEffect(() => {
  //   const tempList = risksList?.filter((risksList) =>
  //     opportunities ? risk.type === "Opportunity" : risk.type === "Threat"
  //   );
  //   tempList && setArrayToFilter(tempList);
  // }, [opportunities]);

  const arrayToSort = opportunities ? [...opportunitiesList] : [...threatsList];

  const filteredArray = arrayToSort?.sort((a, b) =>
    schedule
      ? Number(b.scheduleSuggestedLikely) - Number(a.scheduleSuggestedLikely)
      : Number(b.costSuggestedLikely) - Number(a.costSuggestedLikely)
  );

  return (
    <>
      <div className="risk-table--open">{`${Math.floor(
        Math.random() * 60 + 20
      )} open risks`}</div>
      <div className="risk-table">
        <Table>
          <thead>
            <tr>
              <th>Risk ID</th>
              <th>Title</th>
              <th>{schedule ? "Schedule" : "Cost (£k)"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {repr === true
              ? reprData.map((r) => {
                  return (
                    <TableRow
                      key={1}
                      identifier={r.identifier}
                      data={schedule ? r.schedule : r.cost}
                      title={r.title}
                      id={r.id}
                    />
                  );
                })
              : filteredArray
                  ?.slice(0, 5)
                  .map((risk) => (
                    <TableRow
                      key={risk.id}
                      identifier={risk.identifier}
                      data={
                        schedule
                          ? `${
                              splitTime(Number(risk.scheduleSuggestedLikely)).d
                            }d ${
                              splitTime(Number(risk.scheduleSuggestedLikely)).h
                            }h`
                          : priceMask(Number(risk.costSuggestedLikely))
                      }
                      title={risk.title}
                      id={risk.id}
                    />
                  ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Risk;
