import { useDispatch, useSelector } from "react-redux";
import { onMovePage } from "../../../redux/pages/assumptions";
import { RootState } from "../../../redux/store";

const AssumptionNavigation = () => {
  const dispatch = useDispatch();
  const activePage = useSelector(
    (state: RootState) => state.assumptions.activePage
  );

  return (
    <div className="form__navigation">
      <div
        className={`form__navigation-item ${activePage === 0 && "active"}`}
        onClick={() => dispatch(onMovePage(0))}
      >
        <span className="page-number">1</span>
        <div className="line" />
        <span className="page-title">Assumption details</span>
      </div>
      <div
        className={`form__navigation-item ${activePage === 1 && "active"}`}
        onClick={() => dispatch(onMovePage(1))}
      >
        <span className="page-number">2</span>
        <div className="line" />
        <span className="page-title">Consequences</span>
      </div>
      <div
        className={`form__navigation-item ${activePage === 2 && "active"}`}
        onClick={() => dispatch(onMovePage(2))}
      >
        <span className="page-number">3</span>
        <div className="line" />
        <span className="page-title">Actions</span>
      </div>
    </div>
  );
};

export default AssumptionNavigation;
