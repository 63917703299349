import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonToolbar } from "reactstrap";

import { RootState } from "../../../redux/store";
import { WizzardPageType } from "../../../types/types";
import Consequence from "./Consequence";

type RiskConsequencesProps = {
  initialConsequences?: Array<any | undefined>;
} & WizzardPageType;

const RiskConsequences = ({
  initialConsequences,
  mutators,
  values,
}: RiskConsequencesProps) => {
  const [consequences, setConsequences] = useState(1);

  const addConcequence = () => {
    setConsequences(consequences + 1);
  };

  const riskFormState = useSelector(
    (state: RootState) => state.risks.riskFormState
  );

  return (
    <>
      {riskFormState === "edit" && initialConsequences?.length !== 0
        ? initialConsequences?.map((value: undefined, index: number) => (
            <Consequence
              key={index}
              consequenceNumber={index + 1}
              values={values}
              mutators={mutators}
            />
          ))
        : [...Array(consequences)].map((value: undefined, index: number) => (
            <Consequence
              key={index}
              consequenceNumber={index + 1}
              mutators={mutators}
              values={values}
            />
          ))}

      <ButtonToolbar>
        <Button color="primary" outline={true} onClick={addConcequence}>
          + ADD NEW CONSEQUENCE
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default RiskConsequences;
