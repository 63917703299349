import api from "./api";
import { Organisation } from "../types/interfaces";

export const getOrganisationsRequest = () => {
  return api.get("/Organisations");
};

export const getOrganisationRequest = (id: string) => {
  return api.get(`/Organisations/${id}`);
};

export const createOrganisationRequest = (body: Organisation) => {
    return api.post(`/Organisations`, body);
}

export const updateOrganisationRequest = (id: string, body: Organisation) => {
    return api.put(`/Organisations/${id}`, {...body, id});
}

export const deleteOrganisationRequest = (id: string) => {
    return api.delete(`/Organisations/${id}`);
}