import { Field } from "react-final-form";
import { Row, Col } from "reactstrap";
import RenderFileInputField from "../../../shared/components/form/FileInput";

import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RenderSlider from "../../../shared/components/range_slider/Slider";

type ConsequenceProps = {
  consequenceNumber: number;
};

const Concequence = ({ consequenceNumber }: ConsequenceProps) => {
  return (
    <>
      <div className="form-title">{`Consequence ${consequenceNumber}`}</div>

      <Col md={12}>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {`Consequence ${consequenceNumber} title`}
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`consequenceTitle${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {`Consequence ${consequenceNumber} Description`}
            </span>
            <div className="form__form-group-field">
              <Field
                name={`consequenceDescription${consequenceNumber}`}
                component="textarea"
                type="text"
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Likelihood</span>
              <Field
                name={`consequenceLikelihood${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {`Consequence ${consequenceNumber} Schedule Impact`}
              </span>
              <Field
                name={`consequenceScheduleImpact${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1 - 15 days",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16 - 30 days",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31 - 50 days",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51 - 75 days",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> 75 days",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Add supporting evidence
              </span>
              <Field
                name={`consequenceSupportingDocuments${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderFileInputField input={input} />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {`Consequence ${consequenceNumber} Cost Impact`}
              </span>
              <Field
                name={`consequenceCostImpact${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={1000}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      140: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      150: "£0 - £150k",
                      290: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      300: "£150k - £300k",
                      490: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      500: "£300k - £500k",
                      690: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      700: "£500k - £750k",
                      990: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      1000: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> £750k",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Concequence;
