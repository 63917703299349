import { Route } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import Layout from "../../../Layout";
import Organisations from "../../../Organisations";
import UserManagement from "../../../UserManagement";
import SentInvitations from "../../../UserManagement/components/SentInvitations";
import BulkUpload from "../../../BulkUpload";
import CreateOrganisation from "../../../Admin/components/CreateOrganisation";
import Forum from "../../../Forum/index";

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" exact component={Dashboard} />
      <Route path="/forms/organisations" component={Organisations} />
      <Route path="/manage-users" component={UserManagement} />
      <Route path="/bulk-upload" component={BulkUpload} />
      <Route path="/create-organisation" component={CreateOrganisation} />
      <Route path="/sent-invitations" component={SentInvitations} />
      <Route path="/forum" component={Forum} />
    </div>
  </div>
);
