import React from "react";
import logo from "../../../shared/img/logo_big.png";
import Footer from "../components/Footer";
import Header from "../components/Header";

// import logoBig from "../../../shared/img/logo_big";

const AboutUs = () => {
  return (
    <div className="about-us">
      <Header />
      <div className="about-us__content">
        <div className="about-us__left">
          <img src={logo} alt="" />
        </div>
        <div className="about-us__right">
          <div className="decoration__blue-box" />
          <h1>Our Company</h1>
          <p className="about-us__text1">
            <strong>Riskoncise</strong> was established to meet the imminent
            need for a new and innovative risk management software solution
            which addresses the{" "}
            <strong>complexity</strong>, <strong>interdependency</strong> and{" "}
            <strong>subjectivity</strong> of risk in an accessible way.
          </p>
          <p className="about-us__text2">
            Focusing first on Project Risk Management,{" "}
            <strong>Riskoncise</strong> will be extended to tackle risk in the
            following areas:
          </p>
          <ul>
            <li>QHSSE</li>
            <li>Supply Chain Risk Management</li>
            <li>Business Continuity and Crisis Management </li>
            <li>Extended Enterprise Risk Management</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
