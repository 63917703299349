import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalContainer from "../../shared/components/ModalContainer";
import { RootState } from "../../redux/store";
import { onNewRiskToggle } from "../../redux/formModals";
import RiskDetails from "./formPages/RiskDetails";
import RiskFormNavigation from "./formPages/RiskFormNavigation";
import RiskCauses from "./formPages/RiskCauses";
import RiskConsequences from "./formPages/RiskConsequences";
import RiskMitigations from "./formPages/RiskMitigations";
import MonteCarlo from "./formPages/MonteCarlo";
import { createRisk, onRiskChosen, updateRisk } from "../../redux/risks";

import RiskWizzardForm from "./RiskWizzardForm";
import { getProjects } from "../../redux/pages/dashboard";
import { getWorkPackages } from "../../redux/workPackages";
import { IRisk } from "../../types/interfaces";

const RiskForm = () => {
  const [page, setPage] = useState(1);

  const isNewRiskOpen = useSelector(
    (state: RootState) => state.modals.newRiskOpen
  );

  const editRisk = useSelector((state: RootState) => state.risks.risk);
  const riskList = useSelector((state: RootState) => state.risks.risksList);

  const riskFormState = useSelector(
    (state: RootState) => state.risks.riskFormState
  );

  useEffect(() => {
    dispatch(getProjects());
  }, [riskList]);

  const dispatch = useDispatch();

  const onModalClose = () => {
    dispatch(onRiskChosen(null));
    dispatch(onNewRiskToggle());
  };

  // Groups into objects by the last character
  const groupUp = (arrArg: any) => {
    let result = [];
    for (let j = 1; j <= arrArg.length; j++) {
      let temp = [];
      for (let i = 0; i < arrArg.length; i++) {
        if (arrArg[i][0].endsWith(j)) {
          const noNumber = arrArg[i][0].replace(/\d+/g, "");

          temp.push([noNumber, arrArg[i][1]]);
        }
      }
      result.push(Object.fromEntries(temp));
    }
    return result.filter((value) => Object.keys(value).length !== 0);
  };

  const onSubmit = (values: any) => {
    // Modifying data from array of key:value pares into named objects with array of subObjects (causes: [cause1], [cause2])
    const entries = Object.entries(values);
    const riskData = entries.filter((e: any) => {
      if (e[0].startsWith("risk")) return e;
    });
    const cause = entries.filter((e: any) => {
      if (e[0].startsWith("cause")) return e;
    });
    const consequence = entries.filter((e: any) => {
      if (e[0].startsWith("consequence")) return e;
    });
    const mitigation = entries.filter((e: any) => {
      if (e[0].startsWith("mitigation")) return e;
    });
    const modifiedData: any = {
      riskDetails: Object.fromEntries(riskData),
      causes: groupUp(cause),
      consequences: groupUp(consequence),
      mitigations: groupUp(mitigation),
    };
    switch (riskFormState) {
      case "create":
        dispatch(createRisk(modifiedData));
        console.log({ modifiedData });
        break;
      case "edit":
        editRisk && dispatch(updateRisk(editRisk.id, modifiedData));
        console.log(modifiedData);
        console.log(values);
        break;
      default:
        new Error("Could not submit the form!");
        break;
    }
    // dispatch(getProjects());
    dispatch(getWorkPackages());
    onModalClose();
  };

  // Shapes received data into arrays of cause / consequence / actions
  const refactoredCausesArray = editRisk?.causes?.map((cause, index) => {
    return {
      [`causeId${index + 1}`]: cause.id,
      // [`causeRiskTitle${index + 1}`]: cause.riskTitle,
      [`causeTitle${index + 1}`]: cause.title,
      [`causeDescription${index + 1}`]: cause.description,
      [`causeLikelihood${index + 1}`]: cause.likelihood,
    };
  });

  const refactoredConsequencesArray = editRisk?.consequences?.map(
    (consequence, index) => {
      return {
        id: consequence.id,
        // [`consequenceLinkedRisk${index + 1}`]: consequence.linkedRisk,
        [`consequenceTitle${index + 1}`]: consequence.title,
        [`consequenceDescription${index + 1}`]: consequence.description,
        [`consequenceLikelihood${index + 1}`]: consequence.likelihood,
        [`consequenceCostImpact${index + 1}`]: consequence.costImpact,
        [`consequenceScheduleImpact${index + 1}`]: consequence.scheduleImpact,
        // [`consequenceTaskId${index + 1}`]: consequence.,
        [`consequenceProlongationCost${index + 1}`]:
          consequence.prolongationCost,
        [`consequenceResourceDailyCost${index + 1}`]:
          consequence.resourceDailyCost,
        [`consequenceSitePrelimsDailyCost${index + 1}`]:
          consequence.sitePrelimsDailyCost,
      };
    }
  );

  const refactoredMitigationsArray = editRisk?.mitigations?.map(
    (mitigation, index) => {
      return {
        id: mitigation.id,
        [`mitigationTitle${index + 1}`]: mitigation.title,
        // [`mitigationLinkedRisk${index + 1}`]: mitigation.title,
        [`mitigationDescription${index + 1}`]: mitigation.description,
        [`mitigationOwner${index + 1}`]: mitigation.owner,
        [`mitigationStatus${index + 1}`]: mitigation.status,
        // [`mitigationImpact${index + 1}`]: mitigation.impact,
        [`mitigationImpactProbability${index + 1}`]:
          mitigation.impactProbability,
        [`mitigationImpactCost${index + 1}`]: mitigation.impactCost,
        [`mitigationImpactSchedule${index + 1}`]: mitigation.impactSchedule,
        [`mitigationOwnerRole${index + 1}`]: mitigation.ownerRole,
        [`mitigationOwnerOrganisation${index + 1}`]:
          mitigation.ownerOrganisationType,
        [`mitigationTargetCompletionDate${index + 1}`]:
          mitigation.targetCompletionDate,
        [`mitigationActualCompletionDate${index + 1}`]:
          mitigation.actualCompletionDate,
        [`mitigationScoreEffort${index + 1}`]: mitigation.effortLevel,
        // [`mitigationEffectivenessCost${index + 1}`]: mitigation.effectivenessCost,
      };
    }
  );

  // Makes objects from array of causes / consequences / actions
  const causesArray =
    refactoredCausesArray && Object.assign({}, ...refactoredCausesArray);
  const consequencesArray =
    refactoredConsequencesArray &&
    Object.assign({}, ...refactoredConsequencesArray);
  const mitigationsArray =
    refactoredMitigationsArray &&
    Object.assign({}, ...refactoredMitigationsArray);

  // Alters risk score initial state, depends on risk type

  const addScoreExplanation = (score: number, riskType: string | undefined) => {
    let result;
    switch (riskType) {
      case "Threat":
        if (score <= 5) {
          result = `${score} (Very Low)`;
        } else if (score > 5 && score <= 10) {
          result = `${score} (Low)`;
        } else if (score > 10 && score <= 15) {
          result = `${score} (Medium)`;
        } else if (score > 15 && score <= 20) {
          result = `${score} (High)`;
        } else if (score > 20 && score <= 25) {
          result = `${score} (Very High)`;
        }
        return result;

      case "Opportunity":
        if (score <= 5) {
          result = `${score} (Very Low)`;
        } else if (score > 5 && score <= 10) {
          result = `${score} (Low)`;
        } else if (score > 10 && score <= 15) {
          result = `${score} (Medium)`;
        } else if (score > 15 && score <= 20) {
          result = `${score} (High)`;
        } else if (score > 20 && score <= 25) {
          result = `${score} (Very High)`;
        }
        return result;

      default:
        console.log("Something went wrong");
    }
  };

  //// Initial values ////

  const initialValues = riskFormState === "edit" &&
    editRisk && {
      riskTitle: editRisk.title,
      riskOwner: { value: editRisk.owner, label: editRisk.owner },
      riskType: { value: editRisk.type, label: editRisk.type },
      riskOwnerRole: { value: editRisk.ownerRole, label: editRisk.ownerRole },
      riskStatus: { value: editRisk.status, label: editRisk.status },
      riskOwnerOrganisationType: {
        value: editRisk.ownerOrganisationType,
        label: editRisk.ownerOrganisationType,
      },
      riskDescription: editRisk.description,
      riskRiskImpactExposureStartDate:
        editRisk.riskImpactExposureStartDate &&
        new Date(editRisk.riskImpactExposureStartDate),
      riskRiskImpactExposureEndDate:
        editRisk.riskImpactExposureEndDate &&
        new Date(editRisk.riskImpactExposureEndDate),
      riskCostImpactExposureStartDate:
        editRisk.costImpactExposureStartDate &&
        new Date(editRisk.costImpactExposureStartDate),
      riskCostImpactExposureEndDate:
        editRisk.costImpactExposureEndDate &&
        new Date(editRisk.costImpactExposureEndDate),
      riskCategorisationHierarchyLevel: {
        value: editRisk.hierarchyLevel,
        label: editRisk.hierarchyLevel,
      },
      riskCategorisationCategory1: {
        value: editRisk.category1,
        label: editRisk.category1,
      },
      riskCategorisationCategory3: {
        value: editRisk.category3,
        label: editRisk.category3,
      },
      riskCategorisationRiskWorkPackageId: editRisk.workPackageId,
      riskCategorisationCategory2: {
        value: editRisk.category2,
        label: editRisk.category2,
      },
      riskCategorisationCostBreakdownStructure: editRisk.costBreakdownStructure,
      riskCategorisationWorkBreakdownStructure: editRisk.workBreakdownStructure,
      riskCategorisationProbability: editRisk.probability,
      riskCategorisationProposedResponseStrategy:
        editRisk.proposedResponseStrategy,
      riskCostDistributionType: {
        value: editRisk.costDistributionType,
        label: editRisk.costDistributionType,
      },
      riskCostSuggestedMinimum: editRisk.costSuggestedMinimum?.toString(),
      riskCostSuggestedLikely: editRisk.costSuggestedLikely?.toString(),
      riskCostSuggestedMaximum: editRisk.costSuggestedMaximum?.toString(),
      riskCostNotes: editRisk.costNotes,
      riskIsCostAccepted: editRisk.isCostAccepted,
      riskScheduleDistributionType: {
        value: editRisk.scheduleDistributionType,
        label: editRisk.scheduleDistributionType,
      },
      riskScheduleSuggestedMinimum:
        editRisk.scheduleSuggestedMinimum?.toString(),
      riskScheduleSuggestedLikely: editRisk.scheduleSuggestedLikely?.toString(),
      riskScheduleSuggestedMaximum:
        editRisk.scheduleSuggestedMaximum?.toString(),
      riskScheduleNotes: editRisk.scheduleNotes,
      riskIsScheduleAccepted: editRisk.isScheduleAccepted,
      riskIsMonteCarloIncluded: editRisk.isMonteCarloIncluded,
      riskAssignedId: editRisk.identifier,
      riskImpactScore: addScoreExplanation(
        Number(editRisk.score),
        editRisk.type
      ),
      riskAssessmentConfidenceScore: addScoreExplanation(
        Math.floor(Math.random() * 25 + 1),
        editRisk.type
      ),
      riskIdentifier: editRisk.identifier,
      riskCategorisationWorkPackageId: {
        value: editRisk.workPackageId,
        label: `${editRisk.workPackageCode} - ${editRisk.workPackageName}`,
      },
      ...causesArray,
      ...consequencesArray,
      ...mitigationsArray,
    };

  return (
    <ModalContainer
      title={riskFormState === "edit" ? "EDIT RISK" : "ADD RISK"}
      isOpen={isNewRiskOpen}
      size="xl"
      toggle={onModalClose}
      headerChildren={<RiskFormNavigation />}
    >
      <div className="risk-form__container">
        <RiskWizzardForm initialValues={initialValues} onSubmit={onSubmit}>
          <RiskWizzardForm.Page>
            {({ values, mutators }: any) => {
              return <RiskDetails values={values} mutators={mutators} />;
            }}
          </RiskWizzardForm.Page>
          <RiskWizzardForm.Page>
            {({ values, mutators }: any) => {
              return (
                <RiskCauses
                  initialCauses={refactoredCausesArray}
                  values={values}
                  mutators={mutators}
                />
              );
            }}
          </RiskWizzardForm.Page>
          <RiskWizzardForm.Page>
            {({ values, mutators }: any) => {
              return (
                <RiskConsequences
                  initialConsequences={refactoredConsequencesArray}
                  values={values}
                  mutators={mutators}
                />
              );
            }}
          </RiskWizzardForm.Page>
          <RiskWizzardForm.Page>
            {({ values, mutators }: any) => {
              return (
                <RiskMitigations
                  initialMitigations={refactoredMitigationsArray}
                  mutators={mutators}
                />
              );
            }}
          </RiskWizzardForm.Page>
          <RiskWizzardForm.Page>
            {({ values, mutators }: any) => {
              return <MonteCarlo values={values} mutators={mutators} />;
            }}
          </RiskWizzardForm.Page>
        </RiskWizzardForm>
      </div>
    </ModalContainer>
  );
};

export default RiskForm;
