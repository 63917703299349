import React from "react";
import { Container, Row, Col } from "reactstrap";

import FilterToolbar from "./components/FilterToolbar";
import ForumPost from "./components/ForumPost";

const posts = [
  {
    postTitle: "Peer Risk Reviews 2022",
    postText:
      "I want to start preparing for my peer risk review in the Stations portfolio. Is there a schedule available to view and are there any standard templates/resources we can access?",
    tagList: ["Stations Portfolio Risk Team Members", "Peer Reviews"],
    postOwnerName: "Leann Pratt",
    time: "10",
    anonymous: false,
    imageSrc:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
  },
  {
    postTitle: "Risk Team Social Venue Confirmed!!! : )",
    postText:
      "Hi everyone. We have booked a venue the risk team end of year social for Thursday 23rd December. Remember to let me know by the 15th if you want to take part in Secret Santa! : )",
    tagList: ["All Risk Team Members", "Social"],
    postOwnerName: "Gib Hext",
    time: "27",
    anonymous: false,
    imageSrc:
      "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2080&q=80",
  },
  {
    postTitle: "IRM Global Risk Survey 2021",
    postText:
      "Good Afternoon. I've attached a link to a recent IRM Global Risk Survey. There's also a survey you can take part in for next year's edition. Happy reading!",
    tagList: ["IRM", "Industry Best Practice"],
    postOwnerName: "Evaline Wyman",
    time: "32",
    anonymous: false,
    imageSrc:
      "https://images.unsplash.com/photo-1485290334039-a3c69043e517?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2080&q=80",
  },
  {
    postTitle: "Welcome new team members!",
    postText:
      "Afternoon All. Please join me in welcoming our most recent new additions to the risk team. Welcome aboard and please reach out if you have any questions.",
    tagList: ["All Risk Team Members"],
    postOwnerName: "Lesley Bennington",
    time: "41",
    anonymous: false,
    imageSrc:
      "https://images.unsplash.com/photo-1518577915332-c2a19f149a75?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2062&q=80",
  },
  {
    postTitle: "Project team members not attending reviews",
    postText:
      "Hi all. I'm having a problem with some members of the project team not attending risk reviews. Is there anything you have found useful to increase engagement and get them interested in coming to reviews?",
    tagList: ["All Risk Team Members", "Conerns"],
    postOwnerName: "Bob Jewell",
    time: "58",
    anonymous: true,
    imageSrc:
      "https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2080&q=80",
  },
];

const Forum = () => {
  return (
    <Container className="forum">
      <Row>
        <Col md={12}>
          <h1 className="page-title">Forum</h1>
        </Col>
      </Row>
      <FilterToolbar />
      <div className="forum__content">
        {posts.map((post, index) => {
          return <ForumPost key={index} {...post} />;
        })}
      </div>
    </Container>
  );
};

export default Forum;
