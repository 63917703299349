import { ChevronDoubleRight } from "react-bootstrap-icons";
import { Container } from "reactstrap";

import dashboard from "../../../shared/img/landing/dashboard.png";

const DashboardPage = () => {
  return (
    <div className="dashboard-page">
        <Container fluid="sm">
        <h3>Ground-Breaking Home Page</h3>
        <div className="dashboard-page__content">
          <div className="dashboard-page__content-left">
            <img src={dashboard} alt="Dashboard" />
          </div>
          <div className="dashboard-page__content-right">
            <ul>
              <li>View all your projects in one place and effortlessly switch between them</li>
              <li>View your live Cost and Schedule risk exposure</li>
              <li>Instantly switch between Threat and Opportunity</li>
              <li>Instantly include or exclude the effect of Response Actions</li>
              <li>
                See the confidence in the your forecasts and drill down on where
                it can be improved
              </li>
              <li>See how many open risks make up your exposure</li>
              <li>
                See your Top Risks, how they’ve moved in the period and drill
                down on the detail
              </li>              
              <li>
                Flick through live, pre-loaded ‘snapshot’ reports on your Home
                Page with the option to expand and export
              </li>
              <li>
                Select which ‘snapshot’ reports you want to see on your home page
                with the option to build your own
              </li>
              <li>
                Fully interactive and configurable Risk Register with the option
                to edit, see individual risk ‘snapshots’ or expand for use in a
                workshop environment
              </li>
            </ul>
            <a href="/contacts">
              See more <ChevronDoubleRight size={9} />
            </a>
          </div>
        </div>
    </Container>
      </div>
  );
};

export default DashboardPage;
