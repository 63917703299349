import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonToolbar, Button } from "reactstrap";

import { RootState } from "../../../redux/store";
import { WizzardPageType } from "../../../types/types";
import Cause from "./Cause";

type RiskDetailsProps = {
  initialCauses?: Array<any | undefined>;
} & WizzardPageType;

const RiskCauses = ({ initialCauses, values, mutators }: RiskDetailsProps) => {
  const [causes, setCauses] = useState(1);

  const riskFormState = useSelector(
    (state: RootState) => state.risks.riskFormState
  );

  const addCause = () => {
    setCauses(causes + 1);
  };

  return (
    <>      
      {riskFormState === "edit" && initialCauses?.length !== 0
        ? initialCauses?.map((value: undefined, index: number) => (
            <Cause key={index} causeNumber={index + 1} values={values} mutators={mutators} />
          ))
        : [...Array(causes)].map((value: undefined, index: number) => (
            <Cause key={index} causeNumber={index + 1} values={values} mutators={mutators} />
          ))}
      <ButtonToolbar>
        <Button color="primary" outline={true} onClick={addCause}>
          + ADD NEW CAUSE
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default RiskCauses;
