import "rc-slider/assets/index.css";
import Slider, { createSliderWithTooltip, SliderTooltip } from "rc-slider";

const Handle = Slider.Handle

const SliderWithTooltip = createSliderWithTooltip(Slider)

const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

type RenderSliderProps = {
  marks?: any;
  min: number;
  max: number;
  deafultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
};

const RenderSlider = ({
  min,
  max,
  marks,
  value,
  onChange,
}: RenderSliderProps) => {
  return (
    <div className="slider">
      <SliderWithTooltip
        min={min}
        max={max}
        value={value || undefined}
        handle={handle}
        onChange={onChange}
        marks={marks}
      />
    </div>
  );
};

export default RenderSlider;
