import { Search } from "react-bootstrap-icons";
import { FilterValue } from "react-table";

interface SearchInput {
  filter: string;
  setFilter: (ilterValue: FilterValue) => void;
}

export const SearchInput = ({ filter, setFilter }: SearchInput) => {
  return (
    <span className="table__search">
      <input value={filter || ""} onChange={(e) => setFilter(e.target.value)} placeholder="Search in table" />
      <Search className="table__search-icon" />
    </span>
  );
};
