import { Field, Form } from "react-final-form";
import { Button, ButtonToolbar } from "reactstrap";

const RemoveUser = () => {
  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <div className="remove-user">
      <h5>
        Are you sure you want to remove this user from{" "}
        <span className="highlight">Organisation XSZ</span>?
      </h5>

      <div className="remove-user__content">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Please insert a reason
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="removeReason"
                    type="textarea"
                    component="textarea"
                  />
                </div>
              </div>

              <ButtonToolbar>
                  <Button type="button">CANCEL</Button>
                  <Button color="danger">REMOVE</Button>
              </ButtonToolbar>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default RemoveUser;
