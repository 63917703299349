import React from "react";

type ContingencyRiskBoxProps = {
  boxText: string;
  text: string;
  boxType: "primary" | "accent";
};

const ContingencyRiskBox = ({
  boxText,
  text,
  boxType,
}: ContingencyRiskBoxProps) => {
  return (
    <div className="contingency-box__container">
      <div className={`contingency-box__box ${boxType}`}>{boxText}</div>
      <div className={`contingency-box__text ${boxType}`}>{text}</div>
    </div>
  );
};

export default ContingencyRiskBox;
