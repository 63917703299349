import { Switch } from "react-router-dom";

import WrappedRoutes from "./WrappedRoutes";
import RiskForm from "../../Risk/RiskForm";
import NotLoggedIn from "./WrappedRoutes/NotLoggedIn";
import Assumptions from "../../Assumption";
import AddPost from "../../Forum/components/AddPost";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import Config from "../../../Config";

const Router = () => {
  // const userModal = useSelector(
  //   (state: RootState) => state.userManagement.userModal
  // );

  // const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<string | null>(null);

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: Config.B2C.Scopes,
          account: account,
        })
        .then((accessToken) => {
          if (accessToken) {
            window.sessionStorage.setItem(
              "Authorization",
              "Bearer " + accessToken.accessToken
            );
            setApiData(accessToken.accessToken);
          }
        });
    }
  }, [account, instance]);

  return (
    <main>
      <AuthenticatedTemplate>
        <Switch>{!apiData ? <h1>Loading...</h1> : <WrappedRoutes />}</Switch>
        <RiskForm />
        <Assumptions />
        <AddPost />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <NotLoggedIn />
      </UnauthenticatedTemplate>
    </main>
  );
};

export default Router;
