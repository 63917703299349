import { Children, cloneElement, ReactElement } from "react";
import { Form, FormSpy } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar } from "reactstrap";
import { onMonteCarloChange, onNextPage } from "../../redux/risks";
import { RootState } from "../../redux/store";

type RiskWizzardFormProps = {
  initialValues: any;
  children: any;
  onSubmit: any;
};

const RiskWizzardForm = ({
  initialValues,
  children,
  onSubmit,
}: RiskWizzardFormProps) => {
  const dispatch = useDispatch();
  const page = useSelector((state: RootState) => state.risks.activePage);

  const activePage = Children.toArray(children)[page];
  const isLastPage = page === Children.count(children) - 1;

  const handleSubmit = (values: any) => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      dispatch(onNextPage(children));
    }
  };

  const clearSupportDocuments = (
    pageTitle: any,
    state: any,
    obj: { setIn: any; changeValue: any }
  ) => {
    const documentType = state.fields[`${pageTitle}DocumentType`];
    const documentFile = state.fields[`${pageTitle}DocumentFile`];
    const description = state.fields[`${pageTitle}DocumentDescription`];
    const documentOwner = state.fields[`${pageTitle}DocumentOwner`];
    const documentOwnerRole = state.fields[`${pageTitle}DocumentOwnerRole`];
    documentType.change(undefined);
    documentFile.change(undefined);
    description.change(undefined);
    documentOwner.change(undefined);
    documentOwnerRole.change(undefined);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ clearSupportDocuments }}
      render={({ handleSubmit, submitting, form: { mutators }, values }) => (
        <form onSubmit={handleSubmit} className="form">
          <FormSpy
            onChange={(state) =>
              dispatch(
                onMonteCarloChange(state?.values?.riskIsMonteCarloIncluded)
              )
            }
          />
          {cloneElement(activePage as ReactElement<any>, {
            values: values,
            mutators: mutators,
          })}
          <ButtonToolbar style={{ marginLeft: "auto" }}>
            {!isLastPage && <Button color="primary">NEXT STEP</Button>}
            {isLastPage && (
              <Button
                style={{ marginLeft: "auto" }}
                color="primary"
                disabled={submitting}
              >
                SUBMIT
              </Button>
            )}
          </ButtonToolbar>
        </form>
      )}
    />
  );
};

export default RiskWizzardForm;

RiskWizzardForm.Page = function Page({ children, values, mutators }: any) {
  const props = {
    values,
    mutators,
  };
  return children(props);
};
