import { useDispatch, useSelector } from "react-redux";
import { ChevronDown } from "react-bootstrap-icons";
import { Column } from "react-table";
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import MOCK_DATA from "../../../containers/UserManagement/components/MOCK_DATA.json";
import {
  UserModals,
  onUserModalTypeChange,
  onUserModalToggle,
} from "../../../redux/pages/userManagement";
import { DraggableTable } from "../../../shared/components/table/DraggableTable";
import { UserData } from "../../../types/types";

const UserTableMenu = () => {
  const dispatch = useDispatch();

  const onItemClicked = (modalType: UserModals) => {
    dispatch(onUserModalTypeChange(modalType));
    dispatch(onUserModalToggle());
  };

  return (
    <UncontrolledDropdown direction="left">
      <DropdownToggle>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu left>
        <DropdownItem onClick={() => onItemClicked("info")}>
          See profile
        </DropdownItem>
        <DropdownItem>Assign project</DropdownItem>
        <DropdownItem>Edit</DropdownItem>
        <DropdownItem>Change permissions</DropdownItem>
        <DropdownItem onClick={() => onItemClicked("remove")}>
          Remove from Organisations
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const UserDataTable = () => {
  const dispatch = useDispatch();

  // const onItemClicked = (modalType: UserModals) => {
  //   dispatch(onUserModalTypeChange(modalType));
  //   dispatch(onUserModalToggle());
  // };

  const headers: Column<UserData>[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Date joined",
      accessor: "date_joined",
    },
    {
      Header: "Name",
      accessor: "first_name",
    },
    {
      Header: "Surname",
      accessor: "last_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Programme",
      accessor: "programme",
    },
    {
      Header: "Project",
      accessor: "project",
    },
    {
      Header: "Role",
      accessor: "role",
    },
  ];

  return (
    <>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <DraggableTable
              tableHeaders={headers}
              tableData={MOCK_DATA}
              title="Users"
              menu={<UserTableMenu />}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default UserDataTable;
