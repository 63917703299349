import React from "react";
import { Col, Container, Row } from "reactstrap";

import ContingencyRiskGraph from "../components/ContingencyRiskGraph";
import ContingencyRiskBox from "../components/ContingencyRiskBox";

const ContingencyRisk = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="tab__header">
            <h4 className="tab__header--title">
              Contingency vs. risk exposure + Expenditure
            </h4>
            <p className="tab__header--view">Detailed View</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ContingencyRiskBox
            boxText="Baseline Risk Budget (£k)"
            text="£924,365"
            boxType="accent"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Risk Spend to Date  (£k)"
            text="£342,387"
            boxType="accent"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Remaining 
            Risk Budget"
            text="£581,987"
            boxType="accent"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Risk Spend %
            of Budget"
            text="37%"
            boxType="accent"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Current Risk 
            Forecast (£k)"
            text="£800,098"
            boxType="primary"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Current Forecast Surplus/Deficit (£k)"
            text="-£281,120"
            boxType="primary"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Target Risk Forecast (£k)"
            text="£463,378"
            boxType="accent"
          />
        </Col>
        <Col>
          <ContingencyRiskBox
            boxText="Target Forecast Surplus/Deficit (£k)"
            text="£118,600"
            boxType="accent"
          />
        </Col>
      </Row>
      <Row>
        <div className="tab__graph-container">
          <ContingencyRiskGraph />
        </div>
      </Row>
    </Container>
  );
};

export default ContingencyRisk;
