import { useState } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import { RenderField } from "../../../shared/components/form/Field";
import RenderSelectField from "../../../shared/components/form/Select";
import UncontrolledSwitchButton from "../../../shared/components/form/UncontrolledSwitchButton";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import { WizzardPageType } from "../../../types/types";
import AssessmentSource from "../components/AssessmentSource";

const MonteCarlo = ({ mutators, values }: WizzardPageType) => {
  const [costAccept, setCostAccept] = useState(false);
  const [scheduleAccept, setScheduleAccept] = useState(false);

  const isMonteCarlo = useSelector(
    (state: RootState) => state.risks.isMonteCarlo
  );

  const costAcceptHandler = (onChange: any) => {
    onChange();
    setCostAccept(!costAccept);
  };
  const scheduleAcceptHandler = (onChange: any) => {
    onChange();
    setScheduleAccept(!scheduleAccept);
  };

  return (
    <>
      <Col md={12}>
        {/* <Row>
          <Col md={4}>
            <div className="form-title">INCLUDE MONTE CARLO DATA?</div>
            <div className="form__form-group">
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {isMonteCarlo ? "Yes" : "No"}
                </span>
                <Field
                  name="riskIsMonteCarloIncluded"
                  render={({ input, meta }) => {
                    return <UncontrolledSwitchButton inputProps={input} />;
                  }}
                />
              </div>
            </div>
          </Col>
        </Row> */}
        <Row>
          <div className="form-title">COST DISTRIBUTION</div>

          {/* {isMonteCarlo && ( */}
          <div className="monte-carlo__container">
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Distribution Type
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="riskCostDistributionType"
                      render={({ input, meta }) => (
                        <RenderSelectField
                          input={input}
                          disabled={costAccept}
                          meta={meta}
                          options={[
                            { value: "Triangular", label: "Triangular" },
                            { value: "Pert", label: "Pert" },
                            { value: "Normal", label: "Normal" },
                            { value: "Lognormal", label: "Lognormal" },
                            { value: "Uniform", label: "Uniform" },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <Row>
                  <Col md={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Minimum Cost (£k)
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="riskCostSuggestedMinimum"
                          disabled={costAccept}
                          component={RenderField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Most Likely Cost (£k)
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="riskCostSuggestedLikely"
                          disabled={costAccept}
                          component={RenderField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Maximum Cost (£k)
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="riskCostSuggestedMaximum"
                          disabled={costAccept}
                          component={RenderField}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* )} */}
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Notes</span>
              <div className="form__form-group-field">
                <Field name="riskCostNotes" component="textarea" type="text" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Accept Suggested Values?
              </span>
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {costAccept ? "Yes" : "No"}
                </span>
                <Field
                  name="riskIsCostAccepted"
                  render={({ input, meta }) => (
                    <SwitchButton
                      id={input.name}
                      name={input.name}
                      checked={input.checked}
                      onChange={(e) => costAcceptHandler(input.onChange)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <AssessmentSource
          pageTitle="riskCostAssessment"
          values={values}
          mutators={mutators}
        />
        <Row>
          <div className="form-title">SCHEDULE DISTRIBUTION</div>
          {/* {isMonteCarlo && ( */}
            <div className="monte-carlo__container">
              <Row>
                <Col md={6} xs={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Distribution Type
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="riskScheduleDistributionType"
                        render={({ input, meta }) => (
                          <RenderSelectField
                            input={input}
                            disabled={scheduleAccept}
                            meta={meta}
                            options={[
                              { value: "Triangular", label: "Triangular" },
                              { value: "Pert", label: "Pert" },
                              { value: "Normal", label: "Normal" },
                              { value: "Lognormal", label: "Lognormal" },
                              { value: "Uniform", label: "Uniform" },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <Row>
                    <Col md={4} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Minimum Time (Hours)
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="riskScheduleSuggestedMinimum"
                            disabled={scheduleAccept}
                            component={RenderField}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Most Likely Time (Hours)
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="riskScheduleSuggestedLikely"
                            disabled={scheduleAccept}
                            component={RenderField}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Maximum Time (Hours)
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="riskScheduleSuggestedMaximum"
                            disabled={scheduleAccept}
                            component={RenderField}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          {/* )} */}
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Notes</span>
              <div className="form__form-group-field">
                <Field
                  name="riskScheduleNotes"
                  component="textarea"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Accept Suggested Values?
              </span>
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {scheduleAccept ? "Yes" : "No"}
                </span>
                <Field
                  name="riskScheduleAccepted"
                  render={({ input, meta }) => (
                    <SwitchButton
                      id={input.name}
                      name={input.name}
                      checked={input.checked}
                      onChange={(e) => scheduleAcceptHandler(input.onChange)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <AssessmentSource
          pageTitle="riskSchedule"
          parentNumber={undefined}
          values={values}
          mutators={mutators}
        />
      </Col>
    </>
  );
};

export default MonteCarlo;
