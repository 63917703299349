import { useState } from "react";
import Dropzone from "react-dropzone";
import { FileCopyOutlined } from "@material-ui/icons";

interface UploadProps {
  name?: string;
  onChange: any;
}

const UploadDropzone = ({ name, onChange }: UploadProps) => {
  const [files, setFiles] = useState<(File & { preview: string })[]>(
    [] as (File & { preview: string })[]
  );

  const onDrop = (file: Array<File>) => {
    const f = file.map((fl) =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      })
    );
    onChange(f);
    setFiles(f);
  };

  const removeFile = () => {
    // e.preventDefault();
    // onChange(value.filter((val, i) => i !== index));
    setFiles([]);
  };

  return (
    <div className={`dropzone dropzone--single`}>
      <Dropzone
        multiple={false}
        onDrop={(fileToUpload) => {
          onDrop(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone__input">
            {(!files || files.length === 0) && (
              <>
                <FileCopyOutlined className="dropzone__input-icon" />
                <span>
                  Drag and drop the completed spreadsheet here to continue (xlsx
                  file) or <a>browse</a> your computer
                </span>
                <input {...getInputProps()} />
              </>
            )}
          </div>
        )}
      </Dropzone>
      {files && Array.isArray(files) && files.length > 0 && (
        <aside className="dropzone__preview">
          {/* <img src={files[0].preview} alt="drop-img" />*/}
          <p className="dropzone__preview-name">{files[0].name}</p>
          <button
            className="dropzone__preview-delete btn btn-outline-danger btn-sm"
            type="button"
            onClick={() => removeFile()}
            // onClick={() => console.log("closing")}
          >
            Remove
          </button>
        </aside>
      )}
    </div>
  );
};

export default UploadDropzone;
