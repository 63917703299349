import React from "react";

import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

const ContingencyRiskGraph = () => {
  const data = [
    {
      name: "Jan 31",
      "Remaining Risk Budget": 910,
      "Current Risk Forecast": 610,
      "Risk Spend to Date": 0,
      "Target Risk Forecast": 270,
    },
    {
      name: "Feb 28",
      "Remaining Risk Budget": 850,
      "Current Risk Forecast": 720,
      "Risk Spend to Date": 75,
      "Target Risk Forecast": 260,
    },
    {
      name: "Mar 31",
      "Remaining Risk Budget": 790,
      "Current Risk Forecast": 610,
      "Risk Spend to Date": 140,
      "Target Risk Forecast": 470,
    },
    {
      name: "Apr 30",
      "Remaining Risk Budget": 740,
      "Current Risk Forecast": 810,
      "Risk Spend to Date": 190,
      "Target Risk Forecast": 450,
    },
    {
      name: "May 31",
      "Remaining Risk Budget": 680,
      "Current Risk Forecast": 610,
      "Risk Spend to Date": 240,
      "Target Risk Forecast": 280,
    },
    {
      name: "Jun 30",
      "Remaining Risk Budget": 640,
      "Current Risk Forecast": 850,
      "Risk Spend to Date": 290,
      "Target Risk Forecast": 430,
    },
    {
      name: "Jul 06",
      "Remaining Risk Budget": 600,
      "Current Risk Forecast": 810,
      "Risk Spend to Date": 350,
      "Target Risk Forecast": 400,
    },
  ];

  return (
    <ResponsiveContainer height={400}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend
          verticalAlign="middle"
          align="right"
          layout="vertical"
          wrapperStyle={{ right: 0 }}
        />
        <Line
          type="linear"
          dataKey="Current Risk Forecast"
          legendType="plainline"
          stroke="#0074d0"
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="Remaining Risk Budget"
          legendType="plainline"
          stroke="#c01f28"
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="Risk Spend to Date"
          legendType="plainline"
          stroke="#1e4e74"
          strokeWidth={2}
        />
        <Line
          type="linear"
          dataKey="Target Risk Forecast"
          legendType="plainline"
          stroke="#333333"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ContingencyRiskGraph;
