import { useDispatch, useSelector } from "react-redux";
import { onMovePage } from "../../../redux/risks";
import { RootState } from "../../../redux/store";


const RiskFormNavigation = () => {

  const dispatch = useDispatch()
  const activePage = useSelector((state:RootState) => state.risks.activePage)

  return (
    <div className="form__navigation">
      <div className={`form__navigation-item ${activePage === 0 && "active"}`} onClick={() => dispatch(onMovePage(0))}>
        <span className="page-number">1</span>
        <div className="line" />
        <span className="page-title">Risk Details</span>
      </div>
      <div className={`form__navigation-item ${activePage === 1 && "active"}`} onClick={() => dispatch(onMovePage(1))}>
        <span className="page-number">2</span>
        <div className="line" />
        <span className="page-title">Causes</span>
      </div>
      <div className={`form__navigation-item ${activePage === 2 && "active"}`} onClick={() => dispatch(onMovePage(2))}>
        <span className="page-number">3</span>
        <div className="line" />
        <span className="page-title">Consequences</span>
      </div>
      <div className={`form__navigation-item ${activePage === 3 && "active"}`} onClick={() => dispatch(onMovePage(3))}>
        <span className="page-number">4</span>
        <div className="line" />
        <span className="page-title">Actions</span>
      </div>
      <div className={`form__navigation-item ${activePage === 4 && "active"}`} onClick={() => dispatch(onMovePage(4))}>
        <span className="page-number">5</span>
        <div className="line" />
        <span className="page-title">Monte Carlo</span>
      </div>
    </div>
  );
};

export default RiskFormNavigation;
