import { PieChart, Pie } from "recharts";

interface Data {
  value: number;
  fill: string;
}

interface PiechartProps {
  height: number;
  width: number;
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  chartData: Data[] | undefined;
}

const chart: Data[] = [
  {
    value: 80,
    fill: "#d5dbde",
  },
  {
    value: 20,
    fill: "#0286c3",
  },
];

function Piechart({
  height,
  width,
  cx,
  cy,
  innerRadius,
  outerRadius,
  chartData,
}: PiechartProps) {
  return (
    <>
      <PieChart
        height={height}
        width={width}
        style={{ transform: "rotate(-90deg" }}
      >
        <Pie
          data={chartData}
          dataKey="value"
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
        />
      </PieChart>
    </>
  );
}

export default Piechart;
