import React from "react";
import { Card, CardBody, Col } from "reactstrap";

import UploadInstructions from "./UploadInstructions";
import UploadDropzone from "./UploadDropzone";

const UploadPage = () => {
  const onChange = (t: any) => {
    console.log(t);
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <UploadDropzone onChange={onChange} />
          <UploadInstructions />
        </CardBody>
      </Card>
    </Col>
  );
};

export default UploadPage;
