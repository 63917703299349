import { Link } from "react-router-dom";

type MenuLinkProps = {
  path: string;
  icon?: string;
  title: string;
};

const TopbarMenuLink = ({ path, icon, title }: MenuLinkProps) => (
  <Link className="topbar__link" to={path}>
    {icon ? <span className={`topbar__link-icon bi-${icon}`} /> : ""}
    <p className="topbar__link-title">{title}</p>
  </Link>
);

export default TopbarMenuLink;
