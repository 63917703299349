import api from "./api";

import { IWorkPackage } from "../types/interfaces";

export const getWorkPackagesRequest = () => {
  return api.get("/WorkPackages");
};

export const getWorkPackageRequest = (id: string) => {
  return api.get(`/WorkPackages/${id}`);
};

export const createWorkPackageRequest = (body: IWorkPackage) => {
  return api.post(`/WorkPackages`, body);
};

export const updateWorkPackageRequest = (id: string, body: IWorkPackage) => {
  return api.put(`/WorkPackages/${id}`, { ...body, id });
};

export const deleteWorkPackageRequest = (id: string) => {
  return api.delete(`/WorkPackages/${id}`);
};
