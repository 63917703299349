import React from "react";
import { List } from "react-bootstrap-icons";

const SwitchButtonDefaultProps = {
  optionLabels: ["ON", "OFF"],
};

type SwitchButtonProps = {
  id: string;
  checked: boolean | undefined;
  onChange: (e: any) => void;
  name?: string;
  optionLabels?: string[];
  small?: boolean;
  disabled?: boolean;
} & typeof SwitchButtonDefaultProps;

function SwitchButton({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
}: SwitchButtonProps) {
  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          onChange(e);
        }}
        disabled={disabled}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          tabIndex={disabled ? -1 : 1}
          htmlFor={id}
        >
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            // data-yes={optionLabels?.[0]}
            // data-no={optionLabels?.[1]}
            // tabIndex={-1}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          >
            <List size={10} style={{ transform: "rotate(90deg)" }} />
          </span>
        </label>
      ) : null}
    </div>
  );
}

SwitchButton.defaultProps = SwitchButtonDefaultProps;

export default SwitchButton;
