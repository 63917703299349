import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { ChevronDown } from "react-bootstrap-icons";

import RiskBreakdownBar from "../components/RiskBreakdownBar";
import SwitchButton from "../../../../../shared/components/form/SwitchButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { onScheduleRiskToogle } from "../../../../../redux/pages/dashboard";
import { IProject, IWorkPackage } from "../../../../../types/interfaces";
import {
  fullPriceMask,
  priceMask,
  splitTime,
} from "../../../../../shared/utilities";

interface RiskBreakdownProps {
  project: IProject;
}

const RiskBreakdown = ({ project }: RiskBreakdownProps) => {
  const [data, setData] = useState<number>(0);
  const [mitigatedData, setMitigatedData] = useState<number>(0);
  const [packages, setPackages] = useState<IWorkPackage[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(project.totalCost);
    setMitigatedData(
      Math.round(
        (project.totalCost * (opportunities ? 1.2 : 0.8) + Number.EPSILON) * 100
      ) / 100
    );
    let packagesCopy, tempPackages;
    // Sorts the workPackages in decending order depending on ScheduleRisk and Opportunities
    packagesCopy = [...workPackages];
    tempPackages = packagesCopy?.sort((a, b) =>
      opportunities
        ? b.opportunitiesCost - a.opportunitiesCost
        : b.threatsCost - a.threatsCost
    );
    setPackages(tempPackages);
  }, []);

  const workPackages = useSelector(
    (state: RootState) => state.workPackages.workPackageList
  );
  const mitigation = useSelector(
    (state: RootState) => state.dashboard.mitigation
  );
  const opportunities = useSelector(
    (state: RootState) => state.dashboard.isOpportunities
  );

  const scheduleRisk = useSelector(
    (state: RootState) => state.dashboard.scheduleRisk
  );

  const onScheduleRiskToggle = () => {
    dispatch(onScheduleRiskToogle(!scheduleRisk));
  };

  useEffect(() => {
    let tempData, tempMitigatedData, tempPackages, packagesCopy;

    if (scheduleRisk) {
      // Calculates data and mitigatedData depending on ScheduleRisk and Opportunities
      tempMitigatedData = Math.floor(
        (opportunities
          ? project.opportunitiesSchedule
          : project.threatsSchedule) * (opportunities ? 1.2 : 0.8)
      );
      tempData = Math.floor(
        opportunities ? project.opportunitiesSchedule : project.threatsSchedule
      );

      // Sorts the workPackages in decending order depending on ScheduleRisk and Opportunities
      packagesCopy = [...workPackages];
      tempPackages = packagesCopy?.sort((a, b) =>
        opportunities
          ? b.opportunitiesSchedule - a.opportunitiesSchedule
          : b.threatsSchedule - a.threatsSchedule
      );
    } else {
      // Calculates data and mitigatedData depending on ScheduleRisk and Opportunities
      tempMitigatedData =
        Math.round(
          ((opportunities ? project.opportunitiesCost : project.threatsCost) *
            (opportunities ? 1.2 : 0.8) +
            Number.EPSILON) *
            100
        ) / 100;
      tempData = opportunities
        ? project.opportunitiesCost
        : project.threatsCost;

      // Sorts the workPackages in decending order depending on ScheduleRisk and Opportunities
      packagesCopy = [...workPackages];
      tempPackages = packagesCopy?.sort((a, b) =>
        opportunities
          ? b.opportunitiesCost - a.opportunitiesCost
          : b.threatsCost - a.threatsCost
      );
    }
    console.log(tempPackages);
    setData(tempData);
    setMitigatedData(tempMitigatedData);
    setPackages(tempPackages);
  }, [scheduleRisk, opportunities]);

  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <div className="risk-breakdown__controls">
            <div className="risk-breakdown__select">
              Risk by Work Package{" "}
              <span>
                <ChevronDown />
              </span>
            </div>
            <div className="switch__wrap">
              <p>Schedule Risk</p>
              <SwitchButton
                name="scheduleRisk"
                small
                id="scheduleRisk"
                onChange={onScheduleRiskToggle}
                checked={scheduleRisk}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="risk-breakdown__board">
            <p>CURRENT {scheduleRisk ? "SCHEDULE" : "COST"} RISK EXPOSURE</p>
            {scheduleRisk ? (
              <p
                style={{
                  color: opportunities
                    ? scheduleRisk
                      ? "#095679"
                      : "#009cd6"
                    : scheduleRisk
                    ? "#84151b"
                    : "#c01f28",
                }}
              >
                {mitigation
                  ? `${splitTime(mitigatedData).d}d ${
                      splitTime(mitigatedData).h
                    }h`
                  : `${splitTime(data).d}d ${splitTime(data).h}h`}
              </p>
            ) : (
              <p
                style={{
                  color: opportunities
                    ? scheduleRisk
                      ? "#095679"
                      : "#009cd6"
                    : scheduleRisk
                    ? "#84151b"
                    : "#c01f28",
                }}
              >
                {mitigation
                  ? `£ ${fullPriceMask(mitigatedData)}`
                  : `£ ${fullPriceMask(data)}`}
              </p>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <div className="risk-breakdown__bars">
          {packages.map((workPackage, index) => {
            return (
              <Row key={workPackage.id}>
                <RiskBreakdownBar
                  id={workPackage.id}
                  label={workPackage.name}
                  amount={
                    scheduleRisk
                      ? mitigation
                        ? (opportunities
                            ? workPackage.opportunitiesSchedule
                            : workPackage.threatsSchedule) *
                          (opportunities ? 1.2 : 0.8)
                        : opportunities
                        ? workPackage.opportunitiesSchedule
                        : workPackage.threatsSchedule
                      : mitigation
                      ? Math.round(
                          ((opportunities
                            ? workPackage.opportunitiesCost
                            : workPackage.threatsCost) *
                            (opportunities ? 1.2 : 0.8) +
                            Number.EPSILON) *
                            100
                        ) / 100
                      : opportunities
                      ? workPackage.opportunitiesCost
                      : workPackage.threatsCost
                  }
                  value={
                    scheduleRisk
                      ? mitigation
                        ? Math.floor(
                            ((opportunities
                              ? workPackage.opportunitiesSchedule
                              : workPackage.threatsSchedule) *
                              (opportunities ? 1.2 : 0.8) *
                              100) /
                              (opportunities
                                ? project.opportunitiesSchedule
                                : project.threatsSchedule)
                          )
                        : Math.floor(
                            ((opportunities
                              ? workPackage.opportunitiesSchedule
                              : workPackage.threatsSchedule) *
                              100) /
                              (opportunities
                                ? project.opportunitiesSchedule
                                : project.threatsSchedule)
                          )
                      : mitigation
                      ? Math.floor(
                          ((opportunities
                            ? workPackage.opportunitiesCost
                            : workPackage.threatsCost) *
                            (opportunities ? 1.2 : 0.8) *
                            100) /
                            (opportunities
                              ? project.opportunitiesCost
                              : project.threatsCost)
                        )
                      : Math.floor(
                          ((opportunities
                            ? workPackage.opportunitiesCost
                            : workPackage.threatsCost) *
                            100) /
                            (opportunities
                              ? project.opportunitiesCost
                              : project.threatsCost)
                        )
                  }
                  color={
                    opportunities
                      ? scheduleRisk
                        ? "dblue"
                        : "blue"
                      : scheduleRisk
                      ? "dred"
                      : "red"
                  }
                  schedule={scheduleRisk}
                />
              </Row>
            );
          })}
        </div>
      </Row>
    </Container>
  );
};

export default RiskBreakdown;
