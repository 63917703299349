import React from "react";
import { Button, ButtonToolbar, Card, CardBody } from "reactstrap";

const UserProfile = () => {
  return (
    <Card>
      <CardBody>
        <div className="user-profile">
          <h5>USER DETAILS</h5>
          <ul>
            <li>
              <p>Member science</p> <span>12 November 2017</span>
            </li>
            <li>
              <p>Name</p> <span>Alison</span>
            </li>
            <li>
              <p>Surname</p> <span>Jones</span>
            </li>
            <li>
              <p>Email</p> <span>alison.jons@gmail.com</span>
            </li>
            <li>
              <p>Phone</p> <span>+44 100 000 234</span>
            </li>
            <li>
              <p>Programmes</p> <span>Programme ZX</span>
            </li>
            <li>
              <p>Projects</p>
              <span>
                Project Z, Project XCV, Constructions NN, Constructions GHY,
                Constructions LTN, Constr. L.
              </span>
            </li>
            <li>
              <p>Permissions</p> <span>Full access</span>
            </li>
          </ul>
          <ButtonToolbar>
            <Button color="primary">EDIT</Button>
            <Button color="primary">CHANGE PROGRAMME</Button>
            <Button color="primary">CHANGE PERMISSIONS</Button>
            <Button color="primary">REMOVE</Button>
          </ButtonToolbar>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserProfile;
