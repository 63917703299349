import api from "./api";
import { IApplicationUser, ICreateUser } from "../types/interfaces";

export const getApplicationUsersRequest = () => {
  return api.get("/ApplicationUsers");
};

export const getApplicationUserRequest = (id: string) => {
  return api.get(`/ApplicationUsers/${id}`);
};

export const createApplicationUserRequest = (body: IApplicationUser) => {
  return api.post(`/ApplicationUsers`, body);
};

export const updateApplicationUserRequest = (
  id: string,
  body: IApplicationUser
) => {
  return api.put(`/ApplicationUsers/${id}`, { ...body, id });
};

export const deleteApplicationUserRequest = (id: string) => {
  return api.delete(`/ApplicationUsers/${id}`);
};

export const createUserRequest = (body: ICreateUser) => {
  return api.post(`/ApplicationUsers/CreateUser`, body);
};
