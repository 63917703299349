import { Field } from "react-final-form";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import RenderDatePickerField from "../../../shared/components/form/DatePicker";
import { RenderField } from "../../../shared/components/form/Field";
import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RenderSlider from "../../../shared/components/range_slider/Slider";

const AssumptionDetails = () => {
  return (
    <>
      <Col md={12}>
        <div className="form-title">Risk Details</div>
        <Row>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">
                Assumption Title<span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionTitle"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Workstream</span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionWorkstream"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Assumption Owner <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionOwner"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Status <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionStatus"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Assigned Id</span>
                  <div className="form__form-group-field">
                    <Field
                      name="assumptionAssignedId"
                      component={RenderField}
                      placeholder="T-0256"
                      type="text"
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Project Name<span>*</span>
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="assumptionProjectName"
                      render={({ input, meta }) => (
                        <RenderSelectField
                          input={input}
                          meta={meta}
                          options={[
                            { value: "1", label: "one" },
                            { value: "2", label: "two" },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Assumption Category
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionCategory"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Assumption Owner Role <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionOwnerRole"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Owner Organisation <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionOwnerOrganisation"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Row>
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Risk Description</span>
            <div className="form__form-group-field">
              <Field name="textarea" component="textarea" type="text" />
            </div>
          </div>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Target Assumption Validation Date
              </span>
              <div className="form__form-group-field">
                <Field
                  name="targetAssumptionValidationDate"
                  render={({ input }) => (
                    <RenderDatePickerField input={input} />
                  )}
                />
              </div>
            </div>
          </Col>
          <Row>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Confidence in Assumption
                </span>
                <Field
                  name="riskCategorisationProbability"
                  render={({ input, meta }) => {
                    return (
                      <RenderSlider
                        min={0}
                        max={100}
                        {...input}
                        marks={{
                          14: {
                            label: "Very Low",
                            style: {
                              width: "55px",
                              wrap: "nowrap",
                              top: "-25px",
                              paddingLeft: "10px",
                            },
                          },
                          15: "1% - 15%",
                          29: {
                            label: "Low",
                            style: {
                              width: "55px",
                              wrap: "nowrap",
                              top: "-25px",
                              paddingLeft: "10px",
                            },
                          },
                          30: "16% - 30%",
                          49: {
                            label: "Medium",
                            style: {
                              width: "55px",
                              wrap: "nowrap",
                              top: "-25px",
                              paddingLeft: "10px",
                            },
                          },
                          50: "31% - 50%",
                          69: {
                            label: "High",
                            style: {
                              width: "55px",
                              wrap: "nowrap",
                              top: "-25px",
                              paddingLeft: "10px",
                            },
                          },
                          70: "51% - 75%",
                          99: {
                            label: "Very High",
                            style: {
                              width: "55px",
                              wrap: "nowrap",
                              top: "-25px",
                              left: "95%",
                            },
                          },
                          100: {
                            style: {
                              width: "50px",
                              wrap: "nowrap",
                              left: "95%",
                            },
                            label: "76%-99%",
                          },
                        }}
                      />
                    );
                  }}
                />
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Supporting Documents
                </span>
                <div className="form__switch-group">
                  <span className="form__switch-group-label">No</span>
                  <Field
                    name={`assumptionRiskSupportingDocuments`}
                    render={({ input, meta }) => (
                      <SwitchButton
                        id={input.name}
                        name={input.name}
                        checked={input.checked}
                        onChange={input.onChange}
                        optionLabels={["ON", "OFF"]}
                        small
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="mitigations__score">
                <div className="mitigations__score-box">
                  <p>SCORE: __</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
    </>
  );
};

export default AssumptionDetails;
