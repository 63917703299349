import { Container } from "reactstrap";

const WorkSmarter = () => {
  return (
    <div className="landing__work-smarter">
      <Container fluid="sm">
        <h3>
          <strong>Next Generation Risk Management:</strong> Time to Work Smarter
        </h3>
        <p>
          Riskoncise augments the capacity of your workforce by{" "}
          <span className="text-highlight">automating many decisions</span>{" "}
         and{" "}
          <span className="text-highlight">
            intelligently managing response actions.
          </span>{" "}
        </p>
        <p>
          Risks, Requirements, Assumptions, Issues, Dependencies and Lessons Learned all
          seamlessly managed in one place.
        </p>
        <p>
          Using a blend of modelling capabilities to harness their individual and
          combined strengths, Riskoncise delivers greater levels of{" "}
          <span className="text-highlight">
            confidence, insight and auditability
          </span>{" "}
          than ever seen before.
        </p>
        <p>
          Linking external data sources means that mundane and time-consuming
          manual updates can be a thing of the past.
        </p>
        <div className="work-smarter__link">
          <a href="/about-us">Find out more about our company</a>
        </div>
      </Container>
    </div>
  );
};

export default WorkSmarter;
