import { useDispatch } from "react-redux";

import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import {
  onNewRiskToggle,
  onNewAssumptionToggle,
} from "../../../redux/formModals";
import { onUserModalToggle } from "../../../redux/pages/userManagement";
import { onRiskFormStateChange } from "../../../redux/risks";

type ContentProps = {
  onClick: () => void;
};

const SidebarContent = ({ onClick }: ContentProps) => {
  const dispatch = useDispatch();
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarCategory admin title="ADMIN" icon="people">
          <SidebarLink
            route="/manage-organisations"
            dot
            title="Manage Organisations"
          />
          <SidebarLink
            route="/manage-users"
            title="Manage User Profiles"
            dot
          />
          <SidebarLink
            route="/sent-invitations"
            title="Manage User Licenses"
            dot
          />
        </SidebarCategory>
      </ul>
      <ul className="sidebar__block">
        <SidebarLink route="/" exact title="Home" icon="house-door" />
        <SidebarLink
          route="/my-projects"
          title="My Projects"
          icon="briefcase"
        />
        <SidebarLink
          route="/hierarchy"
          title="Hierarchy View"
          icon="diagram-2"
        />
        <SidebarCategory title="My Network" icon="share">
          <SidebarLink dot route="/forum" title="All Posts" />
          <SidebarLink dot route="/forum" title="Saved Posts" />
          <SidebarLink dot route="/forum" title="My Posts" />
        </SidebarCategory>
      </ul>

      {/* "New" sector */}
      <ul className="sidebar__block">
        <SidebarCategory title="NEW" icon="plus-circle">
          <button
            className="sidebar__link"
            type="button"
            onClick={() => {
              dispatch(onRiskFormStateChange("create"));
              dispatch(onNewRiskToggle());
            }}
          >
            <p className="sidebar__link-title dot">Risk</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Requirement</p>
          </button>
          <button
            className="sidebar__link"
            type="button"
            onClick={() => dispatch(onNewAssumptionToggle())}
          >
            <p className="sidebar__link-title dot">Assumption/Exclusion</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Dependency/Constraint</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Lesson Learned</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Project</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Work Package</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Contact</p>
          </button>
        </SidebarCategory>

        {/* "Analyse" sector */}
        <SidebarCategory title="ANALYSE" icon="clipboard-data">
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">What If Models</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Saved Models</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">View/Edit Scoring Scheme</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">View/Edit Data Sources</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Settings</p>
          </button>
        </SidebarCategory>

        {/* "Reports" sector */}
        <SidebarCategory title="REPORTS" icon="file-earmark-medical">
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">
              Risk Breakdown by Category
            </p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Contingency vs. Risk</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Risk Over Time</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Risk Variance</p>
          </button>
          {/* <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Pre/Post Mitigation</p>
          </button> */}
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Root Cause Tracer</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Risk Heatmap</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Response Actions by Score</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">
              Build Your Own Report/Dashboard
            </p>
          </button>
        </SidebarCategory>

        {/* "Registers" sector */}
        <SidebarCategory title="REGISTERS" icon="list-ul">
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Risk Register</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Requirements Register</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Assumptions/Exclusions Register</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">
              Dependencies/Constraints Register
            </p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Lessons Learned Register</p>
          </button>
          <SidebarLink route="/bulk-upload" title="Bulk Upload" dot />
        </SidebarCategory>

        {/* "Help" sector */}
        <SidebarCategory title="HELP" icon="question-circle">
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Search Riskoncise Help</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">User Guide</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Tutorials</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Provide Feedback</p>
          </button>
          <button className="sidebar__link" type="button">
            <p className="sidebar__link-title dot">Contact Support</p>
          </button>
        </SidebarCategory>
      </ul>
    </div>
  );
};

export default SidebarContent;
