import moment from "moment";
import { FC, useState } from "react";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import {
  ganttDurationInDays,
  ganttStartingDay,
} from "../../../../../../shared/utilities";
import DurationBar from "./DurationBar";
import { IGanttProject } from "../../../../../../types/interfaces";

interface GanttTableRowProps {
  item: IGanttProject;
}

const GanttTableRow: FC<GanttTableRowProps> = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { id, name, start, end, riskDuration, type, tasks } = item;

  const expandHandler = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <tr key={id} className="gantt__task-row">
        {isExpanded ? (
          <ExpandMore
            className="gantt__task-icon"
            style={{
              position: "absolute",
            }}
            onClick={expandHandler}
          />
        ) : (
          <ChevronRight
            className="gantt__task-icon"
            style={{
              position: "absolute",
            }}
            onClick={expandHandler}
          />
        )}

        <td className="gantt__tasks-container">
          <td className="gantt__tasks-id">{id}</td>
          <td className="gantt__tasks-title">{name}</td>
          <td className="gantt__tasks-from">
            {type === "risk" || moment(start).format("D MMM YYYY")}
          </td>
          <td className="gantt__tasks-to">
            {type === "risk" || moment(end).format("D MMM YYYY")}
          </td>
        </td>
        <td className="gantt__schedule-container">
          <tr className="gantt__schedule-days">
            {/* Creates empty day boxes for schedule part */}
            {[...Array(30)].map((e, i) => {
              return <td className="gantt__schedule-day" key={i}></td>;
            })}
            {type !== "risk" && (
              <>
                <DurationBar item={item} />
                <DurationBar item={item} isRisk />
              </>
            )}
          </tr>
        </td>
      </tr>
      {isExpanded
        ? tasks.map((task: any, i: number) => {
            return (
              <tr key={task.id} className="gantt__task-row">
                <td className="gantt__tasks-container">
                  <td className="gantt__tasks-id">{task.id}</td>
                  <td className="gantt__tasks-title">{task.name}</td>
                  <td className="gantt__tasks-from">
                    {moment(task.start).format("D MMM YYYY")}
                  </td>
                  <td className="gantt__tasks-to">
                    {moment(task.end).format("D MMM YYYY")}
                  </td>
                </td>
                <td className="gantt__schedule-container">
                  <tr className="gantt__schedule-days">
                    {[...Array(30)].map((e, i) => {
                      return <td className="gantt__schedule-day" key={i}></td>;
                    })}
                    {/* Original plan */}
                    <DurationBar item={task} />
                    {/* Risk */}
                    <DurationBar item={task} isRisk />
                  </tr>
                </td>
              </tr>
            );
          })
        : null}
    </>
  );
};

export default GanttTableRow;
