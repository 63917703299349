import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

interface layoutState {
  sidebarCollapse: boolean;
}

const initialState: layoutState = {
  sidebarCollapse: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    onSidebarCollapse(state, action) {
      state.sidebarCollapse = !state.sidebarCollapse;
    },
  },
});

export const { onSidebarCollapse } = layoutSlice.actions;

export default layoutSlice.reducer;
