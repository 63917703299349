import React from "react";
import { Modal } from "reactstrap";

type ModalContainerProps = {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
  title?: string;
  classes?: string;
  headerChildren?: React.ReactNode;
  size?: string;
};

function ModalContainer({
  isOpen,
  toggle,
  children,
  headerChildren,
  classes,
  title,
  size,
}: ModalContainerProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <div className="modal__header">
        <h4 className="text-modal  modal__title">{title}</h4>
        {headerChildren}
      </div>
      <div className={`modal__body ${classes}`}>{children}</div>
    </Modal>
  );
}

export default ModalContainer;
