import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { ReactComponent as Icon1 } from "../../../shared/img/landing/icon-1.svg";
import { ReactComponent as Icon2 } from "../../../shared/img/landing/icon-2.svg";
import { ReactComponent as Icon3 } from "../../../shared/img/landing/icon-3.svg";
import { ReactComponent as Icon4 } from "../../../shared/img/landing/icon-4.svg";
import { ReactComponent as Icon5 } from "../../../shared/img/landing/icon-5.svg";
import { ReactComponent as Icon6 } from "../../../shared/img/landing/icon-6.svg";
import { ReactComponent as Icon7 } from "../../../shared/img/landing/icon-7.svg";
import { ReactComponent as Icon8 } from "../../../shared/img/landing/icon-8.svg";

const KeyFeatures = () => {
  const [feature, setFeature] = useState(0);

  const features = [
    {
      title:
        "Risks, Requirements, Assumptions, Issues, Dependencies & Lessons Learned",
      text: "Intelligently manage all your project’s sources of uncertainty in one place and in a truly joined up way. Clearly defined, scored, ranked and modelled, all with SMART actions; Riskoncise brings a new level of rigour and uniformity to the business of delivering successful projects.",
      image: <Icon1 />,
    },
    {
      title: "Combined Cost/Schedule Models for Threat & Opportunity",
      text: "Live Cost and Schedule forecasts with the facility to view your total combined risk exposure and drill down on the drivers behind it.",
      image: <Icon2 />,
    },
    {
      title: "Causes and Consequences Assessed & Modelled",
      text: "Quickly and easily assess the Causes and Consequences of your risks to inform our powerful models and ensure your response actions are focused where it counts.",
      image: <Icon3 />,
    },
    {
      title: "Response Actions Assessed, Modelled & Intelligently Managed",
      text: "Assess your response actions to understand which will deliver the most value and factor them into your models. Notifications sent to Action Owners to advise of upcoming actions and request updates.",
      image: <Icon4 />,
    },
    {
      title: "Data Integrity Challenged, Substantiated & Owned",
      text: "Assessment sources identified, scored and assigned to specific owners with the option to attach supporting documentation. No more ‘garbage in, garbage out’.",
      image: <Icon5 />,
    },
    {
      title: "Updates from External Data Sources Automated & Tracked",
      text: "Linkage to live external data sources ensure risk impacts are always up to date without the need for laborious and time-consuming manual updates.",
      image: <Icon6 />,
    },
    {
      title: "Correlation Easily Mapped as you Work",
      text: "Simple and intuitive mapping of risk correlation. Easily capture the complex relationships between Risks, Assumptions, Issues and Dependencies to provide far more accurate forecasts which allow you to track risks to their source.",
      image: <Icon7 />,
    },
    {
      title: "Real-Time, User Configurable Reporting",
      text: "A range of clear, concise and useful pre-formatted reports and dashboards, with the option to build your own. All using live, up to the moment data.",
      image: <Icon8 />,
    },
  ];

  return (
    <div className="key-features">
      <Container fluid="sm">
        <div className="key-features__title">
          <h3>Key Features</h3>
          <p>
            With its unparalleled selection of cutting-edge tools, Riskoncise
            delivers more
          </p>
        </div>
        <Row className="key-features__content">
          <Col lg={12} xl={8}>
            <div className="key-features__left">
              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(0)}
                >
                  {features[0].image}
                </div>
                <div className="key-features__item-title">
                  {features[0].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(1)}
                >
                  {features[1].image}
                </div>
                <div className="key-features__item-title">
                  {features[1].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(2)}
                >
                  {features[2].image}
                </div>
                <div className="key-features__item-title">
                  {features[2].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(3)}
                >
                  {features[3].image}
                </div>
                <div className="key-features__item-title">
                  {features[3].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(4)}
                >
                  {features[4].image}
                </div>
                <div className="key-features__item-title">
                  {features[4].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(5)}
                >
                  {features[5].image}
                </div>
                <div className="key-features__item-title">
                  {features[5].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(6)}
                >
                  {features[6].image}
                </div>
                <div className="key-features__item-title">
                  {features[6].title}
                </div>
              </div>

              <div className="key-features__item">
                <div
                  className="key-features__item-icon"
                  onMouseEnter={() => setFeature(7)}
                >
                  {features[7].image}
                </div>
                <div className="key-features__item-title">
                  {features[7].title}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} xl={4} style={{ display: "flex", alignItems: "center" }}>
            <div className="key-features__card">
              <h5>{features[feature].title}</h5>
              <p>{features[feature].text}</p>
              <a href="/">READ MORE</a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KeyFeatures;
