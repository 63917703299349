import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Tooltip, UncontrolledTooltip } from "reactstrap";
import {
  onActiveProjectChange,
  onMitigationToggle,
  onOpportunityToggle,
} from "../../../redux/pages/dashboard";
import { RootState } from "../../../redux/store";

import Piechart from "../../../shared/components/charts/Piechart";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RiskTable from "./RiskTable";
import { priceMask, splitTime } from "../../../shared/utilities";
import { IProject } from "../../../types/interfaces";
import { InfoCircle } from "react-bootstrap-icons";

interface Data {
  value: number;
  fill: string;
}

interface ProjectCardProps {
  title: string;
  index: string;
  project: IProject;
  projects: IProject[];
  repr: boolean; // temporary
  price?: string; // temporary
  date?: string; // temporary
  tableData1?: Array<any> | any; // temporary
  tableData2?: Array<any> | any; // temporary
}

const ProjectCard = ({
  title,
  index,
  project,
  projects,
  tableData1,
  tableData2,
  price,
  date,
  repr,
}: ProjectCardProps) => {
  const [opportunities, setOpportunities] = useState(false);
  const [mitigation, setMitigation] = useState(false);
  const [cardCost, setCardCost] = useState<number>(0);
  const [cardSchedule, setCardSchedule] = useState<number>(0);

  const { activeProject } = useSelector((state: RootState) => state.dashboard);

  const toggleMitigation = () => {
    setMitigation(!mitigation);
    dispatch(onMitigationToggle(!mitigation));
  };

  useEffect(() => {
    if (opportunities) {
      setCardCost(project.opportunitiesCost);
      setCardSchedule(project.opportunitiesSchedule);
    } else {
      setCardCost(project.threatsCost);
      setCardSchedule(project.threatsSchedule);
    }
  }, [opportunities]);

  const toggleOpportunities = () => {
    setOpportunities(!opportunities);
    dispatch(onOpportunityToggle(!opportunities));
  };

  const dispatch = useDispatch();

  let mitigationPrice = opportunities
    ? Math.floor(cardCost * 1.2)
    : Math.floor(cardCost * 0.8);
  let mitigationDay = opportunities
    ? Math.floor(cardSchedule * 1.2)
    : Math.floor(cardSchedule * 0.8);

  const chart1: Data[] | undefined =
    projects.length !== 0
      ? [
          {
            value: mitigation
              ? 100 - (cardCost * 100) / 10000
              : 100 - (cardCost * 100) / 10000,
            fill: "#d5dbde",
          },
          {
            value: mitigation
              ? ((cardCost * 100) / 10000) * (opportunities ? 1.2 : 0.8)
              : (cardCost * 100) / 10000,
            fill: opportunities ? "#0286c3" : "#c01f28",
          },
        ]
      : undefined;

  const chart2: Data[] | undefined =
    projects.length !== 0
      ? [
          {
            value: mitigation
              ? 100 -
                (((cardSchedule / 24) * 100) / 365) *
                  (opportunities ? 1.2 : 0.8)
              : 100 - ((cardSchedule / 24) * 100) / 365,
            fill: "#d5dbde",
          },
          {
            value: mitigation
              ? (((cardSchedule / 24) * 100) / 365) *
                (opportunities ? 1.2 : 0.8)
              : ((cardSchedule / 24) * 100) / 365,
            fill: opportunities ? "#095679" : "#84151b",
          },
        ]
      : undefined;

  return (
    <Card
      className={`panel dashboard ${activeProject === index && "active"}`}
      onClick={() => dispatch(onActiveProjectChange(index))}
    >
      <div className="panel__header">
        <div className="panel__title">{title}</div>
        <div className="panel__switches">
          <div className="panel__switch">
            <p className="panel__switch-label">Switch to Opportunities</p>
            <SwitchButton
              id={`opportunities${Math.floor(Math.random() * 200)}`}
              checked={opportunities}
              onChange={toggleOpportunities}
              optionLabels={["ON", "OFF"]}
            />
          </div>
          <div className="panel__switch">
            <p className="panel__switch-label">Include Response Actions</p>
            <SwitchButton
              id={`mitigation${Math.floor(Math.random() * 200)}`}
              checked={mitigation}
              onChange={toggleMitigation}
              optionLabels={["ON", "OFF"]}
            />
          </div>
        </div>
      </div>
      <CardBody className="panel__body">
        {projects.length !== 0 ? (
          <>
            <div className="panel__column">
              <div className="piechart__stat">
                <div className="piechart__title">Cost (£k):</div>

                <div className="piechart__stat-chart">
                  <Piechart
                    height={130}
                    width={130}
                    cx={60}
                    cy={60}
                    innerRadius={53}
                    outerRadius={65}
                    chartData={chart1}
                  />
                  <p className="piechart__stat-label">
                    {repr
                      ? price
                      : mitigation
                      ? `£ ${priceMask(mitigationPrice)}`
                      : `£ ${priceMask(Math.floor(cardCost * 100) / 100)}`}
                    <span
                      style={{ color: opportunities ? "#1e4e74" : "#84151b" }}
                    >
                      {opportunities ? "Opportunities" : "Threats"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="piechart__stat sm">
                <div className="piechart__stat-chart">
                  <Piechart
                    height={50}
                    width={50}
                    cx={20}
                    cy={20}
                    innerRadius={19}
                    outerRadius={25}
                    chartData={chart1}
                  />
                  <p className="piechart__stat-label sm">
                    {Math.floor(
                      mitigation
                        ? ((cardCost * 100) / 10000) *
                            (opportunities ? 1.2 : 0.8)
                        : (cardCost * 100) / 10000
                    )}
                    %
                  </p>
                </div>
                <UncontrolledTooltip target="infoCircle1">
                  Confidence in Forecast
                </UncontrolledTooltip>
                <InfoCircle id="infoCircle1" className="pirchart__state-info" />
              </div>
              <RiskTable repr={repr} reprData={tableData1} />
            </div>

            <div className="panel__column">
              <div className="piechart__stat">
                <div className="piechart__title">
                  Schedule (Days and Hours):{" "}
                </div>
                <div className="piechart__stat-chart">
                  <Piechart
                    height={130}
                    width={130}
                    cx={60}
                    cy={60}
                    innerRadius={53}
                    outerRadius={65}
                    chartData={chart2}
                  />
                  <p className="piechart__stat-label">
                    {repr
                      ? date
                      : mitigation
                      ? `${splitTime(mitigationDay).d}d ${
                          splitTime(mitigationDay).h
                        }h`
                      : `${splitTime(cardSchedule).d}d ${
                          splitTime(cardSchedule).h
                        }h`}
                    <span
                      style={{ color: opportunities ? "#1e4e74" : "#84151b" }}
                    >
                      {opportunities ? "Opportunities" : "Threats"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="piechart__stat sm">
                <div className="piechart__stat-chart">
                  <Piechart
                    height={50}
                    width={50}
                    cx={20}
                    cy={20}
                    innerRadius={19}
                    outerRadius={25}
                    chartData={chart2}
                  />
                  <p className="piechart__stat-label sm">
                    {Math.floor(
                      mitigation
                        ? (((cardSchedule / 24) * 100) / 365) *
                            (opportunities ? 1.2 : 0.8)
                        : ((cardSchedule / 24) * 100) / 365
                    )}
                    %
                  </p>
                </div>
                <UncontrolledTooltip target="infoCircle2">
                  Confidence in Forecast
                </UncontrolledTooltip>
                <InfoCircle id="infoCircle2" className="pirchart__state-info" />
              </div>
              <RiskTable schedule repr={repr} reprData={tableData2} />
            </div>
          </>
        ) : (
          "Loading..."
        )}
      </CardBody>
    </Card>
  );
};

export default ProjectCard;
