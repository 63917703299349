import React from "react";
import Select from "react-select";

import { SelectOption } from "../../../types/types";

type MultiSelectFieldProps = {
  onChange: Function;
  name: string;
  placeholder?: string;
  options?: Array<SelectOption>;
  value: any;
};

export const MultiSelectField = ({
  value,
  name,
  placeholder,
  options,
  onChange,
  ...other
}: MultiSelectFieldProps) => {
  const handleChange = (handleChangeValue: any) => {
    onChange(handleChangeValue);
  };

  return (
    <Select
      isMulti
      name={name}
      value={value}
      onChange={handleChange}
      options={options}
      clearable={false}
      closeMenuOnSelect={false}
      removeSelected={false}
      className="react-select"
      placeholder={placeholder}
      classNamePrefix="react-select"
      {...other}
    />
  );
};

type RenderMultiSelectFieldProps = {
  input: any;
  meta?: any;
  options?: Array<SelectOption>;
  placeholder?: string;
};

const RenderMultiSelectField = ({
  input,
  meta,
  options,
  placeholder,
}: RenderMultiSelectFieldProps) => (
  <div className="form__form-group-input-wrap">
    <MultiSelectField
      {...input}
      options={options}
      placeholder={placeholder}
    />
    {meta.touched && meta.error && (
      <span className="form__form-group-error">{meta.error}</span>
    )}
  </div>
);

export default RenderMultiSelectField;
