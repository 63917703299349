import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import UserProfile from "./components/UserProfile";
import ModalContainer from "../../shared/components/ModalContainer";
import UserDataTable from "./components/UserDataTable";
import { RootState } from "../../redux/store";
import { onUserModalToggle } from "../../redux/pages/userManagement";
import RemoveUser from "./components/RemoveUser";
import UserInvitation from "./components/UserInvitation";

const UserManagement = () => {
  const dispatch = useDispatch();

  const userModal = useSelector(
    (state: RootState) => state.userManagement.userModal
  );
  const userModalType = useSelector(
    (state: RootState) => state.userManagement.userModalType
  );

  let modalTitle, content;
  switch (userModalType) {
    case "info":
      modalTitle = `USER NO. U-1285 - ALISON JONES`;
      content = <UserProfile />;
      break;
    case "remove":
      modalTitle = `USER NO. U-1285 - ALISON JONES`;
      content = <RemoveUser />;
      break;

    case "invitation":
      modalTitle = "SEND INVITE";
      content = (
        <UserInvitation toggleModal={() => dispatch(onUserModalToggle())} />
      );
      break;

    default:
      console.log("Something went wrong");
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Admin/Manage User Profiles</h3>
        </Col>
      </Row>
      <Row>
        <UserDataTable />
      </Row>
      <ModalContainer
        isOpen={userModal}
        toggle={() => dispatch(onUserModalToggle())}
        size={userModalType === "invitation" ? "xl" : "lg"}
        title={modalTitle}
      >
        {content}
      </ModalContainer>
    </Container>
  );
};

export default UserManagement;
