import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalContainer from "../../shared/components/ModalContainer";
import { RootState } from "../../redux/store";
import AssumptionNavigation from "./components/AssumptionNavigation";
import { onNewAssumptionToggle } from "../../redux/formModals";
import AssumptionForm from "./AssumptionForm";
import AssumptionDetails from "./components/AssumptionDetails";
import AssumptionConcequences from "./components/AssumptionConsequences";
import AssumptionMitigations from "./components/AssumptionMitigations";

const Assumptions = () => {
  const dispatch = useDispatch();

  const isNewAssumptionOpen = useSelector(
    (state: RootState) => state.modals.newAssumptionOpen
  );

  const onSubmit = (values: any) => {
    console.log(values);
  };
  return (
    <ModalContainer
      title="ADD ASSUMPTION"
      isOpen={isNewAssumptionOpen}
      size="xl"
      toggle={() => dispatch(onNewAssumptionToggle())}
      headerChildren={<AssumptionNavigation />}
    >
      <div className="assumptions-form__container">
        <AssumptionForm initialValues={{}} onSubmit={onSubmit}>
          <AssumptionForm.Page>
            <AssumptionDetails />
          </AssumptionForm.Page>
          <AssumptionForm.Page>
            <AssumptionConcequences />
          </AssumptionForm.Page>
          <AssumptionForm.Page>
            <AssumptionMitigations />
          </AssumptionForm.Page>
        </AssumptionForm>
      </div>
    </ModalContainer>
  );
};

export default Assumptions;
