import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import SwitchButton from "../../../../../shared/components/form/SwitchButton";
import MCGraph from "../components/MCGraph";
import { RootState } from "../../../../../redux/store";
import { onMonteCarloGraphTypeToggle } from "../../../../../redux/pages/dashboard";

const MonteCarloGraph = () => {
  const dispatch = useDispatch();

  const graphType = useSelector(
    (state: RootState) => state.dashboard.monteCarloGraphType
  );

  const graphTypeToggle = () => {
    dispatch(onMonteCarloGraphTypeToggle());
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="tab__header">
            <h4 className="tab__header--title">Monte Carlo Output Graph</h4>
            <p className="tab__header--view">
              <div className="switch__wrap" style={{ marginRight: "20px" }}>
                <p>Schedule Risk</p>
                <SwitchButton
                  name="monte-carlo__scheduleRisk"
                  small
                  id="monte-carlo__scheduleRisk"
                  onChange={graphTypeToggle}
                  checked={graphType}
                />
              </div>
              View full stats
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="tab__graph-container">
          <MCGraph />
        </div>
      </Row>
    </Container>
  );
};

export default MonteCarloGraph;
