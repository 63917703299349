import axios from "axios";
import Config from "./../Config.js";

let headers = {
  "Content-Type": "application/json",
};

const api = axios.create({
  baseURL: Config.API.BaseUrl,
  headers,
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("Authorization");
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => {    
    Promise.reject(error)
  }
);

export default api;
