import { useMsal } from "@azure/msal-react";
import { ArrowRight } from "react-bootstrap-icons";
import { Button } from "reactstrap";

import { B2C_SCOPES } from "../../../B2C";

interface AccessProps {
  color: "light" | "dark";
  text: string;
}

const Access = ({ color, text }: AccessProps) => {
  const { instance } = useMsal();

  return (
    <div className={`landing__access ${color}`}>
      <h3>{text}</h3>
      <button onClick={() => instance.loginRedirect(B2C_SCOPES.API_ACCESS)}>
        Get started <ArrowRight size={20} />
      </button>
    </div>
  );
};

export default Access;
