import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import ContingencyRisk from "./pages/ContingencyRisk";
import RiskOverTime from "./pages/RiskOverTime";
import TopRiskVariances from "./pages/TopRiskVariances";
import MonteCarloGraph from "./pages/MonteCarloGraph";
import RiskBreakdown from "./pages/RiskBreakdown";
import { RootState } from "../../../../redux/store";
import workPackages from "../../../../redux/workPackages";
import GanttChart from "./components/GanttChart/GanttChart";
import GanttChartPage from "./pages/GanttChartPage";

const DashboardTabs = () => {
  const [activeTab, setActiveTab] = useState("4");

  const activeTabToggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const project = useSelector((state: RootState) => state.dashboard.project);
  const workPackages = useSelector(
    (state: RootState) => state.workPackages.workPackageList
  );
  const isGanttChartRisks = useSelector(
    (state: RootState) => state.dashboard.isGanttChartRisks
  );

  return (
    <Card>
      <CardBody>
        <div className="tabs tabs--border-bottom">
          <div className="tabs__wrap" style={{ minWidth: "initial" }}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => activeTabToggle("1")}
                >
                  Risk Breakdown by Categories
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => activeTabToggle("2")}
                >
                  Contingency vs. Risk
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => activeTabToggle("3")}
                >
                  Risk Over Time
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => activeTabToggle("4")}
                >
                  Top Risk Variances
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => activeTabToggle("5")}
                >
                  Monte Carlo Output Graph
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => activeTabToggle("6")}
                >
                  Gantt Chart
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {project && workPackages.length !== 0 ? (
                  <RiskBreakdown project={project} />
                ) : (
                  <p>Loading...</p>
                )}
              </TabPane>
              <TabPane tabId="2">
                <ContingencyRisk />
              </TabPane>
              <TabPane tabId="3">
                <RiskOverTime />
              </TabPane>
              <TabPane tabId="4">
                <TopRiskVariances />
              </TabPane>
              <TabPane tabId="5">
                <MonteCarloGraph />
              </TabPane>
              <TabPane tabId="6">
                {/* {isGanttChartRisks ? <GanttChartPage /> : <GanttChart />} */}
                <GanttChartPage />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardTabs;
