import api from "./api";

export const getProjectsRequest = () => {
  return api.get(`/Projects`);
};

export const getProjectRequest = (id: string) => {
  return api.get(`/Projects/${id}`);
};

export const createProjectRequest = (body: any) => {
  return api.post(`/Projects`, body);
};

export const updateProjectRequest = (id: string, body: any) => {
  return api.put(`/Projects/${id}`, { id, ...body });
};

export const deleteProjectRequest = (id: string) => {
  return api.delete(`/Projects/${id}`);
};
