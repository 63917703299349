import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  createOrganisationRequest,
  deleteOrganisationRequest,
  getOrganisationRequest,
  getOrganisationsRequest,
  updateOrganisationRequest,
} from "../../api/organisations";
import { Organisation } from "../../types/interfaces";

interface OrganisationsState {
  organisationsList: Organisation[];
  organisation: Organisation | null | undefined;
  editMode: boolean;
}

const initialState: OrganisationsState = {
  organisationsList: [],
  organisation: null,
  editMode: false,
};

export const organisationsSlice = createSlice({
  name: "organisations",
  initialState,
  reducers: {
    onEditModeChange(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    updateOrganisationsList(state, action: PayloadAction<Organisation[]>) {
      state.organisationsList = action.payload;
    },
    onOrganisationCreate(state, action: PayloadAction<Organisation>) {
      const oldList = state.organisationsList;
      oldList.push(action.payload);
      state.organisationsList = oldList;
    },
    onUpdateOrganisation(state, action: PayloadAction<Organisation["id"]>) {
      const organisation = state.organisationsList.find(
        (o) => o.id === action.payload
      );
      state.organisation = organisation;
    },
    onOrganisationDelete(state, action: PayloadAction<Organisation["id"]>) {
      const newOrganisationsList = state.organisationsList.filter(
        (o) => o.id !== action.payload
      );
      state.organisationsList = newOrganisationsList;
    },
  },
});

export const {
  onEditModeChange,
  updateOrganisationsList,
  onOrganisationDelete,
  onOrganisationCreate,
  onUpdateOrganisation,
} = organisationsSlice.actions;

export const getOrganisations = () => async (dispatch: Dispatch) => {
  const result = await getOrganisationsRequest();
  dispatch(updateOrganisationsList(result.data));
};

export const getOrganisation =
  (id: Organisation["id"]) => async (dispatch: Dispatch) => {
    try {
      const result = await getOrganisationRequest(id);
      dispatch(onUpdateOrganisation(result.data));
    } catch (error) {
      console.log(error);
    }
  };

export const createOrganisation =
  (body: Organisation) => async (dispatch: Dispatch) => {
    try {
      await createOrganisationRequest(body);
      const result = await getOrganisationsRequest();
      dispatch(updateOrganisationsList(result.data));
    } catch (error) {
      console.log(error);
    }
  };

export const updateOrganisation =
  (id: Organisation["id"], body: Organisation) =>
  async (dispatch: Dispatch) => {
    try {
      await updateOrganisationRequest(id, body);
      const result = await getOrganisationsRequest();
      dispatch(updateOrganisationsList(result.data));
    } catch (error) {
      console.log(error);
    }
  };

export const deleteOrganisation =
  (id: Organisation["id"]) => async (dispatch: Dispatch) => {
    try {
      await deleteOrganisationRequest(id);
      dispatch(onOrganisationDelete(id));
    } catch (error) {
      console.log(error);
    }
  };

export default organisationsSlice.reducer;
