import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { Country } from "../types/interfaces";
import { getCountriesRequest } from "../api/other/countries";

interface otherState {
  countries: Country[];
  country: Country | null;
}

const initialState: otherState = {
  countries: [],
  country: null,
};

export const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    onCoutriesRecieved(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
    },
  },
});

export const { onCoutriesRecieved } = otherSlice.actions;

export const getCountries = () => async (dispatch: Dispatch) => {
  try {
    const result = await getCountriesRequest();
    dispatch(onCoutriesRecieved(result.data));
  } catch (error) {
    console.log(error);
  }
};

export default otherSlice.reducer;
