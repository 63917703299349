import { Field } from "react-final-form";
import { Col, Row } from "reactstrap";

import RenderDatePickerField from "../../../shared/components/form/DatePicker";
import { RenderField } from "../../../shared/components/form/Field";
import RenderFileInputField from "../../../shared/components/form/FileInput";
import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RenderSlider from "../../../shared/components/range_slider/Slider";

type MitigationProps = {
  mitigationNumber: number;
};

const Mitigation = ({ mitigationNumber }: MitigationProps) => {
  return (
    <>
      <div className="form-title">{`MITIGATION ${mitigationNumber}`}</div>
      <Col md={12}>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Action Title</span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationTitle${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Assigned Id</span>
              <div className="form__form-group-field">
                <Field
                  name="assumptionTitle"
                  component={RenderField}
                  type="text"
                  placeholder="T-0512"
                  disabled
                />
              </div>
            </div>
          </Col>
          <div className="form__form-group">
            <span className="form__form-group-label">Action Description</span>
            <div className="form__form-group-field">
              <Field
                name={`mitigationDescription${mitigationNumber}`}
                component="textarea"
                type="text"
              />
            </div>
          </div>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Action Owner <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwner${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Status <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationStatus${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Target Completion Date
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name={`mitigationTargetCompletionDate${mitigationNumber}`}
                      render={({ input }) => (
                        <RenderDatePickerField input={input} />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Actual Completion Date
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name={`mitigationActualCompletionDate${mitigationNumber}`}
                      render={({ input }) => (
                        <RenderDatePickerField input={input} />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Mitigation Effectiveness - Probability
                </span>
                <Field
                  name={`assumptionMitigationProbability`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={100}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        14: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        15: "1% - 15%",
                        29: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        30: "16% - 30%",
                        49: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        50: "31% - 50%",
                        69: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        70: "51% - 75%",
                        99: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        100: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "76%-99%",
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Ease of Implementation - Level of Effort
                </span>
                <Field
                  name={`assumptionMitigationEffortLevel`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={100}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        14: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        15: "1% - 15%",
                        29: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        30: "16% - 30%",
                        49: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        50: "31% - 50%",
                        69: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        70: "51% - 75%",
                        99: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        100: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "76%-99%",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Action Owner Role</span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwnerRole${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Action Owner Organisation <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwnerOrganisation${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "one" },
                        { value: "2", label: "two" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Progress</span>
              <Field
                name={`assumptionMitigationProgress`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Mitigation Effectivenes - Cost (£)
              </span>
              <Field
                name={`assumptionMitigationProgress`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={1000}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      140: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      150: "£0 - £150k",
                      290: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      300: "£150k - £300k",
                      490: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      500: "£300k - £500k",
                      690: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      700: "£500k - £750k",
                      990: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      1000: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> £750k",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Add supporting documents
              </span>
              <Field
                name={`assumptionMititgationSupportingDocuments${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderFileInputField input={input} />
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="mitigations__score">
              <div className="mitigations__score-box">
                <p>SCORE: __</p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Mitigation;
