import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  createRiskRequest,
  deleteRiskRequest,
  getRiskRequest,
  getRisksRequest,
  updateRiskRequest,
} from "../api/risks";
import { IRisk, ISupportingDocument } from "../types/interfaces";

interface risksState {
  risk: IRisk | null;
  risksList: Array<IRisk> | null;
  riskFormState: "create" | "edit";
  activePage: number;
  opportunitiesList: IRisk[];
  threatsList: IRisk[];
  isMonteCarlo: boolean;
  riskSupportingDocuments: Array<any>;
  actionSupportingDocuments: Array<any>;
}

const initialState: risksState = {
  risk: null,
  risksList: null,
  riskFormState: "create",
  opportunitiesList: [],
  threatsList: [],
  activePage: 0,
  isMonteCarlo: false,
  riskSupportingDocuments: [],
  actionSupportingDocuments: [],
};

export const risksSlice = createSlice({
  name: "risks",
  initialState,
  reducers: {
    onRiskChosen: (state, action: PayloadAction<IRisk | null>) => {
      state.risk = action.payload;
    },
    onRisksListReceived: (state, action: PayloadAction<IRisk[]>) => {
      state.risksList = action.payload;
      state.opportunitiesList = action.payload.filter(
        (risk) => risk.type === "Opportunity"
      );
      state.threatsList = action.payload.filter(
        (risk) => risk.type === "Threat"
      );
    },
    onRiskFormStateChange: (
      state,
      action: PayloadAction<"create" | "edit">
    ) => {
      state.riskFormState = action.payload;
    },
    onNextPage: (state, action: PayloadAction<React.ReactChildren[]>) => {
      state.activePage = Math.min(
        state.activePage + 1,
        action.payload.length - 1
      );
    },
    onPreviousPage: (state, action) => {
      state.activePage = Math.max(state.activePage - 1, 0);
    },
    onMovePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload;
    },
    onMonteCarloChange: (state, action: PayloadAction<boolean>) => {
      state.isMonteCarlo = action.payload;
    },
    addRiskSupportingDocument: (
      state,
      action: PayloadAction<ISupportingDocument>
    ) => {
      const oldSupDocs = [...state.riskSupportingDocuments];
      oldSupDocs.push(action.payload);
      state.riskSupportingDocuments = oldSupDocs;
    },
    addActionSupportingDocument: (
      state,
      action: PayloadAction<ISupportingDocument>
    ) => {
      const oldSupDocs = [...state.actionSupportingDocuments];
      oldSupDocs.push(action.payload);
      state.actionSupportingDocuments = oldSupDocs;
    },
  },
});

export const {
  onRiskChosen,
  onRisksListReceived,
  onRiskFormStateChange,
  onNextPage,
  onPreviousPage,
  onMovePage,
  onMonteCarloChange,
  addRiskSupportingDocument,
  addActionSupportingDocument
} = risksSlice.actions;

export const getRisks = () => async (dispatch: Dispatch) => {
  const result = await getRisksRequest();
  dispatch(onRisksListReceived(result.data));
};

export const getRisk = (id: string) => async (dispatch: Dispatch) => {
  const result = await getRiskRequest(id);
  dispatch(onRiskChosen(result.data));
};

export const createRisk = (body: any) => async (dispatch: Dispatch) => {
  await createRiskRequest(body);
  const result = await getRisksRequest();
  dispatch(onRisksListReceived(result.data));
};

export const updateRisk =
  (id: string, body: any) => async (dispatch: Dispatch) => {
    await updateRiskRequest(id, body);
    const result = await getRisksRequest();
    dispatch(onRisksListReceived(result.data));
  };

export const deleteRisk = (id: string) => async (dispatch: Dispatch) => {
  await deleteRiskRequest(id);
  const result = await getRisksRequest();
  dispatch(onRisksListReceived(result.data));
};

export default risksSlice.reducer;
