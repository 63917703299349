import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import store from "../../redux/store";
import { MsalProvider } from "@azure/msal-react";

interface AppProps {
  msalInstance: any;
}

function App({ msalInstance }: AppProps) {
    return (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    );
}

export default App;
