import defaultConfig from './ConfigDefaults.json';

var Config = {
    B2C: {
      Instance: process.env.REACT_APP_B2C_Instance === undefined ? defaultConfig.B2C.Instance : process.env.REACT_APP_B2C_Instance, 
      Tenant: process.env.REACT_APP_B2C_Tenant === undefined ? defaultConfig.B2C.Tenant : process.env.REACT_APP_B2C_Tenant,
      // SignInPolicy: defaultConfig.B2C.SignInPolicy,
      SignInPolicy: process.env.REACT_APP_B2C_SignInPolicy === undefined ? defaultConfig.B2C.SignInPolicy : process.env.REACT_APP_B2C_SignInPolicy,
      ClientId:process.env.REACT_APP_B2C_ClientId === undefined ? defaultConfig.B2C.ClientId : process.env.REACT_APP_B2C_ClientId,
      Scopes: process.env.REACT_APP_B2C_Scopes === undefined ? defaultConfig.B2C.Scopes : [ process.env.REACT_APP_B2C_Scopes ],
      RedirectUri: process.env.REACT_APP_B2C_RedirectUri === undefined ? defaultConfig.B2C.RedirectUri : process.env.REACT_APP_B2C_RedirectUri
    },
    API: {
      BaseUrl: process.env.REACT_APP_API_BaseUrl === undefined ? defaultConfig.API.BaseUrl : process.env.REACT_APP_API_BaseUrl,
    }
  };
  
  export default Config;