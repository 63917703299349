import { ChevronDown } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { onNewRiskToggle } from "../../../../redux/formModals";
import {
  deleteRisk,
  getRisk,
  onRiskFormStateChange,
} from "../../../../redux/risks";
import { RootState } from "../../../../redux/store";
import { BasicTable } from "../../../../shared/components/table/BasicTable";
import { priceMask } from "../../../../shared/utilities";
import { IRisk } from "../../../../types/interfaces";

type RiskMenuProps = {
  id: string;
};

const RiskMenu = ({ id }: RiskMenuProps) => {
  const dispatch = useDispatch();

  const onEditClick = () => {
    dispatch(getRisk(id));
    dispatch(onRiskFormStateChange("edit"));
    dispatch(onNewRiskToggle());
  };

  const onDeleteClick = () => {
    dispatch(deleteRisk(id));
  };

  return (
    <UncontrolledDropdown direction="left">
      <DropdownToggle>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu left>
        <DropdownItem onClick={onEditClick}>Edit</DropdownItem>
        <DropdownItem>View Details</DropdownItem>
        <DropdownItem onClick={onDeleteClick}>Close</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const Risk = () => {
  const { opportunitiesList, threatsList, risksList } = useSelector(
    (state: RootState) => state.risks
  );
  const opportunities = useSelector(
    (state: RootState) => state.dashboard.isOpportunities
  );

  const headers: Column<IRisk>[] = [
    {
      Header: "ID",
      accessor: "identifier",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Owner",
      accessor: "owner",
      Cell: ({ value, row }) => {
        return (
          <div className="risk-registers__owner">
            <p>{value}</p>
            <span>{row.original.ownerRole}</span>
          </div>
        );
      },
    },
    {
      Header: "Cost",
      accessor: "costSuggestedLikely",
      Cell: ({ value }) => {
        const getRandom = () => {
          const number = Math.floor(Math.random() * 2);
          return number === 0 ? (
            <div className="triangle-up" style={{ marginRight: "5px" }} />
          ) : (
            <div className="triangle-down" style={{ marginRight: "5px" }} />
          );
        };
        return (
          <span>
            {getRandom()} £ {priceMask(Number(value))}
          </span>
        );
      },
    },
    {
      Header: "Score",
      accessor: "score",
      Cell: ({ value }) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "#c01f28",
                fontWeight: 700,
              }}
            >
              {value}
            </p>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Start Date",
      accessor: "riskImpactExposureStartDate",
      Cell: ({ value }) => {
        return (
          <span>
            {value &&
              new Date(value).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
          </span>
        );
      },
    },
    {
      Header: "Expiry Date",
      accessor: "riskImpactExposureEndDate",
      Cell: ({ value }) => {
        return (
          <span>
            {value &&
              new Date(value).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
          </span>
        );
      },
    },
    {
      Header: "Work package",
      accessor: "workPackageName",
      Cell: ({ value }) => {
        return (
          <div className="risk-registers__owner">
            <p>{value}</p>
            <span>Staff room</span>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "modal",
      Cell: ({ row }) => {
        return <RiskMenu id={row.original.id} />;
      },
    },
  ];

  return (
    <Card>
      <CardBody>
        {risksList !== null ? (
          <BasicTable
            tableHeaders={headers}
            title="RISK REGISTER"
            exportFile
            header
            pagination
            search
            saveButton
            showColumns
            detailedView
            tableData={opportunities ? opportunitiesList : threatsList}
          />
        ) : (
          <p>Loading...</p>
        )}
      </CardBody>
    </Card>
  );
};

export default Risk;
