import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Collapse } from "reactstrap";

import { onSidebarCollapse } from "../../../redux/layout";
import { RootState } from "../../../redux/store";

type CategoryProps = {
  title: string;
  icon?: string;
  admin?: boolean;
  children: React.ReactNode;
};

const SidebarCategory = ({ title, icon, children, admin }: CategoryProps) => {
  const [categoryCollapse, setCategoryCollapse] = useState(false);

  const changeCategoryCollapse = () => {
    setCategoryCollapse(!categoryCollapse);
  };

  const sidebarCollapse = useSelector(
    (state: RootState) => state.layout.sidebarCollapse
  );

  const categoryClass = classNames({
    "sidebar__category-wrap": true,
    "sidebar__link sidebar__category": true,
    "admin": admin,
    "sidebar__category-wrap--open": categoryCollapse,
  });

  return (
    <div className={sidebarCollapse ? "sidebar-collapse-wrapper" : ""}>
      <button
        type="button"
        className={categoryClass}
        onClick={changeCategoryCollapse}
      >
        {icon ? <i className={`sidebar__link-icon bi-${icon}`}></i> : ""}
        <p className="sidebar__link-title">{title}</p>
        <span className="sidebar__category-icon bi-chevron-right" />
      </button>
      <Collapse isOpen={categoryCollapse} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
};

export default SidebarCategory;
