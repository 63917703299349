import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { IWorkPackage } from "../types/interfaces";
import {
  getWorkPackagesRequest,
  getWorkPackageRequest,
  createWorkPackageRequest,
  updateWorkPackageRequest,
  deleteWorkPackageRequest,
} from "../api/workPackages";

interface workPackageState {
  workPackageList: Array<IWorkPackage>;
  workPackageChosen: IWorkPackage | null;
}

const initialState: workPackageState = {
  workPackageList: [],
  workPackageChosen: null,
};

export const workPackagesSlice = createSlice({
  name: "workPacakges",
  initialState,
  reducers: {
    onWorkPackageChosen: (state, action: PayloadAction<IWorkPackage>) => {
      state.workPackageChosen = action.payload;
    },
    onWorkPackagesReceived: (
      state,
      action: PayloadAction<Array<IWorkPackage>>
    ) => {
      state.workPackageList = action.payload;
    },
  },
});

export const { onWorkPackagesReceived, onWorkPackageChosen } =
  workPackagesSlice.actions;

export const getWorkPackages = () => async (dispatch: Dispatch) => {
  const result = await getWorkPackagesRequest();
  dispatch(onWorkPackagesReceived(result.data));
};

export const getWorkPackage = (id: string) => async (dispatch: Dispatch) => {
  const result = await getWorkPackageRequest(id);
  dispatch(onWorkPackageChosen(result.data));
};

export const createWorkPackage =
  (body: IWorkPackage) => async (dispatch: Dispatch) => {
    await createWorkPackageRequest(body);
    const result = await getWorkPackagesRequest();
    dispatch(onWorkPackagesReceived(result.data));
  };

export const updateWorkPackage =
  (id: string, body: IWorkPackage) => async (dispatch: Dispatch) => {
    await updateWorkPackageRequest(id, body);
    const result = await getWorkPackagesRequest();
    dispatch(onWorkPackagesReceived(result.data));
  };

export const deleteWorkPackage = (id: string) => async (dispatch: Dispatch) => {
  await deleteWorkPackageRequest(id);
  const result = await getWorkPackagesRequest();
  dispatch(onWorkPackageChosen(result.data));
};

export default workPackagesSlice.reducer;
