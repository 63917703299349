import api from "./api";
import { IUserInvitation } from "../types/interfaces";

export const getUserInvitationsRequest = () => {
  return api.get("/UserInvitations");
};

export const getUserInvitationRequest = (id: string) => {
  return api.get(`/UserInvitations/${id}`);
};

export const createUserInvitationRequest = (body: IUserInvitation) => {
  return api.post(`/UserInvitations`, body);
};

export const updateUserInvitationRequest = (
  id: string,
  body: IUserInvitation
) => {
  return api.put(`/UserInvitations`, { ...body, id });
};

export const deleteUserInvitationRequest = (id: string) => {
  return api.delete(`/UserInvitations/${id}`);
};
