import React from "react";
import Slider, { Settings } from "react-slick";

type CarouselMultiplyProps = {
  children: any;
};

const CarouselMultiply = ({ children }: CarouselMultiplyProps) => {
  const settings: Settings = {
    dots: true,
    speed: 500,
    infinite: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      { breakpoint: 1340, settings: { slidesToShow: 1 } },
        { breakpoint: 1870, settings: { slidesToShow: 2 } },
      
      //   { breakpoint: 1200, settings: { slidesToShow: 3 } },
      //   { breakpoint: 100000, settings: { slidesToShow: 4 } },
    ],
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default CarouselMultiply;
