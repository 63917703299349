import { Button, Col, Row } from "reactstrap";
import { Field } from "react-final-form";

import RenderSelectField from "../../../shared/components/form/Select";
import RenderSlider from "../../../shared/components/range_slider/Slider";
import { WizzardPageType } from "../../../types/types";
import AssessmentForm from "./AssessmentForm";

type Props = {
  parentNumber?: number;
  pageTitle: string;
} & WizzardPageType;

const AssessmentSource = ({
  parentNumber,
  values,
  mutators,
  pageTitle,
}: Props) => {
  const getAssessmentForm = (page: string) => {
    if (values === null) {
      return <></>;
    }
    switch (values[`${page}AssessmentSource${parentNumber}`]?.value) {
      case "surveys":
        return (
          <AssessmentForm
            type="Survey"
            pageTitle={pageTitle}
            parentNumber={parentNumber}
          />
        );
      case "documentaryEvidence":
        return (
          <AssessmentForm
            type="Documentary Evidence"
            pageTitle={pageTitle}
            parentNumber={parentNumber}
          />
        );
      case "specialistOpinion":
        return (
          <AssessmentForm
            type="Specialist Opinion"
            pageTitle={pageTitle}
            parentNumber={parentNumber}
          />
        );
      default:
        new Error("Something happened");
        break;
    }
  };

  return (
    <>
      <Row>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Assessment Source</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}AssessmentSource${parentNumber}`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    meta={meta}
                    placeholder="Assessment Source"
                    options={[
                      { value: "surveys", label: "Surveys" },
                      {
                        value: "documentaryEvidence",
                        label: "Documentary Evidence",
                      },
                      {
                        value: "specialistOpinion",
                        label: "Specialist Opinion",
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Confidence in Assessment
            </span>
            <Field
              name={`${pageTitle}Confidence${parentNumber}`}
              render={({ input, meta }) => (
                <RenderSlider
                  min={0}
                  max={100}
                  value={input.value}
                  onChange={input.onChange}
                  marks={{
                    14: {
                      label: "Very Low",
                      style: {
                        width: "55px",
                        wrap: "nowrap",
                        top: "-25px",
                        paddingLeft: "10px",
                      },
                    },
                    15: "1% - 15%",
                    29: {
                      label: "Low",
                      style: {
                        width: "55px",
                        wrap: "nowrap",
                        top: "-25px",
                        paddingLeft: "10px",
                      },
                    },
                    30: "16% - 30%",
                    49: {
                      label: "Medium",
                      style: {
                        width: "55px",
                        wrap: "nowrap",
                        top: "-25px",
                        paddingLeft: "10px",
                      },
                    },
                    50: "31% - 50%",
                    69: {
                      label: "High",
                      style: {
                        width: "55px",
                        wrap: "nowrap",
                        top: "-25px",
                        paddingLeft: "10px",
                      },
                    },
                    70: "51% - 75%",
                    99: {
                      label: "Very High",
                      style: {
                        width: "55px",
                        wrap: "nowrap",
                        top: "-25px",
                        left: "95%",
                      },
                    },
                    100: {
                      style: {
                        width: "50px",
                        wrap: "nowrap",
                        left: "95%",
                      },
                      label: "76%-99%",
                    },
                  }}
                />
              )}
            />
          </div>
        </Col>
      </Row>
      {getAssessmentForm(pageTitle)}
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col md={6} xs={12}>
          <Button type="button" color="primary" outline>
            + ADD ASSESSMENT SOURCE
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentSource;
