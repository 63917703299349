import React, { useEffect, useState } from "react";
import data from "../riskOverTimeData.json";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
} from "recharts";

type RiskOverTimeGraphProps = {
  zoom: string;
};

const RiskOverTimeGraph = ({ zoom }: RiskOverTimeGraphProps) => {
  const [chosenZoom, setChosenZoom] = useState<number>(30);

  const applyZoom = () => {
    switch (zoom) {
      case "1":
        setChosenZoom(1);
        break;
      case "2":
        setChosenZoom(7);
        break;
      case "3":
        setChosenZoom(30);
        break;
      case "4":
        setChosenZoom(90);
        break;
      case "5":
        setChosenZoom(180);
        break;
      case "6":
        setChosenZoom(365);
        break;
      case "7":
        setChosenZoom(data.length - 1);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    applyZoom();
  }, [zoom]);

  const formatXAxis = (tickItem: string) => {
    return new Date(tickItem).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
  };

  const orderedList = data.sort(
    (a, b) => +new Date(a.name) - +new Date(b.name)
  );

  return (
    <ResponsiveContainer key={chosenZoom} height={400}>
      <AreaChart
        data={orderedList}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickFormatter={formatXAxis}
          tickCount={2}
          tickLine={false}
        />
        <YAxis />
        <Tooltip />
        <Brush endIndex={chosenZoom} />
        <Area
          type="monotone"
          dataKey="Risk Impact Exposure"
          stroke="#1e4e74"
          fill="#cee1f0"
        />
        <Area
          type="monotone"
          dataKey="Cost Impact Exposure"
          stroke="#c01f28"
          fill="#f3d2d4"
        />
        <Legend />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RiskOverTimeGraph;
