import React, { FC } from "react";
import { IGanttProject } from "../../../../../../types/interfaces";
import GanttTableRow from "./TableRow";

type TaskViewData = {
  data: IGanttProject[];
};

const TaskView: FC<TaskViewData> = ({ data }) => {
  return (
    <div className="gantt__table">
      <table>
        <thead>
          <tr>
            <td className="gantt__tasks-container">
              <th className="gantt__tasks-id">Task ID</th>
              <th className="gantt__tasks-title">Task Title</th>
              <th className="gantt__tasks-from">From</th>
              <th className="gantt__tasks-to">To</th>
            </td>
            <td className="gantt__schedule-container">
              <tr className="gantt__schedule-month">
                <td>April</td>
              </tr>
              <tr className="gantt__schedule-days">
                <td className="gantt__schedule-day">Fri, 1</td>
                <td className="gantt__schedule-day">Sat, 2</td>
                <td className="gantt__schedule-day">Sun, 3</td>
                <td className="gantt__schedule-day">Mon, 4</td>
                <td className="gantt__schedule-day">Tue, 5</td>
                <td className="gantt__schedule-day">Wed, 6</td>
                <td className="gantt__schedule-day">Thu, 7</td>
                <td className="gantt__schedule-day">Fri, 8</td>
                <td className="gantt__schedule-day">Sat, 9</td>
                <td className="gantt__schedule-day">Sun, 10</td>
                <td className="gantt__schedule-day">Mon, 11</td>
                <td className="gantt__schedule-day">Tue, 12</td>
                <td className="gantt__schedule-day">Wed, 13</td>
                <td className="gantt__schedule-day">Thu, 14</td>
                <td className="gantt__schedule-day">Fri, 15</td>
                <td className="gantt__schedule-day">Sat, 16</td>
                <td className="gantt__schedule-day">Sun, 17</td>
                <td className="gantt__schedule-day">Mon, 18</td>
                <td className="gantt__schedule-day">Tue, 19</td>
                <td className="gantt__schedule-day">Wed, 20</td>
                <td className="gantt__schedule-day">Thu, 21</td>
                <td className="gantt__schedule-day">Fri, 22</td>
                <td className="gantt__schedule-day">Sat, 23</td>
                <td className="gantt__schedule-day">Sun, 24</td>
                <td className="gantt__schedule-day">Mon, 25</td>
                <td className="gantt__schedule-day">Tue, 26</td>
                <td className="gantt__schedule-day">Wed, 27</td>
                <td className="gantt__schedule-day">Thu, 28</td>
                <td className="gantt__schedule-day">Fri, 29</td>
                <td className="gantt__schedule-day">Sat, 30</td>
              </tr>
            </td>
          </tr>
        </thead>
        <tbody>
          {data.map((project) => {
            return <GanttTableRow item={project} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TaskView;
