import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

interface AssumptionsState {
  activePage: number;
}

const initialState: AssumptionsState = {
  activePage: 0,
};

const assumptionsSlice = createSlice({
  name: "assumptions",
  initialState,
  reducers: {
    onNextPage: (state, action: PayloadAction<React.ReactChildren[]>) => {
      state.activePage = Math.min(
        state.activePage + 1,
        action.payload.length - 1
      );
    },
    onPreviousPage: (state) => {
      state.activePage = Math.max(state.activePage - 1, 0);
    },
    onMovePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload;
    },
  },
});

export const { onNextPage, onPreviousPage, onMovePage } =
  assumptionsSlice.actions;

export default assumptionsSlice.reducer;
