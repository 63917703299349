import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

interface modalsState {
  newRiskOpen: boolean;
  newAssumptionOpen: boolean;
  addPostOpen: boolean;
}

const initialState: modalsState = {
  newRiskOpen: false,
  newAssumptionOpen: false,
  addPostOpen: false,
};

export const modalsStateSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    onNewRiskToggle(state) {
      state.newRiskOpen = !state.newRiskOpen;
    },
    onNewAssumptionToggle(state) {
      state.newAssumptionOpen = !state.newAssumptionOpen;
    },
    onAddPostToggle(state) {
      state.addPostOpen = !state.addPostOpen;
    },
  },
});

export const { onNewRiskToggle, onNewAssumptionToggle, onAddPostToggle } =
  modalsStateSlice.actions;

export default modalsStateSlice.reducer;
