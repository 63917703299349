import { List } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { onSidebarCollapse } from "../../../redux/layout";

type SidebarButtonProps = {
  changeMobileSidebarVisibility: () => void;
};

const TopbarSidebarButton = ({
  changeMobileSidebarVisibility,
}: SidebarButtonProps) => {

  const dispatch = useDispatch()

  return (
    <div>
      <button
        className="topbar__button topbar__button--desktop"
        type="button"
        onClick={() => dispatch(onSidebarCollapse(""))}
      >
        <List className="topbar__button-icon" />
      </button>
      <button
        className="topbar__button topbar__button--mobile"
        type="button"
        onClick={changeMobileSidebarVisibility}
      >
        <List className="topbar__button-icon" />
      </button>
    </div>
  );
};

export default TopbarSidebarButton;
