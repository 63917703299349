import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import SwitchButton from "../../../../../shared/components/form/SwitchButton";
import { tempGanttRisks } from "../../../../../shared/tempData/ganttChartData";
import { ganttData } from "../../../../../shared/tempData/ganttData";
import RiskView from "../components/GanttChart/RiskView";
import TaskView from "../components/GanttChart/TaskView";

const GanttChartPage = () => {
  const [isRiskView, setIsRiskView] = useState(false);

  const onRiskViewChange = () => {
    setIsRiskView(!isRiskView);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="tab__header">
            <h4 className="tab__header--title">Gantt Chart</h4>
            <p className="tab__header--view">
              <div className="switch__wrap" style={{ marginRight: "20px" }}>
                <p>Risk View</p>
                <SwitchButton
                  name="gantt__risk-view"
                  small
                  id="gantt__risk-view"
                  onChange={onRiskViewChange}
                  checked={isRiskView}
                />
              </div>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="gantt-chart__page">
          {isRiskView ? (
            <RiskView data={tempGanttRisks} />
          ) : (
            <TaskView data={ganttData} />
          )}
        </div>
      </Row>
    </Container>
  );
};

export default GanttChartPage;
