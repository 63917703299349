import { NavLink } from "react-router-dom";

type SidebarLinkProps = {
  title: string;
  icon?: string;
  route: string;
  onClick?: () => void;
  exact?: boolean;
  dot?: boolean;
};

const SidebarLink = ({
  title,
  icon,
  route,
  onClick,
  exact,
  dot,
}: SidebarLinkProps) => (
  <NavLink
    to={route}
    onClick={onClick}
    exact={exact}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <i className={`sidebar__link-icon bi-${icon}`}></i> : ""}
      <p className={`sidebar__link-title ${dot && "dot"}`}>{title}</p>
    </li>
  </NavLink>
);

export default SidebarLink;
