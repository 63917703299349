import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { RenderField } from "../../shared/components/form/Field";
import RenderSelectField from "../../shared/components/form/Select";
import { Organisation } from "../../types/interfaces";
import {
  createOrganisation,
  onUpdateOrganisation,
  updateOrganisation,
} from "../../redux/pages/organisations";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import RenderFileInputField from "../../shared/components/form/FileInput";

const OrganisationsForm = () => {
  const dispatch = useDispatch();

  const countries = useSelector((state: RootState) => state.other.countries);
  const editMode = useSelector(
    (state: RootState) => state.organisations.editMode
  );
  const organisation = useSelector(
    (state: RootState) => state.organisations.organisation
  );

  const onSubmit = (data: any) => {
    const modifiedData = {
      ...data,
      addressCountryName: data.addressCountry?.label,
      addressCountryId: data.addressCountry?.value,
    };
    if (editMode) {
      dispatch(updateOrganisation(modifiedData.id, modifiedData));
      dispatch(onUpdateOrganisation("reset"));
    } else {
      dispatch(createOrganisation(modifiedData));
    }
  };

  const initialValues = editMode && {
    ...organisation,
    addressCountry: {
      value: organisation?.addressCountryId,
      label: organisation?.addressCountryName,
    },
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form className="form" onSubmit={handleSubmit}>
          <Col md={12}>
            <Row>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Organisation Name
                  </span>
                  <div className="form__form-group-field">
                    <Field name="name" component={RenderField} type="text" />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Lead Contact Phone Number
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="leadContactPhoneNumber"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Organisation Country
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="organisationCountry"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Lead Contact</span>
                  <div className="form__form-group-field">
                    <Field
                      name="leadContact"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Lead Contact Email
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="leadContactEmail"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Organisation Industry Sector
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="organisationIndustrySector"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Organisation Address
                </span>
                <div className="form__form-group-field">
                  <Field name="address" component={RenderField} type="text" />
                </div>
              </div>
            </Row>
            <Row>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Scoring Scheme</span>
                  <div className="form__form-group-field">
                    <Field
                      name="scoringScheme"
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Add Supporting Document
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="addSupportingDocument"
                      render={({ input, meta }) => (
                        <RenderFileInputField input={input} meta={meta} />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
          <ButtonToolbar style={{ marginBottom: "20px", marginLeft: "auto" }}>
            <Button type="submit" color="primary">
              SAVE
            </Button>
          </ButtonToolbar>
        </form>
      )}
    />
  );
};

export default OrganisationsForm;
