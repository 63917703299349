import { useState } from "react";
import { Field } from "react-final-form";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { RenderField } from "../../../shared/components/form/Field";
import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RenderSlider from "../../../shared/components/range_slider/Slider";
import RenderFileInputField from "../../../shared/components/form/FileInput";
import AssessmentSource from "../components/AssessmentSource";
import { WizzardPageType } from "../../../types/types";
import { InfoCircle } from "react-bootstrap-icons";

type ConsequenceProps = {
  consequenceNumber: number;
} & WizzardPageType;

const Consequence = ({
  consequenceNumber,
  values,
  mutators,
}: ConsequenceProps) => {
  const [linkConsequence, setLinkConsequence] = useState(false);

  const linkConsequenceHandler = (onChange: any) => {
    onChange();
    setLinkConsequence(!linkConsequence);
  };

  // Renders Prolongation Content depending on chosen value
  const prolongationContent = () => {
    switch (values[`consequenceProlongationCost${consequenceNumber}`]?.value) {
      case "resource":
        return (
          <Row>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Resource Daily Cost (£)
                </span>
                <Field
                  name={`consequenceResourceDailyCost${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={1000}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        140: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        150: "£0 - £150k",
                        290: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        300: "£150k - £300k",
                        490: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        500: "£300k - £500k",
                        690: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        700: "£500k - £750k",
                        990: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        1000: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "> £750k",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  External Cost Data Source
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`consequenceResourceDailyCostExternalCostDataSource${consequenceNumber}`}
                    render={({ input, meta }) => (
                      <RenderSelectField
                        input={input}
                        meta={meta}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        );
      case "sitePrelims":
        return (
          <Row>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Site Prelims Daily Cost (£)
                </span>
                <Field
                  name={`consequenceSitePrelimsDailyCost${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={1000}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        140: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        150: "£0 - £150k",
                        290: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        300: "£150k - £300k",
                        490: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        500: "£300k - £500k",
                        690: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        700: "£500k - £750k",
                        990: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        1000: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "> £750k",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  External Cost Data Source
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`consequenceSitePrelimsDailyCostExternalCostDataSource${consequenceNumber}`}
                    render={({ input, meta }) => (
                      <RenderSelectField
                        input={input}
                        meta={meta}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        );
      case "resourceSitePrelims":
        return (
          <Row>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Resource Daily Cost (£)
                </span>
                <Field
                  name={`consequenceResourceDailyCost${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={1000}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        140: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        150: "£0 - £150k",
                        290: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        300: "£150k - £300k",
                        490: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        500: "£300k - £500k",
                        690: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        700: "£500k - £750k",
                        990: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        1000: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "> £750k",
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Site Prelims Daily Cost (£)
                </span>
                <Field
                  name={`consequenceSitePrelimsDailyCost${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSlider
                      min={0}
                      max={1000}
                      value={input.value}
                      onChange={input.onChange}
                      marks={{
                        140: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        150: "£0 - £150k",
                        290: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        300: "£150k - £300k",
                        490: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        500: "£300k - £500k",
                        690: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        700: "£500k - £750k",
                        990: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        1000: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "> £750k",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  External Cost Data Source
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`consequenceResourceDailyCostExternalCostDataSource${consequenceNumber}`}
                    render={({ input, meta }) => (
                      <RenderSelectField
                        input={input}
                        meta={meta}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  External Cost Data Source
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`consequenceSitePrelimsDailyCostExternalCostDataSource${consequenceNumber}`}
                    render={({ input, meta }) => (
                      <RenderSelectField
                        input={input}
                        meta={meta}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="form-title">{`Consequence ${consequenceNumber}`}</div>
      {/* <form className="form" onSubmit={submit}> */}
      <Col md={12}>
        <Row>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link an existing record as a Consequence?
              </span>
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {linkConsequence ? "Yes" : "No"}
                </span>
                <Field
                  name={`consequenceLink${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <SwitchButton
                      id={input.name}
                      name={input.name}
                      checked={input.checked}
                      onChange={() => linkConsequenceHandler(input.onChange)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">Linked Record</span>
              <div className="form__form-group-field">
                <Field
                  name={`consequenceLinkedRisk${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      disabled={!linkConsequence}
                      meta={meta}
                      placeholder="Linked Risk"
                      options={[
                        { value: "1", label: "T-0074 - COVID-19 Impact" },
                        {
                          value: "2",
                          label: "T-0057 - Non-Productive Contractors",
                        },
                        {
                          value: "3",
                          label: "T-0060 - Below Ground Obstructions",
                        },
                        { value: "4", label: "T-0069 - Utility strike" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">Consequence Title</span>
              <div className="form__form-group-field">
                <div className="form__form-group-field">
                  <Field
                    name={`consequenceTitle${consequenceNumber}`}
                    disabled={linkConsequence}
                    component={RenderField}
                    placeholder="Consequence Title"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Consequence Description
            </span>
            <div className="form__form-group-field">
              <Field
                name={`consequenceDescription${consequenceNumber}`}
                component="textarea"
                disabled={linkConsequence}
                type="text"
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Degree Of Correlation
                <UncontrolledTooltip target="correlationInfo">
                  If the Risk impacts, how likely is it that this Consequence
                  will happen?
                </UncontrolledTooltip>
                <InfoCircle
                  id="correlationInfo"
                  className="form__form-group-info"
                />
              </span>
              <Field
                name={`consequenceDegreeOfCorrelation${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Cost Impact</span>
              <Field
                name={`consequenceCostImpact${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={1000}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      140: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      150: "£0 - £150k",
                      290: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      300: "£150k - £300k",
                      490: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      500: "£300k - £500k",
                      690: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      700: "£500k - £750k",
                      990: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      1000: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> £750k",
                      },
                    }}
                  />
                )}
              />
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Schedule Impact</span>
              <Field
                name={`consequenceScheduleImpact${consequenceNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1 - 15 days",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16 - 30 days",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31 - 50 days",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51 - 75 days",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> 75 days",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                External Cost Data Source
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`consequenceConstImpactExternalCostDataSource${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Impacted Task ID/s</span>
              <div className="form__form-group-field">
                <Field
                  name={`consequenceTaskId${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "T-0043" },
                        { value: "2", label: "T-0039" },
                        { value: "3", label: "T-0032" },
                        { value: "4", label: "T-0054" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Prolongation Cost</span>
              <div className="form__form-group-field">
                <Field
                  name={`consequenceProlongationCost${consequenceNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "resource", label: "Resource Only" },
                        { value: "sitePrelims", label: "Site Prelims Only" },
                        {
                          value: "resourceSitePrelims",
                          label: "Resource & Site Prelims",
                        },
                        { value: "notApplicable", label: "Not Applicable" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        {prolongationContent()}
        <AssessmentSource
          pageTitle="consequence"
          parentNumber={consequenceNumber}
          values={values}
          mutators={mutators}
        />
      </Col>
      {/* </form> */}
    </>
  );
};

export default Consequence;
