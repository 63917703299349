import { FieldMetaState } from "react-final-form";

type FieldProps = {
  input: any;
  placeholder: string;
  type: string;
  disabled?: boolean;
  classes?: string;
  meta: FieldMetaState<any>;
};

export const RenderField = ({
  input,
  placeholder,
  disabled,
  classes,
  type,
  meta,
}: FieldProps) => {
  return (
    <div className="form__form-group-input-wrap">
      <input
        {...input}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        className={classes}
      />
      {meta?.touched && meta?.error && (
        <span className="form__form-group-error">{meta?.error}</span>
      )}
    </div>
  );
};
