import { Field, Form } from "react-final-form";
import { Lock } from "@material-ui/icons";
import { Settings } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { RenderField } from "../../../shared/components/form/Field";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import { IUserInvitation } from "../../../types/interfaces";
import { createUserInvitation } from "../../../redux/pages/userManagement";
import RenderSelectField from "../../../shared/components/form/Select";

interface UserInvitationProps {
  toggleModal: () => void;
}

const UserInvitation = ({ toggleModal }: UserInvitationProps) => {
  const dispatch = useDispatch();

  const onSubmit = (data: IUserInvitation) => {
    const modifiedData = {
      ...data,
      organisationId: "494328fb-af53-4957-bd28-697bbfdb50de"
    };
    dispatch(createUserInvitation(modifiedData));
  };

  return (
    <div className="user-invitation">
      <Form
        onSubmit={onSubmit}
        mutators={{
          setReader: (args, state, utils) => {
            utils.changeValue(
              state,
              "applicationRoleId",
              () => "AFEA88FC-6BC0-4DB2-B602-4BEAD78F859F"
            );
          },
          setUser: (args, state, utils) => {
            utils.changeValue(
              state,
              "applicationRoleId",
              () => "AE15435E-82EB-4A10-9073-EFE5FFE151D0"
            );
          },
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="form">
            <Col md={12}>
              <Row>
                <h4>USER DETAILS - User can change the details later</h4>
              </Row>
              <Row>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Name<span>*</span>
                    </span>
                    <div className="form__form-group-field">
                      <Field name="name" component={RenderField} type="text" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <Field
                        name="emailAddress"
                        component={RenderField}
                        type="email"
                      />
                    </div>
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Surname</span>
                    <div className="form__form-group-field">
                      <Field
                        name="surname"
                        component={RenderField}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Phone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phone"
                        component={RenderField}
                        type="phone"
                      />
                    </div>
                  </div>
                </div>
              </Row>

              <Row>
                <div className="form__form-group">
                  <span className="form__form-group-label">Message</span>
                  <div className="form__form-group-field">
                    <Field
                      name="message"
                      type="textarea"
                      component="textarea"
                    />
                  </div>
                </div>
              </Row>

              <div className="user-invitation__permissions">
                <h4>
                  USER PERMISSIONS <span>*</span>:
                </h4>

                <Field
                  name="applicationRoleId"
                  value="AE15435E-82EB-4A10-9073-EFE5FFE151D0"
                  type="radio"
                  render={({ input, meta }) => (
                    <div
                      className={`permissions__option ${
                        input.checked && "active"
                      }`}
                      onClick={form.mutators.setUser}
                    >
                      <input type="radio" {...input} />
                      <Settings className="permisstions__options--icon" />
                      <div className="permisstions__options--text">
                        <h5>Invite with Full Access</h5>
                        <p>
                          Allow full access to everything. Users can create new
                          and edit existing projects and risks.
                        </p>
                      </div>
                    </div>
                  )}
                />
                <Field
                  name="applicationRoleId"
                  value="AFEA88FC-6BC0-4DB2-B602-4BEAD78F859F"
                  type="radio"
                  render={({ input, meta }) => (
                    <div
                      className={`permissions__option ${
                        input.checked && "active"
                      }`}
                      onClick={form.mutators.setReader}
                    >
                      <input type="radio" {...input} />
                      <Lock className="permisstions__options--icon" />
                      <div className="permisstions__options--text">
                        <h5>Invite with Read-Only Access</h5>
                        <p>Allow Read-Only access to everything.</p>
                      </div>
                    </div>
                  )}
                />
              </div>

              <Row>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Programmes</span>
                    <div className="form__form-group-field">
                      <Field
                        name="programmes"
                        render={({ input, meta }) => (
                          <RenderSelectField
                            input={input}
                            meta={meta}
                            placeholder=""
                            // options={countries.map((c) => {
                            //   return { value: c.id, label: c.name };
                            // })}
                            options={[
                              { value: "prog1", label: "Programme XYZ" },
                              { value: "prog2", label: "Programme ZYX" },
                              { value: "prog3", label: "Programme YXZ" },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Projects</span>
                    <div className="form__form-group-field">
                      <Field
                        name="projects"
                        render={({ input, meta }) => (
                          <RenderSelectField
                            input={input}
                            meta={meta}
                            placeholder=""
                            // options={countries.map((c) => {
                            //   return { value: c.id, label: c.name };
                            // })}
                            options={[
                              { value: "proj1", label: "Project XYZ" },
                              { value: "proj2", label: "Project ZYX" },
                              { value: "proj3", label: "Project YXZ" },
                            ]}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Row>
            </Col>

            <ButtonToolbar>
              <Button
                onClick={() => toggleModal()}
                type="button"
                color="primary"
                outline
              >
                Cancel
              </Button>
              <Button color="primary">Submit</Button>
            </ButtonToolbar>
          </form>
        )}
      ></Form>
    </div>
  );
};

export default UserInvitation;
