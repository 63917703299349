import { useMemo, useState } from "react";
import { Filter, ChevronDown } from "react-bootstrap-icons";
import {
  Column,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  PaginationItem,
  PaginationLink,  
} from "reactstrap";

import { SearchInput } from "./SearchInput";

interface DataType {
  id: number;
}

interface DraggableTableProps {
  tableHeaders: Column<any>[];
  tableData: DataType[];
  title?: string;
  children?: React.ReactChild;
  menu?: React.ReactElement;
}

type Reorder = (rows: any, startIndex: number, endIndex: number) => any;

const reorder: Reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const DraggableTable = ({
  tableHeaders,
  tableData,
  title,
  children,
  menu
}: DraggableTableProps) => {
  const [rows, setRows] = useState(tableData);

  const updateDraggableData = (result: any) => {
    const items = reorder(rows, result.source.index, result.destination.index);
    setRows(items);
  };

  const columns = useMemo(() => tableHeaders, []);
  const data = useMemo(() => rows, [rows]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    updateDraggableData(result);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;
  const { pageIndex, pageSize } = state;

  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  return (
    <div className="table__container">
      <div className="table__header">
        <h4>{title}</h4>
        <div className="table__header--left">
          <SearchInput filter={globalFilter} setFilter={setGlobalFilter} />
          {children}
        </div>
      </div>
      <div className="table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <Filter className="th-badge" />
                  </th>
                ))}
                <th>More</th>
              </tr>
            ))}
          </thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="draggable" type="data">
              {(provided) => (
                <tbody
                  {...getTableBodyProps()}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <Draggable
                        draggableId={`${row.id}`}
                        key={row.id}
                        index={Number(row.id)}
                      >
                        {(provide) => (
                          <tr
                            {...row.getRowProps()}
                            ref={provide.innerRef}
                            {...provide.draggableProps}
                            {...provide.dragHandleProps}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                            <td>
                              {menu}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      <div className="table__footer">
        <div className="table__footer-count">
          Page {pageIndex + 1} of {pageOptions.length}
        </div>
        <div className="table__footer-pages">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          {arrayPageIndex.map((i) => (
            <PaginationItem
              active={pageIndex === i}
              key={i}
              className="pagination__item"
            >
              <PaginationLink
                key={i}
                type="button"
                onClick={() => gotoPage(i)}
                className="pagination__link"
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
        </div>
        <div className="table__footer-results">
          <span>Results on page</span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
