import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Card, CardBody } from "reactstrap";
import { getCountries } from "../../redux/other";
import { getOrganisations } from "../../redux/pages/organisations";

import OrganisationsForm from "./OrganisationsForm";
import OrganisationsTable from "./OrganisationsTable";

const Organisations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganisations());
    dispatch(getCountries());
  }, []);

  return (
    <Container>
      <h3>Organisations</h3>
      <Card>
        <CardBody>
          <OrganisationsForm />
        </CardBody>
      </Card>
      <OrganisationsTable />
    </Container>
  );
};

export default Organisations;
