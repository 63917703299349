import React, { useState } from "react";
import { Image } from "react-bootstrap-icons";
import { Field, Form } from "react-final-form";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { useDispatch, useSelector } from "react-redux";

import { RenderField } from "../../../shared/components/form/Field";
import RenderMultiSelectField from "../../../shared/components/form/MultiSelect";
import ModalContainer from "../../../shared/components/ModalContainer";
import { RootState } from "../../../redux/store";
import { onAddPostToggle } from "../../../redux/formModals";
import SwitchButton from "../../../shared/components/form/SwitchButton";

const AddPost = () => {
  const addPostOpen = useSelector(
    (state: RootState) => state.modals.addPostOpen
  );

  const [postAnonymously, setPostAnonymously] = useState(false);

  const onPostAnonymouslyHandler = (onChange: any) => {
    onChange();
    setPostAnonymously(!postAnonymously);
  };

  const dispatch = useDispatch();

  const onSubmit = (values: any) => {
    console.log(values);
    dispatch(onAddPostToggle());
  };

  return (
    <ModalContainer
      isOpen={addPostOpen}
      title="Add Post"
      size="xl"
      toggle={() => dispatch(onAddPostToggle())}
    >
      <div className="risk-form__container">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Anonymous Posting
                      </span>
                      <div className="form__switch-group">
                        <span className="form__switch-group-label">
                          {postAnonymously ? "Yes" : "No"}
                        </span>
                        <Field
                          name="postAnonymously"
                          render={({ input, meta }) => (
                            <SwitchButton
                              id={input.name}
                              name={input.name}
                              small
                              checked={input.checked}
                              onChange={() =>
                                onPostAnonymouslyHandler(input.onChange)
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Title <span>*</span>
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="postTitle"
                          component={RenderField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Choose Categories
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="postCategories"
                          render={({ input, meta }) => {
                            return (
                              // <RenderSelectField
                              //   input={input}
                              //   meta={meta}
                              //   placeholder="Choose Categories"
                              //   options={[
                              //     {
                              //       value: "David Smith",
                              //       label: "David Smith",
                              //     },
                              //     {
                              //       value: "Ian Stewart",
                              //       label: "Ian Stewart",
                              //     },
                              //     {
                              //       value: "Renata Jordan",
                              //       label: "Renata Jordan",
                              //     },
                              //     { value: "Mia Smith", label: "Mia Smith" },
                              //     {
                              //       value: "Adele Stewart",
                              //       label: "Adele Stewart",
                              //     },
                              //   ]}
                              // />
                              <RenderMultiSelectField
                                input={input}
                                meta={meta}
                                options={[
                                  {
                                    value: "All Risk Team Members",
                                    label: "All Risk Team Members",
                                  },
                                  {
                                    value:
                                      "Station Portfolio Risk Team Members",
                                    label:
                                      "Station Portfolio Risk Team Members",
                                  },
                                  {
                                    value: "All Users",
                                    label: "All Users",
                                  },
                                  {
                                    value: "Industry Best Practice",
                                    label: "Industry Best Practice",
                                  },
                                  {
                                    value: "Peer Reviews",
                                    label: "Peer Reviews",
                                  },
                                  {
                                    value: "Social",
                                    label: "Social",
                                  },
                                  {
                                    value: "Concerns",
                                    label: "Concerns",
                                  },
                                ]}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Message</span>
                      <div className="form__form-group-field">
                        <Field
                          name="postMessage"
                          component="textarea"
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ButtonToolbar>
                      <Button type="button" color="primary">
                        <Image /> ADD ATTACHMENT
                      </Button>
                    </ButtonToolbar>
                  </Col>
                  <Col>
                    <ButtonToolbar style={{ float: "right" }}>
                      <Button type="button" color="secondary">
                        SAVE DRAFT
                      </Button>
                      <Button type="submit" color="primary">
                        <SendOutlinedIcon /> PUBLISH
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Col>
            </form>
          )}
        />
      </div>
    </ModalContainer>
  );
};

export default AddPost;
