import classNames from "classnames";
import ReactDOM from "react-dom";
import { FC, useState } from "react";
import {
  ganttDurationInDays,
  ganttStartingDay,
} from "../../../../../../shared/utilities";
import { useMousePosition } from "../../../../../../shared/hooks";
import {
  IGanttProject,
  IGanttRisk,
  IGanttTask,
} from "../../../../../../types/interfaces";

interface DurationBarProps {
  isRisk?: boolean;
  style?: any;
  item: IGanttProject | IGanttTask;
}

const DurationBar: FC<DurationBarProps> = ({ isRisk, style, item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { start, end, riskDuration, risks } = item;

  const durationBarClasses = classNames({
    "gantt__schedule-block": true,
    risk: isRisk,
  });

  return (
    <>
      <div
        className={durationBarClasses}
        style={{
          width: `${
            isRisk ? riskDuration * 70 : ganttDurationInDays(end, start) * 70
          }px`,
          left: `${
            isRisk ? 70 * ganttStartingDay(end) : 70 * ganttStartingDay(start)
          }px`,
          ...style,
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {isOpen && risks && <GanttTooltip isOpen={isOpen} risks={risks} />}
      </div>
    </>
  );
};

export default DurationBar;

type GanttTooltipProps = {
  isOpen: boolean;
  risks?: IGanttRisk[];
};

const GanttTooltip: FC<GanttTooltipProps> = ({ isOpen, risks }) => {
  const { x, y } = useMousePosition();

  const durationTooltipClasses = classNames({
    "gantt__schedule-tooltip": true,
    open: isOpen,
  });
  return ReactDOM.createPortal(
    <div
      className={durationTooltipClasses}
      style={{ left: `${x - 390}px`, top: `${y}px` }}
    >
      <div className="gantt__tooltip-container">
        <table>
          <thead>
            <th>Risk ID</th>
            <th>Risk Title</th>
            <th>Description</th>
          </thead>
          <tbody>
            {risks?.map((risk) => {
              return (
                <tr>
                  <td>{risk.id}</td>
                  <td>{risk.title}</td>
                  <td>{risk.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>,
    document.body
  );
};
