import { useSelector } from "react-redux";
import { Progress, Table, UncontrolledTooltip } from "reactstrap";
import { RootState } from "../../../../../redux/store";
import {
  fullPriceMask,
  priceMask,
  splitTime,
} from "../../../../../shared/utilities";

interface RiskBreakdownBarTooltipProps {
  schedule: boolean;
  label: string;
}

function RiskBreakdownBarTooltip({
  label,
  schedule,
}: RiskBreakdownBarTooltipProps) {
  const { risksList, opportunitiesList, threatsList } = useSelector(
    (state: RootState) => state.risks
  );
  const { scheduleRisk, isOpportunities } = useSelector(
    (state: RootState) => state.dashboard
  );

  const tempList = isOpportunities
    ? opportunitiesList?.filter(
        (risksList) => risksList.workPackageName === label
      )
    : threatsList?.filter((risksList) => risksList.workPackageName === label);

  const list = tempList?.sort((a, b) =>
    scheduleRisk
      ? Number(b.scheduleSuggestedLikely) - Number(a.scheduleSuggestedLikely)
      : Number(b.costSuggestedLikely) - Number(a.costSuggestedLikely)
  );

  return (
    <>
      <div className="tooltip-container">
        <h5>Key Risk Drivers</h5>
        <Table>
          <thead>
            <tr>
              <td>Risk ID</td>
              <td>Title</td>
              <td>{scheduleRisk ? "Schedule" : "Cost £(k)"}</td>
            </tr>
          </thead>
          <tbody>
            {list?.slice(0, 5).map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.identifier}</td>
                  <td>{row.title}</td>
                  <td>
                    {scheduleRisk
                      ? `${splitTime(Number(row.scheduleSuggestedLikely)).d}d ${
                          splitTime(Number(row.scheduleSuggestedLikely)).h
                        }h`
                      : priceMask(Number(row.costSuggestedLikely))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

type RiskBreakdownBar = {
  value: number;
  color: "blue" | "red" | "dblue" | "dred";
  amount: number;
  id: string;
  label: string;
  schedule: boolean;
};

function RiskBreakdownBar({
  value,
  color,
  amount,
  label,
  id,
  schedule,
}: RiskBreakdownBar) {
  return (
    <>
      <UncontrolledTooltip
        target={`bar${id}`}
        popperClassName="risk_tooltip"
        innerClassName="risk_tooltip-inner"
      >
        <RiskBreakdownBarTooltip label={label} schedule={schedule} />
      </UncontrolledTooltip>
      <div className={`progress__wrap ${color}`} id={`bar${id}`}>
        <div className="progress__wrap-info">
          <div className="progress__info-left">
            <div className="progress__left-amount">
              {schedule
                ? `${splitTime(amount).d}d ${splitTime(amount).h}h`
                : `£ ${fullPriceMask(amount)}`}
            </div>
            <div className="progress__left-label">{label}</div>
          </div>
          <div className="progress__info-right">{value}%</div>
        </div>
        <Progress value={value} />
      </div>
    </>
  );
}

export default RiskBreakdownBar;
