import { useState } from "react";
import { Collapse } from "reactstrap";

import TopbarMenuLink from "./TopbarMenuLink";
import { useMsal } from "@azure/msal-react";

const Ava = `${process.env.PUBLIC_URL}/img/ava.jpg`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { instance } = useMsal();
  
  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <img src={Ava} alt="avatar" className="topbar__avatar-img" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="My account" path="/" />
          <TopbarMenuLink title="My settings" path="/" />
          <div className="topbar__menu-divider" />
          <div
            className="topbar__link"
            onClick={() => instance.logoutRedirect()}
          >
            <span className="topbar__link-title">Logout</span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
