import { IRisk } from "../types/interfaces";
import api from "./api";

export const getRisksRequest = () => {
  return api.get(`/Risks`);
};

export const getRiskRequest = (id: string) => {
  return api.get(`/Risks/${id}`);
};

export const createRiskRequest = (body: IRisk) => {
  return api.post(`/Risks`, body);
};

export const updateRiskRequest = (id: string, body: any) => {
  return api.put(`/Risks/${id}`, { id, ...body });
};

export const deleteRiskRequest = (id: string) => {
  return api.delete(`/Risks/${id}`);
};
