import { useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Bar,
  Line,
  ReferenceLine,
} from "recharts";
import { MCGraphData } from "../../../../../files/MCGraphData";
import { RootState } from "../../../../../redux/store";
import { priceMask } from "../../../../../shared/utilities";

const MCGraph = () => {
  const test2 = [
    { pv: 8 },
    { pv: 31 },
    { pv: 61 },
    { pv: 99 },
    { pv: 132 },
    { pv: 172 },
    { pv: 170 },
    { pv: 131 },
    { pv: 98 },
    { pv: 48 },
    { pv: 3 },
    { pv: 17 },
    { pv: 7 },
    { pv: 5 },
    { pv: 2 },
  ];

  const test1 = [
    { rv: 4 },
    { rv: 9 },
    { rv: 12 },
    { rv: 22 },
    { rv: 26 },
    { rv: 58 },
    { rv: 80 },
    { rv: 83 },
    { rv: 78 },
    { rv: 140 },
    { rv: 98 },
    { rv: 90 },
    { rv: 68 },
    { rv: 70 },
    { rv: 74 },
    { rv: 40 },
    { rv: 20 },
    { rv: 25 },
    { rv: 16 },
    { rv: 10 },
    { rv: 10 },
    { rv: 8 },
    { rv: 5 },
    { rv: 2 },
    { rv: 7 },
    { rv: 4 },
  ];

  const graphType = useSelector(
    (state: RootState) => state.dashboard.monteCarloGraphType
  );

  const formattedYLabel = (value: any, min: number, max: number) => {
    const input = (value * (max - min)) / 100 + min;
    return `${value}% £${priceMask(input)}`;
  };

  const formattedXLabel = (
    value: any,
    index: number,
    min: number,
    max: number
  ) => {
    let data = graphType ? test1 : test2;
    if (index === data.length - 1) {
      return `${max}`;
    }

    if (index === Math.round(data.length / 2) - 1)
      return `${(50 * (max - min)) / 100 + min}`;
    console.log(data.length);
    return ``;
  };

  return (
    <ResponsiveContainer height={700}>
      <ComposedChart
        data={graphType ? test1 : test2}
        margin={{
          top: 20,
          right: 50,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          stroke="#074663"
          xAxisId={1}
          interval={0}
          tickLine={false}
          tickFormatter={(value, index) =>
            formattedXLabel(value, index, 24000000, 28000000)
          }
        />
        <XAxis
          stroke="#ff0000"
          hide={true}
          xAxisId={2}
          domain={[0, 1000]}
          markerHeight={20}
        />
        <YAxis stroke="#074663" yAxisId={1} dataKey={graphType ? "rv" : "pv"} />
        <YAxis
          yAxisId={2}
          type="number"
          dataKey="uv"
          name="percentile"
          tickCount={21}
          interval={0}
          width={130}
          tickMargin={10}
          domain={[0, 100]}
          orientation="right"
          stroke="#074663"
          tickFormatter={(value) => formattedYLabel(value, 24258155, 27803155)}
        />
        <Legend />
        <Bar
          xAxisId={1}
          dataKey={graphType ? "rv" : "pv"}
          yAxisId={1}
          barSize={20}
          fill={graphType ? "#c01f28" : "#0074d0"}
          color="#000000"
        />
        <Line
          data={MCGraphData}
          // type="monotone"
          xAxisId={2}
          yAxisId={2}
          dataKey="uv"
          stroke="#000000"
          dot={false}
        />
        <ReferenceLine
          segment={[
            { x: 999, y: 80 },
            { x: 667, y: 80 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
        <ReferenceLine
          segment={[
            { x: 667, y: 0 },
            { x: 667, y: 80 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
        <ReferenceLine
          segment={[
            { x: 999, y: 50 },
            { x: 500, y: 50 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
        <ReferenceLine
          segment={[
            { x: 500, y: 0 },
            { x: 500, y: 50 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
        <ReferenceLine
          segment={[
            { x: 999, y: 20 },
            { x: 330, y: 20 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
        <ReferenceLine
          segment={[
            { x: 330, y: 0 },
            { x: 330, y: 20 },
          ]}
          xAxisId={2}
          yAxisId={2}
          stroke="black"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default MCGraph;
