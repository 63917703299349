import { Field, useFormState } from "react-final-form";
import { Row, Col, ButtonToolbar, Button } from "reactstrap";
import { XLg } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";

import RenderFileInputField from "../../../shared/components/form/FileInput";
import RenderSelectField from "../../../shared/components/form/Select";
import { addActionSupportingDocument, addRiskSupportingDocument } from "../../../redux/risks";
import { WizzardPageType } from "../../../types/types";

type RiskDocumentProps = {
  isOpen: boolean;
  closeHandler: () => void;
  pageTitle: string;
} & typeof defaultProps &
  WizzardPageType;

const defaultProps = {
  isOpen: false,
};

const RiskDocument = ({
  isOpen,
  closeHandler,
  mutators,
  pageTitle,
}: RiskDocumentProps) => {
  const formState = useFormState();

  const dispatch = useDispatch();

  const saveHandler = () => {
    const toSubmit = {
      documentType: formState.values[`${pageTitle}DocumentType`],
      documentFile: {
        title: formState.values[`${pageTitle}DocumentFile`].file.name,
        date: formState.values[`${pageTitle}DocumentFile`].file
          .lastModifiedDate,
      },
      description: formState.values[`${pageTitle}DocumentDescription`],
      documentOwner: formState.values[`${pageTitle}DocumentOwner`],
      documentOwnerRole: formState.values[`${pageTitle}DocumentOwnerRole`],
    };
    if (pageTitle === "risk") {
      dispatch(addRiskSupportingDocument(toSubmit));
    } else {
      dispatch(addActionSupportingDocument(toSubmit));
    }
    closeHandler();
    mutators.clearSupportDocuments(pageTitle);
  };

  return (
    <div className={`risk__document-container ${isOpen && "open"}`}>
      <XLg className="risk__document-close" onClick={() => closeHandler()} />
      <Row>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Document Type</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentType`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    meta={meta}
                    options={[
                      { value: "surveys", label: "Surveys" },
                      {
                        value: "documentaryEvidence",
                        label: "Documentary Evidence",
                      },
                      {
                        value: "specialistOpinion",
                        label: "Specialist Opinion",
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Assessment Source</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentFile`}
                render={({ input, meta }) => (
                  <RenderFileInputField input={input} />
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentDescription`}
                component="textarea"
                type="text"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Document Owner</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentOwner`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    placeholder="Document Owner"
                    meta={meta}
                    options={[
                      { value: "David Smith", label: "David Smith" },
                      { value: "Ian Stewart", label: "Ian Stewart" },
                      { value: "Renata Jordan", label: "Renata Jordan" },
                      { value: "Mia Smith", label: "Mia Smith" },
                      { value: "Adele Stewart", label: "Adele Stewart" },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Document Owner Role</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentOwnerRole`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    placeholder="Document Owner Role"
                    meta={meta}
                    options={[
                      {
                        value: "Software Developer",
                        label: "Software Developer",
                      },
                      { value: "Designer", label: "Designer" },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <ButtonToolbar>
        <Button
          onClick={saveHandler}
          color="primary"
          style={{ marginLeft: "auto" }}
        >
          SAVE
        </Button>
      </ButtonToolbar>
    </div>
  );
};

RiskDocument.defaultProps = defaultProps;

export default RiskDocument;
