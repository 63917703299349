import { Column } from "react-table";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ChevronDown } from "react-bootstrap-icons";

import { BasicTable } from "../../../../../shared/components/table/BasicTable";
import topRisk from "../topRiskVariancesData.json";
import topOpportunity from "../topOpportunityVariancesData.json";
import { priceMask } from "../../../../../shared/utilities";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import ReactTooltip from "react-tooltip";

type MenuProps = {
  id: string;
};

const Menu = ({ id }: MenuProps) => {
  return (
    <UncontrolledDropdown direction="left">
      <DropdownToggle>
        <ChevronDown />
      </DropdownToggle>
      <DropdownMenu left>
        <DropdownItem>Edit</DropdownItem>
        <DropdownItem>View Details</DropdownItem>
        <DropdownItem>Close</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

type TopRiskVariancesTableProps = {
  isSchedule: boolean;
};

const TopRiskVariancesTable = ({ isSchedule }: TopRiskVariancesTableProps) => {
  const { isOpportunities } = useSelector(
    (state: RootState) => state.dashboard
  );

  const headers: Column<any>[] = [
    {
      Header: () => {
        return (
          <div
            style={{
              backgroundColor: "white",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0px",
              left: "0px",
              padding: "12px 20px",
              cursor: "default",
            }}
          ></div>
        );
      },
      accessor: "empty",
      columns: [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Title",
          accessor: "title",
          Cell: ({ value }) => {
            return (
              <div
                style={{
                  width: "100px",
                  whiteSpace: "normal",
                  inlineSize: "100px",
                }}
              >
                {value}
              </div>
            );
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <div
            style={{
              backgroundColor: "#C7E6FF",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0px",
              left: "0px",
              padding: "12px 20px",
              cursor: "default",
            }}
          >
            Last Reporting Period
          </div>
        );
      },
      accessor: " ",
      columns: [
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#C7E6FF",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                }}
              >
                Percentage
              </div>
            );
          },
          accessor: "last_percentage",
          Cell: ({ value }) => {
            const getText = () => {
              switch (value) {
                case "Very Low":
                  return <div style={{ color: "#777777" }}>(0% to 30%)</div>;
                case "Low":
                  return <div style={{ color: "#777777" }}>(30% to 50%)</div>;
                case "Medium":
                  return <div style={{ color: "#777777" }}>(30% to 50%)</div>;
                case "High":
                  return <div style={{ color: "#777777" }}>(50% to 70%)</div>;
                case "Very High":
                  return <div style={{ color: "#777777" }}>(70% to 100%)</div>;

                default:
                  return <></>;
              }
            };
            return (
              <>
                <div>{value}</div>
                {getText()}
              </>
            );
          },
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#C7E6FF",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                }}
              >
                Cost
              </div>
            );
          },
          accessor: "last_cost",
          Cell: ({ value }) => {
            return <span>£ {priceMask(value)}</span>;
          },
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#C7E6FF",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                }}
              >
                Time (days)
              </div>
            );
          },
          accessor: "last_time",
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#C7E6FF",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                }}
              >
                Score
              </div>
            );
          },
          accessor: "last_score",
          Cell: ({ value }) => {
            return <span style={{ fontWeight: "bolder" }}>{value}</span>;
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <div
            style={{
              backgroundColor: "#1E4E74",
              color: "white",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0px",
              left: "0px",
              padding: "12px 20px",
              cursor: "default",
              borderBottom: "1px solid white",
            }}
          >
            This Reporting Period
          </div>
        );
      },
      accessor: "ThisReportingPeriod",
      columns: [
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#1E4E74",
                  color: "white",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                  borderBottom: "1px solid white",
                }}
              >
                Percentage
              </div>
            );
          },
          accessor: "this_percentage",
          Cell: ({ value }) => {
            const getText = () => {
              switch (value) {
                case "Very Low":
                  return (
                    <div style={{ color: "#c01f28", fontWeight: "bolder" }}>
                      <div>{value}</div>
                      <div>(0% to 20%)</div>
                    </div>
                  );
                case "Low":
                  return (
                    <div style={{ color: "#c01f28", fontWeight: "bolder" }}>
                      <div>{value}</div>
                      <div>(20% to 40%)</div>
                    </div>
                  );
                case "Medium":
                  return (
                    <div style={{ color: "#0074d0", fontWeight: "bolder" }}>
                      <div>{value}</div>
                      <div>(40% to 60%)</div>
                    </div>
                  );
                case "High":
                  return (
                    <div style={{ color: "#009688", fontWeight: "bolder" }}>
                      <div>{value}</div>
                      <div>(60% to 80%)</div>
                    </div>
                  );
                case "Very High":
                  return (
                    <div style={{ color: "#009688", fontWeight: "bolder" }}>
                      <div>{value}</div>
                      <div>(80% to 100%)</div>
                    </div>
                  );

                default:
                  return <></>;
              }
            };
            return <>{getText()}</>;
          },
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#1E4E74",
                  color: "white",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                  borderBottom: "1px solid white",
                }}
              >
                Cost
              </div>
            );
          },
          accessor: "this_cost",
          Cell: ({ value }) => {
            return <span>£ {priceMask(value)}</span>;
          },
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#1E4E74",
                  color: "white",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                  borderBottom: "1px solid white",
                }}
              >
                Time (days)
              </div>
            );
          },
          accessor: "this_time",
        },
        {
          Header: () => {
            return (
              <div
                style={{
                  backgroundColor: "#1E4E74",
                  color: "white",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  padding: "12px 20px",
                  cursor: "default",
                  borderBottom: "1px solid white",
                }}
              >
                Score
              </div>
            );
          },
          accessor: "this_score",
          Cell: ({ value }) => {
            const getRandom = () => {
              const number = Math.floor(Math.random() * 2);
              return number === 0 ? (
                <>
                  <div
                    className="triangle-up"
                    style={{ marginRight: "20px" }}
                  />
                  <span style={{ color: "#c01f28", fontWeight: "bold" }}>
                    {value}
                  </span>
                </>
              ) : (
                <>
                  <div
                    className="triangle-down"
                    style={{ marginRight: "20px" }}
                  />
                  <span style={{ color: "#009688", fontWeight: "bold" }}>
                    {value}
                  </span>
                </>
              );
            };
            return <span>{getRandom()}</span>;
          },
        },
      ],
    },
    {
      Header: "Variance Substantiation",
      columns: [
        {
          Header: "Variance Driver",
          accessor: "v_driver",
        },
        {
          Header: "Variance Comment",
          accessor: "v_comment",
          Cell: ({ value }) => {
            return (
              <div data-tip={value} className="overflow">
                {value}
              </div>
            );
          },
        },
        {
          Header: "More",
          accessor: "modal",
          Cell: ({ row }) => {
            return <Menu id={row.original.id} />;
          },
        },
      ],
    },
  ];

  return (
    <>
      <BasicTable
        tableHeaders={headers}
        tableData={isOpportunities ? topOpportunity : topRisk}
      />
      <ReactTooltip place="top" type="dark" effect="solid" multiline={true} />
    </>
  );
};

export default TopRiskVariancesTable;
