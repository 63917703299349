import { Task } from "gantt-task-react";
import { IGanttProject } from "../../types/interfaces";

const currentDate = new Date();

export const tempGanttRisks: IGanttProject[] = [
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Wall Collapse",
    id: "R-0022",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
        name: "Dig Foundations",
        id: "T-0023",
        type: "task",
        riskDuration: 3,
      },
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
        name: "Install Supports",
        id: "T-0024",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Plant Availability",
    id: "R-0023",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
        name: "Dig Foundations",
        id: "T-0023",
        type: "task",
        riskDuration: 3,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Resource Availability",
    id: "R-0024",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
        name: "Dig Foundations",
        id: "T-0023",
        type: "task",
        riskDuration: 3,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Insufficient Loading",
    id: "R-0025",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
        name: "Install Supports",
        id: "T-0024",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Plant Failure",
    id: "R-0026",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 6),
        name: "Install Supports",
        id: "T-0024",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Concrete Test Failure",
    id: "R-0027",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
        name: "Pour Concrete",
        id: "T-0025",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Logistics",
    id: "R-0028",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
        name: "Pour Concrete",
        id: "T-0025",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Concrete Spillage",
    id: "R-0029",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
        name: "Pour Concrete",
        id: "T-0025",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Late Delivery",
    id: "R-0030",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 12),
        name: "Glazing",
        id: "T-0026",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Glass Breakage",
    id: "R-0031",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 12),
        name: "Glazing",
        id: "T-0026",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Incorrect Measurements",
    id: "R-0032",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 12),
        name: "Glazing",
        id: "T-0026",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Finishing Plant availability",
    id: "R-0033",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 14),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 16),
        name: "Finish Surface",
        id: "T-0027",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Surface Failures",
    id: "R-0034",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 14),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 16),
        name: "Finish Surface",
        id: "T-0027",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Damage to Finished Surface",
    id: "R-0035",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 14),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 16),
        name: "Finish Surface",
        id: "T-0027",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Power Outage",
    id: "R-0036",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 17),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
        name: "Install Electrical Supply",
        id: "T-0028",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Testing Equipment Availability",
    id: "R-0037",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 17),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
        name: "Install Electrical Supply",
        id: "T-0028",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Testing Resources Constraints",
    id: "R-0038",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 17),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
        name: "Install Electrical Supply",
        id: "T-0028",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Testing Resources Constraints",
    id: "R-0039",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 21),
        name: "Install Plumbing",
        id: "T-0029",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Past Availability",
    id: "R-0040",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 21),
        name: "Install Plumbing",
        id: "T-0029",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Site Congestion",
    id: "R-0041",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 21),
        name: "Install Plumbing",
        id: "T-0029",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Permissions Refused",
    id: "R-0042",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24),
        name: "Secure Permissions",
        id: "T-0030",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Permissions Granted with Conditions",
    id: "R-0043",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24),
        name: "Secure Permissions",
        id: "T-0030",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Incorrect Information Submitted",
    id: "R-0044",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 23),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24),
        name: "Secure Permissions",
        id: "T-0030",
        type: "task",
        riskDuration: 2,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Specialist Resource Availability",
    id: "R-0045",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 26),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 28),
        name: "Procure Resource",
        id: "T-0031",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Increased Resource Costs",
    id: "R-0046",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 26),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 28),
        name: "Procure Resource",
        id: "T-0031",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Competing Projects",
    id: "R-0047",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 26),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 28),
        name: "Procure Resource",
        id: "T-0031",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Critical Plant Availability",
    id: "R-0048",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 29),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
        name: "Hire Plant",
        id: "T-0032",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Plant Breakdown",
    id: "R-0049",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 29),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
        name: "Hire Plant",
        id: "T-0032",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 3),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    name: "Plant Parts Availability",
    id: "R-0050",
    type: "risk",
    riskDuration: 1,
    tasks: [
      {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 29),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 30),
        name: "Hire Plant",
        id: "T-0032",
        type: "task",
        riskDuration: 1,
      },
    ],
  },
];
