import { useState } from "react";
import { X } from "react-bootstrap-icons";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { RootState } from "../../../redux/store";
import RenderDatePickerField from "../../../shared/components/form/DatePicker";
import { RenderField } from "../../../shared/components/form/Field";
import RenderSelectField from "../../../shared/components/form/Select";
import SwitchButton from "../../../shared/components/form/SwitchButton";
import RenderSlider from "../../../shared/components/range_slider/Slider";
import { WizzardPageType } from "../../../types/types";
import RiskDocument from "../components/RiskDocument";

type MitigationProps = {
  mitigationNumber: number;
} & WizzardPageType;

const Mitigation = ({
  mitigationNumber,
  mutators,
  values,
}: MitigationProps) => {
  const supportingDocuments = useSelector(
    (state: RootState) => state.risks.actionSupportingDocuments
  );

  const [linkMitigation, setLinkMitigation] = useState(false);
  const [documentIsOpen, setDocumentIsOpen] = useState(false);

  const linkMitigationHandler = (onChange: any) => {
    onChange();
    setLinkMitigation(!linkMitigation);
  };

  const onDocumentOpen = () => {
    setDocumentIsOpen(true);
  };

  const onDocumentClose = () => {
    setDocumentIsOpen(false);
  };

  const mitigationScoreColorHandler = (value: string) => {
    let result;
    const score = Number(value.split(" ")[0]);
    if (score <= 5) {
      result = `threat vlow`;
    } else if (score > 5 && score <= 10) {
      result = `threat low`;
    } else if (score > 10 && score <= 15) {
      result = `threat medium`;
    } else if (score > 15 && score <= 20) {
      result = `threat high`;
    } else if (score > 20 && score <= 25) {
      result = `threat vhigh`;
    }
    return result;
  };

  return (
    <>
      <div className="form-title">{`RESPONSE ACTION ${mitigationNumber}`}</div>
      <Col md={12}>
        <Row>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link Response Action?
              </span>
              <div className="form__switch-group">
                <span className="form__switch-group-label">
                  {linkMitigation ? "Yes" : "No"}
                </span>
                <Field
                  name={`mitigationLink${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <SwitchButton
                      id={input.name}
                      name={input.name}
                      checked={input.checked}
                      onChange={() => linkMitigationHandler(input.onChange)}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link to an existing Record
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationLinkedRisk${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      disabled={!linkMitigation}
                      meta={meta}
                      placeholder="Linked Risk"
                      options={[
                        { value: "1", label: "T-0074 - COVID-19 Impact" },
                        {
                          value: "2",
                          label: "T-0057 - Non-Productive Contractors",
                        },
                        {
                          value: "3",
                          label: "T-0060 - Below Ground Obstructions",
                        },
                        { value: "4", label: "T-0069 - Utility strike" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link an existing Response Action
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationLinkAction${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      disabled={!linkMitigation}
                      meta={meta}
                      placeholder="Response Actions"
                      options={[
                        { value: "1", label: "T-0074 - COVID-19 Impact" },
                        {
                          value: "2",
                          label: "T-0057 - Non-Productive Contractors",
                        },
                        {
                          value: "3",
                          label: "T-0060 - Below Ground Obstructions",
                        },
                        { value: "4", label: "T-0069 - Utility strike" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Row>
            <Col md={6} xs={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Response Action Title
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`mitigationTitle${mitigationNumber}`}
                    component={RenderField}
                    disabled={linkMitigation}
                    placeholder="Action Title"
                  />
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Response Action ID
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`mitigationAssignedId${mitigationNumber}`}
                    disabled={true}
                    component={RenderField}
                    type="text"
                  />
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Score
                </span>
                <div className="form__form-group-field">
                  <Field
                    name={`mitigationScore${mitigationNumber}`}
                    render={({ input, meta }) => {
                      return (
                        <RenderField
                          input={input}
                          disabled={true}
                          classes={mitigationScoreColorHandler(input.value)}
                          meta={meta}
                          type="text"
                          placeholder=""
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Response Action Description
            </span>
            <div className="form__form-group-field">
              <Field
                name={`mitigationDescription${mitigationNumber}`}
                component="textarea"
                disabled={linkMitigation}
                type="text"
              />
            </div>
          </div>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Response Action Owner <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwner${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "David Smith", label: "David Smith" },
                        { value: "Ian Stewart", label: "Ian Stewart" },
                        { value: "Renata Jordan", label: "Renata Jordan" },
                        { value: "Mia Smith", label: "Mia Smith" },
                        { value: "Adele Stewart", label: "Adele Stewart" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Status <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationStatus${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "Open" },
                        { value: "2", label: "Closed" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Pre/Post Impact Action
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationImpact${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "Cause 1" },
                        { value: "2", label: "Cause 2" },
                        { value: "3", label: "Cause 3" },
                        { value: "4", label: "Consequence 1" },
                        { value: "5", label: "Consequence 2" },
                        { value: "6", label: "Consequence 3" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <Row>
              <h4 className="mitigations__subtitle">
                Response Action Effectiveness
              </h4>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Probability After Response Action
              </span>
              <Field
                name={`mitigationImpactProbability${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Cost Impact After Response Action
              </span>
              <Field
                name={`mitigationImpactCost${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={1000}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      140: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      150: "£0 - £150k",
                      290: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      300: "£150k - £300k",
                      490: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      500: "£300k - £500k",
                      690: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      700: "£500k - £750k",
                      990: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      1000: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> £750k",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Schedule Impact After Response Action
              </span>
              <Field
                name={`mitigationImpactSchedule${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1 - 15 days",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16 - 30 days",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31 - 50 days",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51 - 75 days",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> 75 days",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Response Action Owner Role
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwnerRole${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "Project Manager", label: "Project Manager" },
                        {
                          value: "Work Package Manager",
                          label: "Work Package Manager",
                        },
                        {
                          value: "Quantity Surveyor",
                          label: "Quantity Surveyor",
                        },
                        {
                          value: "Commercial Manager",
                          label: "Commercial Manager",
                        },
                        { value: "Cost Manager", label: "Cost Manager" },
                        {
                          value: "Planning Manager",
                          label: "Planning Manager",
                        },
                        { value: "QHSSE Manager", label: "QHSSE Manager" },
                        {
                          value: "Environmental Manager",
                          label: "Environmental Manager",
                        },
                        { value: "Change Manager", label: "Change Manager" },
                        { value: "Designer", label: "Designer" },
                        {
                          value: "Software Developer",
                          label: "Software Developer",
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Response Action Owner Organisation <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name={`mitigationOwnerOrganisation${mitigationNumber}`}
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "1", label: "Employer" },
                        { value: "2", label: "Contractor" },
                        { value: "3", label: "Third Party" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Target Completion Date
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="mitigationTargetCompletionDate"
                      render={({ input }) => (
                        <RenderDatePickerField input={input} />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Actual Completion Date
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="mitigationActualCompletionDate"
                      render={({ input }) => (
                        <RenderDatePickerField input={input} />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <h4 className="mitigations__subtitle">Ease of Implementation</h4>
            </Row>

            <div className="form__form-group">
              <span className="form__form-group-label">Level of Effort</span>
              <Field
                name={`mitigationScoreEffort${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1% - 15%",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16% - 30%",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31% - 50%",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51% - 75%",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "76%-99%",
                      },
                    }}
                  />
                )}
              />
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                Cost to Implement (£)
              </span>
              <Field
                name={`mitigationEffectivenessCost${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={1000}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      140: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      150: "£0 - £150k",
                      290: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      300: "£150k - £300k",
                      490: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      500: "£300k - £500k",
                      690: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      700: "£500k - £750k",
                      990: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      1000: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> £750k",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Time to Implement</span>
              <Field
                name={`mitigationScoreSaving${mitigationNumber}`}
                render={({ input, meta }) => (
                  <RenderSlider
                    min={0}
                    max={100}
                    value={input.value}
                    onChange={input.onChange}
                    marks={{
                      14: {
                        label: "Very Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      15: "1 - 15 days",
                      29: {
                        label: "Low",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      30: "16 - 30 days",
                      49: {
                        label: "Medium",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      50: "31 - 50 days",
                      69: {
                        label: "High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          paddingLeft: "10px",
                        },
                      },
                      70: "51 - 75 days",
                      99: {
                        label: "Very High",
                        style: {
                          width: "55px",
                          wrap: "nowrap",
                          top: "-25px",
                          left: "95%",
                        },
                      },
                      100: {
                        style: {
                          width: "50px",
                          wrap: "nowrap",
                          left: "95%",
                        },
                        label: "> 75 days",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Supporting Documents
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-documents">
                  {supportingDocuments.length > 0 ? (
                    supportingDocuments.map((document) => {
                      return (
                        <div
                          key={`${document.documentFile.title}${document.documentFile.date}`}
                          className="form__form-group-document"
                        >
                          <a>
                            {`${
                              document.documentFile.title
                            } was added on ${new Date(
                              document.documentFile.date
                            ).toLocaleDateString("en-GB")}`}
                          </a>
                          <span className="remove-document">
                            <X className="remove-document-icon" />
                            Delete
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <p>No supporting documents attached</p>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Button
              type="button"
              color="primary"
              outline
              onClick={onDocumentOpen}
            >
              + ADD SUPPORTING DOCUMENT
            </Button>
          </Col>
        </Row>
        <RiskDocument
          isOpen={documentIsOpen}
          pageTitle="action"
          closeHandler={onDocumentClose}
          mutators={mutators}
        />
        {/* <Row>
          <div className="mitigations__score">
            <div className="mitigations__score-box">
              <p>SCORE: __</p>
            </div>
          </div>
        </Row> */}
      </Col>
      {/* </form> */}
    </>
  );
};

export default Mitigation;
