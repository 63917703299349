import { Field, useFormState } from "react-final-form";
import { Row, Col, ButtonToolbar, Button } from "reactstrap";
import { useDispatch } from "react-redux";

import RenderFileInputField from "../../../shared/components/form/FileInput";
import RenderSelectField from "../../../shared/components/form/Select";
import { RenderField } from "../../../shared/components/form/Field";

type AssessmentFormProps = {
  type: string;
  parentNumber?: number;
  pageTitle: string;
};

const AssessmentForm = ({
  type,
  parentNumber,
  pageTitle,
}: AssessmentFormProps) => {
  const formState = useFormState();

  const dispatch = useDispatch();

  const saveHandler = () => {
    const toSubmit = {
      // assessmentSourceType: formState.values["documentType"],
      assessmentSourceTitle:
        formState.values[`${pageTitle}AssessmentTitle${parentNumber}`],
      assessmentSourceFile: {
        title:
          formState.values[`${pageTitle}DocumentFile${parentNumber}`].file.name,
        date: formState.values[`${pageTitle}DocumentFile${parentNumber}`].file
          .lastModifiedDate,
      },
      assessmentSourceDescription:
        formState.values[`${pageTitle}DocumentDescription${parentNumber}`],
      assessmentSourceOwner:
        formState.values[`${pageTitle}DocumentOwner${parentNumber}`],
      assessmentSourceOwnerRole:
        formState.values[`${pageTitle}DocumentOwnerRole${parentNumber}`],
    };
    // dispatch(addSupportingDocument(toSubmit));
    console.log(toSubmit);
  };

  console.log(type);
  return (
    <div className={`risk__document-container open`}>
      <Row>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {type === "Specialist Opinion" ? "Title" : `${type} Title`}
            </span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}AssessmentTitle${parentNumber}`}
                component={RenderField}
                type="text"
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Assessment Source</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentFile${parentNumber}`}
                render={({ input, meta }) => (
                  <RenderFileInputField input={input} />
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Description</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentDescription${parentNumber}`}
                component="textarea"
                type="text"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Document Owner</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentOwner${parentNumber}`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    placeholder="Document Owner"
                    meta={meta}
                    options={[
                      { value: "David Smith", label: "David Smith" },
                      { value: "Ian Stewart", label: "Ian Stewart" },
                      { value: "Renata Jordan", label: "Renata Jordan" },
                      { value: "Mia Smith", label: "Mia Smith" },
                      { value: "Adele Stewart", label: "Adele Stewart" },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Document Owner Role</span>
            <div className="form__form-group-field">
              <Field
                name={`${pageTitle}DocumentOwnerRole${parentNumber}`}
                render={({ input, meta }) => (
                  <RenderSelectField
                    input={input}
                    placeholder="Document Owner Role"
                    meta={meta}
                    options={[
                      { value: "Project Manager", label: "Project Manager" },
                      {
                        value: "Work Package Manager",
                        label: "Work Package Manager",
                      },
                      {
                        value: "Quantity Surveyor",
                        label: "Quantity Surveyor",
                      },
                      {
                        value: "Commercial Manager",
                        label: "Commercial Manager",
                      },
                      { value: "Cost Manager", label: "Cost Manager" },
                      {
                        value: "Planning Manager",
                        label: "Planning Manager",
                      },
                      { value: "QHSSE Manager", label: "QHSSE Manager" },
                      {
                        value: "Environmental Manager",
                        label: "Environmental Manager",
                      },
                      { value: "Change Manager", label: "Change Manager" },
                      { value: "Designer", label: "Designer" },
                      {
                        value: "Software Developer",
                        label: "Software Developer",
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <ButtonToolbar>
        <Button
          onClick={saveHandler}
          color="primary"
          style={{ marginLeft: "auto" }}
        >
          SAVE
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default AssessmentForm;
