import { useMemo } from "react";
import { Filter } from "react-bootstrap-icons";
import {
  Column,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { SearchInput } from "../../../shared/components/table/SearchInput";
import { Button, PaginationItem, PaginationLink } from "reactstrap";

interface DataType {
  id: string;
}

interface SentInvitationTableProps {
  tableHeaders: Column<any>[];
  tableData: DataType[];
  sentInvitations?: boolean;
  children?: React.ReactChild;
}

export const SentInvitationTable = ({
  tableHeaders,
  tableData,
  sentInvitations,
  children,
}: SentInvitationTableProps) => {
  const columns = useMemo(() => tableHeaders, []);
  const data = useMemo(() => tableData, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;
  const { pageIndex, pageSize } = state;

  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  return (
    <div className="sent-invitations__table">
      <div className="table__container">
        <div className="table__header">
          <h4>SENT INVITATIONS</h4>
          <div className="table__header--right">
            <SearchInput filter={globalFilter} setFilter={setGlobalFilter} />
            {children}
          </div>
        </div>
        <div className="table">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <Filter className="th-badge" />
                    </th>
                  ))}
                  {sentInvitations && (
                    <>
                      <th> </th>
                      <th> </th>
                      <th>Actions</th>
                    </>
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                    {sentInvitations && (
                      <>
                        <td>
                          {row.values.permission === "admin" && (
                            <Button size="sm">WITHDRAW</Button>
                          )}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            color={
                              row.values.accountStatus === "active"
                                ? "secondary"
                                : "primary"
                            }
                          >
                            {row.values.accountStatus === "active"
                              ? "DEACTIVATE"
                              : "SEND AGAIN"}
                          </Button>
                        </td>
                        <td>
                          <Button size="sm" color="primary">
                            CHANGE PERMISSIONS
                          </Button>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table__footer">
          <div className="table__footer-count">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>
          <div className="table__footer-pages">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>
            {arrayPageIndex.map((i) => (
              <PaginationItem
                active={pageIndex === i}
                key={i}
                className="pagination__item"
              >
                <PaginationLink
                  key={i}
                  type="button"
                  onClick={() => gotoPage(i)}
                  className="pagination__link"
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>
          </div>
          <div className="table__footer-results">
            <span>Results on page</span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
