import { useMsal } from "@azure/msal-react";
import React from "react";
import { Field, FieldRenderProps, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
// import authentication from "../../../B2C";
import { createUser } from "../../../redux/pages/userManagement";

type InviteParams = {
  userInvitationId: string;
};

const passwordValidation = (values: any) => {
  const errors: {
    confirmPassword: string | undefined;
  } = {
    confirmPassword: undefined,
  };
  if (!values.confirmPassword) {
    errors.confirmPassword = "Please confirm the password";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords must match";
  }
  return errors;
};

const AcceptInvite = () => {
  const { instance } = useMsal();

  let { userInvitationId } = useParams<InviteParams>();

  const dispatch = useDispatch();

  type FormData = {
    password: string;
    confirmPassword: string;
  };

  const onSubmit = ({ password, confirmPassword }: FormData) => {
    dispatch(createUser({ userInvitationId, password, confirmPassword }));
    instance.loginRedirect();
  };

  return (
    <div className="accept-invite">
      <div className="accept-invite__container">
        <p>
          You were invited to join an organisation. Please enter a password for
          your account.
        </p>
        <Form
          onSubmit={onSubmit}
          validate={passwordValidation}
          render={({ handleSubmit }) => (
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <Field
                    component="input"
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Confirm Password</span>
                <div className="form__form-group-field">
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    render={({
                      input,
                      placeholder,
                      meta,
                    }: FieldRenderProps<string>) => (
                      <div className="form__form-group-input-wrap">
                        <input
                          {...input}
                          placeholder={placeholder}
                          type="password"
                        />
                        {meta.touched && meta.error && (
                          <span className="form__form-group-error">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <Button type="submit" color="primary">
                CREATE ACCOUNT
              </Button>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default AcceptInvite;
