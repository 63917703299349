import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import { Field } from "react-final-form";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

import { RenderField } from "../../../shared/components/form/Field";
import RenderSelectField from "../../../shared/components/form/Select";
import RenderSlider from "../../../shared/components/range_slider/Slider";
import { RootState } from "../../../redux/store";
import RiskDocument from "../components/RiskDocument";
import { useState } from "react";
import { X } from "react-bootstrap-icons";
import { WizzardPageType } from "../../../types/types";

const RiskDetails = ({ values, mutators }: WizzardPageType) => {
  const { riskSupportingDocuments, risksList } = useSelector(
    (state: RootState) => state.risks
  );

  const [documentIsOpen, setDocumentIsOpen] = useState(false);

  const onDocumentOpen = () => {
    setDocumentIsOpen(true);
  };

  const onDocumentClose = () => {
    setDocumentIsOpen(false);
  };

  // Returns classes for Risk Impact colour code. Depends on risk type and generated score
  const riskImpactScoreColorCode = (value: string) => {
    let result;
    const score = Number(value.split(" ")[0]);
    switch (values?.riskType?.label) {
      case "Threat":
        if (score <= 5) {
          result = `threat vlow`;
        } else if (score > 5 && score <= 10) {
          result = `threat low`;
        } else if (score > 10 && score <= 15) {
          result = `threat medium`;
        } else if (score > 15 && score <= 20) {
          result = `threat high`;
        } else if (score > 20 && score <= 25) {
          result = `threat vhigh`;
        }
        return result;
      case "Opportunity":
        if (score <= 5) {
          result = `opportunity vlow`;
        } else if (score > 5 && score <= 10) {
          result = `opportunity low`;
        } else if (score > 10 && score <= 15) {
          result = `opportunity medium`;
        } else if (score > 15 && score <= 20) {
          result = `opportunity high`;
        } else if (score > 20 && score <= 25) {
          result = `opportunity vhigh`;
        }
        return result;

      default:
        break;
    }
  };

  // Returns classes for assessment Confidence colour code. Depends on risk type and generated score
  const assessmentConfidenceScoreColorCode = (value: string) => {
    let result;
    const score = Number(value.split(" ")[0]);
    switch (values?.riskType?.label) {
      case "Threat":
        if (score <= 5) {
          result = `threat vhigh`;
        } else if (score > 5 && score <= 10) {
          result = `threat high`;
        } else if (score > 10 && score <= 15) {
          result = `threat medium`;
        } else if (score > 15 && score <= 20) {
          result = `threat low`;
        } else if (score > 20 && score <= 25) {
          result = `threat vlow`;
        }
        return result;
      case "Opportunity":
        if (score <= 5) {
          result = `opportunity vlow`;
        } else if (score > 5 && score <= 10) {
          result = `opportunity low`;
        } else if (score > 10 && score <= 15) {
          result = `opportunity medium`;
        } else if (score > 15 && score <= 20) {
          result = `opportunity high`;
        } else if (score > 20 && score <= 25) {
          result = `opportunity vhigh`;
        }
        return result;

      default:
        break;
    }
  };

  return (
    <>
      <Col md={12}>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Risk Title<span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field name="riskTitle" component={RenderField} type="text" />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                Risk Owner<span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskOwner"
                  render={({ input, meta }) => {
                    return (
                      <RenderSelectField
                        input={input}
                        meta={meta}
                        placeholder="Risk Owner"
                        options={[
                          { value: "David Smith", label: "David Smith" },
                          { value: "Ian Stewart", label: "Ian Stewart" },
                          { value: "Renata Jordan", label: "Renata Jordan" },
                          { value: "Mia Smith", label: "Mia Smith" },
                          { value: "Adele Stewart", label: "Adele Stewart" },
                        ]}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Risk ID</span>
                  <div className="form__form-group-field">
                    <Field
                      name="riskAssignedId"
                      disabled={true}
                      component={RenderField}
                      type="text"
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Risk Type<span>*</span>
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="riskType"
                      render={({ input, meta }) => (
                        <RenderSelectField
                          input={input}
                          meta={meta}
                          placeholder="Risk Type"
                          options={[
                            { value: "threat", label: "Threat" },
                            { value: "opportunity", label: "Opportunity" },
                            { value: "issue", label: "Issue" },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="form__form-group">
              <span className="form__form-group-label">Risk Owner Role</span>
              <div className="form__form-group-field">
                <Field
                  name="riskOwnerRole"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      placeholder="Risk Owner Role"
                      options={[
                        { value: "Project Manager", label: "Project Manager" },
                        {
                          value: "Work Package Manager",
                          label: "Work Package Manager",
                        },
                        {
                          value: "Quantity Surveyor",
                          label: "Quantity Surveyor",
                        },
                        {
                          value: "Commercial Manager",
                          label: "Commercial Manager",
                        },
                        { value: "Cost Manager", label: "Cost Manager" },
                        {
                          value: "Planning Manager",
                          label: "Planning Manager",
                        },
                        { value: "QHSSE Manager", label: "QHSSE Manager" },
                        {
                          value: "Environmental Manager",
                          label: "Environmental Manager",
                        },
                        { value: "Change Manager", label: "Change Manager" },
                        { value: "Designer", label: "Designer" },
                        {
                          value: "Software Developer",
                          label: "Software Developer",
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Risk Impact Score</span>
              <div className="form__form-group-field">
                <Field
                  name="riskImpactScore"
                  render={({ input, meta }) => {
                    return (
                      <RenderField
                        input={input}
                        disabled={true}
                        classes={riskImpactScoreColorCode(input.value)}
                        meta={meta}
                        type="text"
                        placeholder=""
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Assessment Confidence Score
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskAssessmentConfidenceScore"
                  render={({ input, meta }) => {
                    return (
                      <RenderField
                        input={input}
                        disabled={true}
                        classes={assessmentConfidenceScoreColorCode(
                          input.value
                        )}
                        meta={meta}
                        type="text"
                        placeholder=""
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Status<span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskStatus"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      placeholder="Status"
                      options={[
                        { value: "open", label: "Open" },
                        { value: "draft", label: "Draft" },
                        {
                          value: "closedImpacted",
                          label: "Closed - Impacted",
                        },
                        { value: "closedExpired", label: "Closed - Expired" },
                        {
                          value: "closedMitigated",
                          label: "Closed - Mitigated",
                        },
                        { value: "transfered", label: "Transfered" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Owner Organisation<span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskOwnerOrganisationType"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      placeholder="Owner Organisation"
                      options={[
                        { value: "Employer", label: "Employer" },
                        { value: "Contractor", label: "Contractor" },
                        { value: "Third Party", label: "Third Party" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className="form__form-group">
          <span className="form__form-group-label">Risk Description</span>
          <div className="form__form-group-field">
            <Field name="riskDescription" component="textarea" type="text" />
          </div>
        </div>
        <Row>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Risk Impact Exposure Start Date
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskRiskImpactExposureStartDate"
                  render={(props) => {
                    return (
                      <div className="date-picker">
                        <DatePicker
                          {...props.input}
                          className="form__form-group-datepicker"
                          selected={
                            props.input.value
                              ? new Date(props.input.value)
                              : new Date()
                          }
                          name="riskRiskImpactExposureStartDate"
                          dateFormat="dd/MM/yyyy"
                          // dropDownMode="select"
                          // popperPlacement="center"
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Risk Impact Exposure End Date
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskRiskImpactExposureEndDate"
                  render={(props) => {
                    return (
                      <div className="date-picker">
                        <DatePicker
                          {...props.input}
                          className="form__form-group-datepicker"
                          selected={
                            props.input.value
                              ? new Date(props.input.value)
                              : new Date()
                          }
                          name="riskRiskImpactExposureEndDate"
                          dateFormat="dd/MM/yyyy"
                          // dropDownMode="select"
                          // popperPlacement="center"
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Cost Impact Exposure Start Date
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskCostImpactExposureStartDate"
                  render={(props) => {
                    return (
                      <div className="date-picker">
                        <DatePicker
                          {...props.input}
                          className="form__form-group-datepicker"
                          selected={
                            props.input.value
                              ? new Date(props.input.value)
                              : new Date()
                          }
                          name="riskCostImpactExposureStartDate"
                          dateFormat="dd/MM/yyyy"
                          // dropDownMode="select"
                          // popperPlacement="center"
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Cost Impact Exposure End Date
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskCostImpactExposureEndDate"
                  render={(props) => {
                    return (
                      <div className="date-picker">
                        <DatePicker
                          {...props.input}
                          className="form__form-group-datepicker"
                          selected={
                            props.input.value
                              ? new Date(props.input.value)
                              : new Date()
                          }
                          name="riskCostImpactExposureEndDate"
                          dateFormat="dd/MM/yyyy"
                          // dropDownMode="select"
                          // popperPlacement="center"
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="form-title">RISK CATEGORISATION</div>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Risk Hierarchy Level <span>*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskCategorisationHierarchyLevel"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "Strategic", label: "Strategic" },
                        { value: "Programme", label: "Programme" },
                        { value: "Project", label: "Project" },
                        { value: "Work Package", label: "Work Package" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Link Parent/Child Risks
              </span>
              <div className="form__form-group-field">
                <Field
                  name="riskParentChildLink"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={risksList?.map((risk) => {
                        return {
                          value: risk.id,
                          label: `${risk.identifier} ${risk.title}`,
                        };
                      })}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Category 1</span>
              <div className="form__form-group-field">
                <Field
                  name="riskCategorisationCategory1"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "Civils", label: "Civils" },
                        { value: "Commercial", label: "Commercial" },
                        { value: "Consents", label: "Consents" },
                        { value: "Design", label: "Design" },
                        { value: "Ecology", label: "Ecology" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Parent/Child</span>
              <div className="form__form-group-field">
                <Field
                  name="riskCategorisationParentChild"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "parent", label: "Parent" },
                        { value: "child", label: "Child" },
                        { value: "standAlone", label: "Stand-Alone" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Lifecycle Phase</span>
              <div className="form__form-group-field">
                <Field
                  name="riskLifecyclePhase"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        {
                          value: "Strategic Definition",
                          label: "Strategic Definition",
                        },
                        {
                          value: "Preparation and Brief",
                          label: "Preparation and Brief",
                        },
                        { value: "Concept Design", label: "Concept Design" },
                        {
                          value: "Developed Design",
                          label: "Developed Design",
                        },
                        { value: "Construction", label: "Construction" },
                        {
                          value: "Handover and Close Out",
                          label: "Handover and Close Out",
                        },
                        { value: "In Use", label: "In Use" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Category 2</span>
              <div className="form__form-group-field">
                <Field
                  name="riskCategorisationCategory3"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "Civils", label: "Civils" },
                        { value: "Commercial", label: "Commercial" },
                        { value: "Consents", label: "Consents" },
                        { value: "Design", label: "Design" },
                        { value: "Ecology", label: "Ecology" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <Row>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Work Breakdown Structure
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="riskCategorisationWorkBreakdownStructure"
                      render={({ input, meta }) => (
                        <RenderSelectField
                          input={input}
                          meta={meta}
                          options={[
                            {
                              value: "WBS-1456 - Fit Out",
                              label: "WBS-1456 - Fit Out",
                            },
                            {
                              value: "WBS-1458 - M&E",
                              label: "WBS-1456 - M&E",
                            },
                            {
                              value: "WBS-1457 - Civils",
                              label: "WBS-1456 - Civils",
                            },
                            {
                              value: "WBS-1459 - Commercial",
                              label: "WBS-1456 - Commercial",
                            },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Cost Breakdown Structure
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="riskCategorisationCostBreakdownStructure"
                      render={({ input, meta }) => (
                        <RenderSelectField
                          input={input}
                          meta={meta}
                          options={[
                            {
                              value: "CBS-1456 - Fit Out",
                              label: "CBS-1456 - Fit Out",
                            },
                            {
                              value: "CBS-1458 - M&E",
                              label: "CBS-1456 - M&E",
                            },
                            {
                              value: "CBS-1457 - Civils",
                              label: "CBS-1456 - Civils",
                            },
                            {
                              value: "CBS-1459 - Commercial",
                              label: "CBS-1456 - Commercial",
                            },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">Risk Probability</span>
              <Field
                name="riskCategorisationProbability"
                render={({ input, meta }) => {
                  return (
                    <RenderSlider
                      min={0}
                      max={100}
                      {...input}
                      marks={{
                        14: {
                          label: "Very Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        15: "1% - 15%",
                        29: {
                          label: "Low",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        30: "16% - 30%",
                        49: {
                          label: "Medium",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        50: "31% - 50%",
                        69: {
                          label: "High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            paddingLeft: "10px",
                          },
                        },
                        70: "51% - 75%",
                        99: {
                          label: "Very High",
                          style: {
                            width: "55px",
                            wrap: "nowrap",
                            top: "-25px",
                            left: "95%",
                          },
                        },
                        100: {
                          style: {
                            width: "50px",
                            wrap: "nowrap",
                            left: "95%",
                          },
                          label: "76%-99%",
                        },
                      }}
                    />
                  );
                }}
              />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Propose Response Strategy
              </span>
              <div className="form__form-group-field">
                <Field
                  name="categorisationProposedResponseStrategy"
                  render={({ input, meta }) => (
                    <RenderSelectField
                      input={input}
                      meta={meta}
                      options={[
                        { value: "treat", label: "Treat" },
                        { value: "tolerate", label: "Tolerate" },
                        { value: "transfer", label: "Transfer" },
                        { value: "terminate", label: "Terminate" },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Supporting Documents
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-documents">
                  {riskSupportingDocuments.length > 0 ? (
                    riskSupportingDocuments.map((document) => {
                      return (
                        <div className="form__form-group-document">
                          <a>
                            {`${
                              document.documentFile.title
                            } was added on ${new Date(
                              document.documentFile.date
                            ).toLocaleDateString("en-GB")}`}
                          </a>
                          <span className="remove-document">
                            <X className="remove-document-icon" />
                            Delete
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <p>No supporting documents attached</p>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Button
              type="button"
              color="primary"
              outline
              onClick={onDocumentOpen}
            >
              + ADD SUPPORTING DOCUMENT
            </Button>
          </Col>
        </Row>
        <RiskDocument
          isOpen={documentIsOpen}
          pageTitle="risk"
          closeHandler={onDocumentClose}
          mutators={mutators}
        />
      </Col>
    </>
  );
};

export default RiskDetails;
