import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

import { B2C_SCOPES } from "../../../B2C";
import logo from "../../../shared/img/logo_white_background.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const { instance } = useMsal();

  return (
    <>
      <Navbar className="header" expand="md" light>
        <NavbarBrand className="header__nav-image" href="/">
          <img src={logo} alt="" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} navbar />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us">About us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contacts">Contact us</NavLink>
            </NavItem>
          </Nav>
          <NavbarText
            style={{ marginLeft: "auto" }}
            onClick={() => instance.loginRedirect(B2C_SCOPES.API_ACCESS)}
          >
            LOGIN
          </NavbarText>
        </Collapse>
      </Navbar>
    </>
  );
};

export default Header;
