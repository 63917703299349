import React from "react";
import { Row, Col, Button, ButtonToolbar } from "reactstrap";
import { Check2Circle } from "react-bootstrap-icons";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CallMade from "@material-ui/icons/CallMade";
import Whatshot from "@material-ui/icons/Whatshot";
import { onAddPostToggle } from "../../../redux/formModals";
import { useDispatch } from "react-redux";

const FilterToolbar = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Row>
        <Col md={12}>
          <ButtonToolbar>
            <Button color="secondary" size="sm">
              <div className="button__content">
                <ScheduleIcon /> New
              </div>
            </Button>
            <Button color="secondary" size="sm">
              <div className="button__content">
                <CallMade /> Top
              </div>
            </Button>
            <Button color="secondary" size="sm">
              <div className="button__content">
                <Whatshot /> Hot
              </div>
            </Button>
            <Button color="secondary" size="sm">
              <div className="button__content">
                <Check2Circle /> Closed
              </div>
            </Button>
            <Button
              color="primary"
              style={{ marginLeft: "auto" }}
              onClick={() => dispatch(onAddPostToggle())}
            >
              ADD POST
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </div>
  );
};

export default FilterToolbar;
