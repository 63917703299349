import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { onUserModalToggle } from "../../../redux/pages/userManagement";
import { RootState } from "../../../redux/store";
import ModalContainer from "../../../shared/components/ModalContainer";

import { SentInvitationTable } from "./SentInvitationTable";
import { SentInvitesTable } from "../../../types/types";
import AddUser from "./AddUser";
import sentInvitations from "./sentInvitations.json";
import UserInvitation from "./UserInvitation";

const SentInvitations = () => {
  const dispatch = useDispatch();

  const userModal = useSelector(
    (state: RootState) => state.userManagement.userModal
  );

  const headers: Column<SentInvitesTable>[] = [
    // {
    //   Header: "ID",
    //   accessor: "id",
    // },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Surname",
      accessor: "surname",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Account status",
      accessor: "accountStatus",
      Cell: ({ value }) => {
        return (
          <span style={{ color: value === "active" ? "#009688" : undefined }}>
            {value.toUpperCase()}
          </span>
        );
      },
    },
    {
      Header: "Permissions",
      accessor: "permission",
      Cell: ({ value }) => {
        switch (value) {
          case "admin":
            return <span>Admin</span>;
          case "readOnly":
            return <span>Read-only Access</span>;
          case "fullAccess":
            return <span>Full Access</span>;

          default:
            break;
        }
      },
    },
  ];

  return (
    <>
      <Container>
        <Col md={12}>
          <Row>
            <h3 className="page-title">Admin/Manage User Licences</h3>
          </Row>
          <Card>
            <CardBody>
              <SentInvitationTable
                tableHeaders={headers}
                tableData={sentInvitations}
                sentInvitations
              >
                <AddUser toggleModal={() => dispatch(onUserModalToggle())} />
              </SentInvitationTable>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <ModalContainer
        isOpen={userModal}
        toggle={() => dispatch(onUserModalToggle())}
        size="xl"
        title="SEND INVITE"
      >
        <UserInvitation toggleModal={() => dispatch(onUserModalToggle())} />
      </ModalContainer>
    </>
  );
};

export default SentInvitations;
